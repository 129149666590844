import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, Observable, shareReplay, startWith, Subject, Subscription, takeUntil } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

import { Empty } from "../../../models/Empty";
import { AnswerInteractionTypes, QuestionTypesEnum } from "../../../models/Enums";
import { INextQuestionSummary, isEndQuestionSummary } from "../../../models/INextQuestionSummary";
import { IQuestionHistoryLog } from "../../../models/IQuestionHistoryLog";
import { StyleService } from "../../../services/style.service";
import { TextContentService } from "../../../services/text-content.service";
import { QuestionFacade } from "../../../state/Question/Question.facade";

@Component({
  selector: "app-message-card",
  templateUrl: "./message-card.component.html",
  styleUrls: ["./message-card.component.scss"],
})
export class MessageCardComponent implements OnDestroy {
  // Data

  public showEndQuestionnaire$: Observable<boolean>;
  public goBack$: Subject<void> = new Subject<void>();
  public goNext$: Subject<void> = new Subject<void>();

  public showGoBackButtonOnEndPage$: Observable<boolean>;

  public destroy$: Subject<void> = new Subject<void>();

  // Lifecycle

  constructor(
    public tcs: TextContentService,
    public questionFacade: QuestionFacade,
    private router: Router,
    private route: ActivatedRoute,
    public styleService: StyleService
  ) {
    // Observables

    this.showEndQuestionnaire$ = this.showEndOfQuestionnaireHandler();
    this.showGoBackButtonOnEndPage$ = this.showGoBackButtonOnEndPageHandler();

    // Subscriptions

    this.registerGoBackHandler();
    this.registerGoNextHandler();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  // Interface methods

  goBack() {
    this.goBack$.next();
  }

  goNext() {
    this.goNext$.next();
  }

  async goToResults() {
    // Indicate end of questionnaire reached
    const answer = Empty.answer();
    answer.currentQuestion.questionType = QuestionTypesEnum.End;
    answer.interactionType = AnswerInteractionTypes.Message;
    this.questionFacade.answerQuestion(answer);

    await this.router.navigate(["/resultaten"], {
      queryParams: this.route.snapshot.queryParams,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
  }

  // Getters & setters

  // Helper methods

  private registerGoBackHandler(): Subscription {
    return this.goBack$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(
          combineLatest([
            this.questionFacade.nextQuestionSummary$,
            this.questionFacade.showProfessionWarning$,
            this.showEndQuestionnaire$,
          ])
        ),
        shareReplay(1)
      )
      .subscribe(([, [nextQuestion, showProfessionWarning, showEndQuestionnaire]]) => {
        if (showProfessionWarning) {
          this.questionFacade.loadNextQuestionInfo(QuestionTypesEnum.ProfessionQuestion, AnswerInteractionTypes.Back);
        } else if (showEndQuestionnaire) {
          this.questionFacade.loadNextQuestionInfo(nextQuestion.questionType, AnswerInteractionTypes.Back);
        }
      });
  }

  private registerGoNextHandler(): Subscription {
    return this.goNext$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(
          combineLatest([
            this.questionFacade.nextQuestionSummary$,
            this.questionFacade.showProfessionWarning$,
            this.showEndQuestionnaire$,
          ])
        ),
        shareReplay(1)
      )
      .subscribe(([, [currentAction, showProfessionWarning, showEndQuestionnaire]]) => {
        if (showProfessionWarning) {
          this.questionFacade.loadNextQuestionInfo(QuestionTypesEnum.ProfessionWarning, AnswerInteractionTypes.Message);
        } else if (showEndQuestionnaire) {
          this.questionFacade.loadNextQuestionInfo(currentAction.questionType, AnswerInteractionTypes.Message);
        }
      });
  }

  private showEndOfQuestionnaireHandler(): Observable<boolean> {
    return this.questionFacade.nextQuestionSummary$.pipe(
      takeUntil(this.destroy$),
      map((currentQuestion: INextQuestionSummary) => isEndQuestionSummary(currentQuestion)),
      startWith(false),
      shareReplay(1)
    );
  }

  private showGoBackButtonOnEndPageHandler(): Observable<boolean> {
    return this.questionFacade.questionHistory$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.showEndQuestionnaire$),
      map(([questionHistory, showEndOfQuestionnaire]) => {
        if (!showEndOfQuestionnaire) return true;
        if (questionHistory.length < 1) return false;

        const questionTypes = questionHistory.map((log: IQuestionHistoryLog) => log.questionType);
        return !questionTypes.includes(QuestionTypesEnum.End);
      })
    );
  }
}

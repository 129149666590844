import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

import { IEnvironment } from "../../../../environments/IEnvironment";
import { TextContentService } from "../../../services/text-content.service";

@Component({
  selector: "app-vdab-toolbar",
  templateUrl: "./vdab-toolbar.component.html",
  styleUrls: ["./vdab-toolbar.component.scss"],
})
export class VdabToolbarComponent {
  // Data
  public appLogoImgUrl = "assets/vdab-logo-white.png";

  constructor(public tcs: TextContentService) {}

  // Getters & setters

  public get environment(): IEnvironment {
    return environment;
  }
}

export interface IE2eValues {
  vdabAiAccordionTestComponent: string;
  vdabAiAccordionRoot: string;
  vdabAiAccordionTitle: string;
  vdabAiAccordionButtonText: string;
  vdabAiAccordionContent: string;
  vdabAiAccordionToggleBtn: string;
  vdabAiAccordionToggleAllBtn: string;

  vdabAiAccordionExpanderTestComponent: string;
  vdabAiAccordionExpanderRoot: string;
  vdabAiAccordionExpanderToggleAllBtn: string;

  vdabAiAlertTestComponent: string;
  vdabAiAlertRoot: string;
  vdabAiAlertTitle: string;
  vdabAiAlertContent: string;
  vdabAiAlertHuisstijlRoot: string;
  vdabAiAlertCloseBtn: string;

  vdabAiAutocompleteTestComponent: string;
  vdabAiAutocompleteRoot: string;
  vdabAiAutocompleteLabel: string;
  vdabAiAutocompleteInput: string;
  vdabAiAutocompleteContent: string;

  vdabAiButtonTestComponent: string;
  vdabAiButtonRoot: string;

  vdabAiCheckboxTestComponent: string;
  vdabAiCheckboxRoot: string;
  vdabAiCheckboxInputElement: string;
  vdabAiCheckboxLabelElement: string;
  vdabAiCheckboxMetaLabelElement: string;

  vdabAiDialogBoxTestComponent: string;
  vdabAiDialogBoxRoot: string;
  vdabAiDialogBoxHuisstijlRoot: string;
  vdabAiDialogBoxTitle: string;
  vdabAiDialogBoxContent: string;
  vdabAiDialogBoxButtons: string;

  vdabAiDropdownTestComponent: string;
  vdabAiDropdownRoot: string;
  vdabAiDropdownLabelElement: string;
  vdabAiDropdownSelectElement: string;

  vdabAiEditableDropdownTestComponent: string;
  vdabAiEditableDropdownRoot: string;
  vdabAiEditableDropdownLabelElement: string;
  vdabAiEditableDropdownInputElement: string;
  vdabAiEditableDropdownOptionsContainerElement: string;

  vdabAiFabButtonTestComponent: string;
  vdabAiFabButtonRoot: string;

  vdabAiHalfDonutChartTestComponent: string;
  vdabAiHalfDonutChartRoot: string;
  vdabAiHalfDonutChartHuisstijlRoot: string;
  vdabAiHalfDonutChartPercentage: string;
  vdabAiHalfDonutChartTextElement: string;
  vdabAiHalfDonutChartInfoIcon: string;

  vdabAiHeaderTestComponent: string;
  vdabAiHeaderRoot: string;
  vdabAiHeaderAppLogoLinkUrl: string;
  vdabAiHeaderAppLogoUrl: string;
  vdabAiHeaderAppName: string;
  vdabAiHeaderToolbarContent: string;

  vdabAiInputTestComponent: string;
  vdabAiInputRoot: string;
  vdabAiInputInputElement: string;
  vdabAiInputLabelElement: string;
  vdabAiInputPrefixElement: string;
  vdabAiInputAffixElement: string;
  vdabAiInputFormFieldElement: string;
  vdabAiInputErrorMsgElement: string;

  vdabAiModalTestComponent: string;
  vdabAiModalRoot: string;
  vdabAiModalHuisstijlRoot: string;
  vdabAiModalHeader: string;
  vdabAiModalHeaderTitleContainer: string;
  vdabAiModalHeaderCloseButton: string;
  vdabAiModalBodyContainer: string;

  vdabAiProgressLinearTestComponent: string;
  vdabAiProgressLinearRoot: string;
  vdabAiProgressLinearProgress: string;

  vdabAiRangeSliderTestComponent: string;
  vdabAiRangeSliderRoot: string;
  vdabAiRangeSliderTypeInputContainerElement: string;
  vdabAiRangeSliderMinTypeInputElement: string;
  vdabAiRangeSliderMaxTypeInputElement: string;
  vdabAiRangeSliderSliderContainerElement: string;
  vdabAiRangeSliderMinDragInputElement: string;
  vdabAiRangeSliderMaxDragInputElement: string;

  vdabAiSnackBarTestComponent: string;
  vdabAiSnackBarRoot: string;
  vdabAiSnackBarContentContainerElement: string;
  vdabAiSnackBarActionsContainerElement: string;
  vdabAiSnackBarActionsCloseBtn: string;

  vdabAiTabsTestComponent: string;
  vdabAiTabsRoot: string;
  vdabAiTabsTabRoot: string;
  vdabAiTabsTabContainerElement: string;
  vdabAiTabsTabHeaderElement: string;
  vdabAiTabsTabContent: string;

  vdabAiTagTestComponent: string;
  vdabAiTagRoot: string;
  vdabAiTagContentContainerElement: string;
  vdabAiTagCloseBtnElement: string;

  vdabAiTextAreaTestComponent: string;
  vdabAiTextAreaRoot: string;
  vdabAiTextAreaLabelElement: string;
  vdabAiTextAreaFormField: string;
  vdabAiTextAreaInputElement: string;

  vdabAiTooltipTestComponent: string;
  vdabAiTooltipRoot: string;
  vdabAiTooltipTargetElement: string;
  vdabAiTooltipContentElement: string;
}


export const e2e: IE2eValues = {
  vdabAiAccordionTestComponent: "vdab-ai-accordion.test-component",
  vdabAiAccordionRoot: "vdab-ai-accordion.root",
  vdabAiAccordionTitle: "vdab-ai-accordion.title",
  vdabAiAccordionButtonText: "vdab-ai-accordion.button-text",
  vdabAiAccordionContent: "vdab-ai-accordion.content",
  vdabAiAccordionToggleBtn: "vdab-ai-accordion.toggle-btn",
  vdabAiAccordionToggleAllBtn: "vdab-ai-accordion.toggle-all-btn",

  vdabAiAccordionExpanderTestComponent: "vdab-ai-accordion-expander.test-component",
  vdabAiAccordionExpanderRoot: "vdab-ai-accordion-expander.root",
  vdabAiAccordionExpanderToggleAllBtn: "vdab-ai-accordion-expander.toggle-all-btn",

  vdabAiAlertTestComponent: "vdab-ai-alert.test-component",
  vdabAiAlertRoot: "vdab-ai-alert.root",
  vdabAiAlertHuisstijlRoot: "vdab-ai-alert.huisstijl-root",
  vdabAiAlertTitle: "vdab-ai-alert.title",
  vdabAiAlertContent: "vdab-ai-alert.content",
  vdabAiAlertCloseBtn: "vdab-ai-alert.close-btn",

  vdabAiAutocompleteTestComponent: "vdab-ai-autcomplete.test-component",
  vdabAiAutocompleteRoot: "vdab-ai-autcomplete.root",
  vdabAiAutocompleteLabel: "vdab-ai-autcomplete.label",
  vdabAiAutocompleteContent: "vdab-ai-autcomplete.content",
  vdabAiAutocompleteInput: "vdab-ai-autcomplete.input",

  vdabAiButtonTestComponent: "vdab-ai-button.test-component",
  vdabAiButtonRoot: "vdab-ai-button.root",


  vdabAiCheckboxTestComponent: "vdab-ai-checkbox.test-component",
  vdabAiCheckboxRoot: "vdab-ai-checkbox.root",
  vdabAiCheckboxInputElement: "vdab-ai-checkbox.input-element",
  vdabAiCheckboxLabelElement: "vdab-ai-checkbox.label-element",
  vdabAiCheckboxMetaLabelElement: "vdab-ai-checkbox.meta-label-element",

  vdabAiDialogBoxTestComponent: "vdab-ai-dialog-box.test-component",
  vdabAiDialogBoxRoot: "vdab-ai-dialog-box.root",
  vdabAiDialogBoxHuisstijlRoot: "vdab-ai-dialog-box.huisstijl-root",
  vdabAiDialogBoxTitle: "vdab-ai-dialog-box.title",
  vdabAiDialogBoxContent: "vdab-ai-dialog-box.content",
  vdabAiDialogBoxButtons: "vdab-ai-dialog-box.buttons",

  vdabAiDropdownTestComponent: "vdab-ai-dropdown.test-component",
  vdabAiDropdownRoot: "vdab-ai-dropdown.root",
  vdabAiDropdownLabelElement: "vdab-ai-dropdown.label-element",
  vdabAiDropdownSelectElement: "vdab-ai-dropdown.select-element",

  vdabAiEditableDropdownTestComponent: "vdab-ai-editable-dropdown.test-component",
  vdabAiEditableDropdownRoot: "vdab-ai-editable-dropdown.root",
  vdabAiEditableDropdownLabelElement: "vdab-ai-editable-dropdown.label-element",
  vdabAiEditableDropdownInputElement: "vdab-ai-editable-dropdown.input-element",
  vdabAiEditableDropdownOptionsContainerElement: "vdab-ai-editable-dropdown.options-container-element",

  vdabAiFabButtonTestComponent: "vdab-ai-fab-button.test-component",
  vdabAiFabButtonRoot: "vdab-ai-fab-button.root",

  vdabAiHalfDonutChartTestComponent: "vdab-ai-half-donut-chart.test-component",
  vdabAiHalfDonutChartRoot: "vdab-ai-half-donut-chart.root",
  vdabAiHalfDonutChartHuisstijlRoot: "vdab-ai-half-donut-chart.huisstijl-root",
  vdabAiHalfDonutChartPercentage: "vdab-ai-half-donut-chart.percentage",
  vdabAiHalfDonutChartTextElement: "vdab-ai-half-donut-chart.label-element",
  vdabAiHalfDonutChartInfoIcon: "vdab-ai-half-donut-chart.info-icon",

  vdabAiHeaderTestComponent: "vdab-ai-header.test-component",
  vdabAiHeaderRoot: "vdab-ai-header.root",
  vdabAiHeaderAppLogoLinkUrl: "vdab-ai-header.app-logo-link-url",
  vdabAiHeaderAppLogoUrl: "vdab-ai-header.app-logo-url",
  vdabAiHeaderAppName: "vdab-ai-header.app-name",
  vdabAiHeaderToolbarContent: "vdab-ai-header.toolbar-content",

  vdabAiInputErrorMsgElement: "vdab-ai-input.error-msg-element",
  vdabAiInputFormFieldElement: "vdab-ai-input.form-field-element",
  vdabAiInputAffixElement: "vdab-ai-input.affix-element",
  vdabAiInputPrefixElement: "vdab-ai-input.prefix-element",
  vdabAiInputInputElement: "vdab-ai-input.input-element",
  vdabAiInputLabelElement: "vdab-ai-input.label-element",
  vdabAiInputRoot: "vdab-ai-input.root",
  vdabAiInputTestComponent: "vdab-ai-input.test-component",

  vdabAiModalTestComponent: "vdab-ai-modal.test-component",
  vdabAiModalRoot: "vdab-ai-modal.root",
  vdabAiModalHuisstijlRoot: "vdab-ai-modal.huisstijl-root",
  vdabAiModalHeader: "vdab-ai-modal.header",
  vdabAiModalHeaderTitleContainer: "vdab-ai-modal.header-title-container",
  vdabAiModalHeaderCloseButton: "vdab-ai-modal.header-close-button",
  vdabAiModalBodyContainer: "vdab-ai-modal.body-container",

  vdabAiProgressLinearTestComponent: "vdab-ai-progress-linear.test-component",
  vdabAiProgressLinearRoot: "vdab-ai-progress-linear.root",
  vdabAiProgressLinearProgress: "vdab-ai-progress-linear.progress",

  vdabAiRangeSliderTestComponent: "vdab-ai-range-slider.test-component",
  vdabAiRangeSliderRoot: "vdab-ai-range-slider.root",
  vdabAiRangeSliderTypeInputContainerElement: "vdab-ai-range-slider.type-input-container-element",
  vdabAiRangeSliderMinTypeInputElement: "vdab-ai-range-slider.min-type-input-element",
  vdabAiRangeSliderMaxTypeInputElement: "vdab-ai-range-slider.max-type-input-element",
  vdabAiRangeSliderSliderContainerElement: "vdab-ai-range-slider.slider-container-element",
  vdabAiRangeSliderMinDragInputElement: "vdab-ai-range-slider.min-drag-input-element",
  vdabAiRangeSliderMaxDragInputElement: "vdab-ai-range-slider.max-drag-input-element",

  vdabAiSnackBarTestComponent: "vdab-ai-snackbar.test-component",
  vdabAiSnackBarRoot: "vdab-ai-snackbar.root",
  vdabAiSnackBarContentContainerElement: "vdab-ai-snackbar.content-container-element",
  vdabAiSnackBarActionsContainerElement: "vdab-ai-snackbar.actions-container-element",
  vdabAiSnackBarActionsCloseBtn: "vdab-ai-snackbar.actions-close-btn",

  vdabAiTabsTestComponent: "vdab-ai-tabs.test-component",
  vdabAiTabsRoot: "vdab-ai-tabs.root",
  vdabAiTabsTabRoot: "vdab-ai-tabs.tab-root",
  vdabAiTabsTabContainerElement: "vdab-ai-tabs.tab-container-element",
  vdabAiTabsTabHeaderElement: "vdab-ai-tabs.tab-header-element",
  vdabAiTabsTabContent: "vdab-ai-tabs.tab-content",

  vdabAiTagTestComponent: "vdab-ai-tag.test-component",
  vdabAiTagRoot: "vdab-ai-tag.root",
  vdabAiTagContentContainerElement: "vdab-ai-tag.content-container-element",
  vdabAiTagCloseBtnElement: "vdab-ai-tag.close-btn-element",

  vdabAiTextAreaTestComponent: "vdab-ai-text-area.test-component",
  vdabAiTextAreaRoot: "vdab-ai-text-area.root",
  vdabAiTextAreaLabelElement: "vdab-ai-text-area.label-element",
  vdabAiTextAreaFormField: "vdab-ai-text-area.form-field",
  vdabAiTextAreaInputElement: "vdab-ai-text-area.input-element",

  vdabAiTooltipTestComponent: "vdab-ai-tooltip.test-component",
  vdabAiTooltipRoot: "vdab-ai-tooltip.root",
  vdabAiTooltipTargetElement: "vdab-ai-tooltip.target-element",
  vdabAiTooltipContentElement: "vdab-ai-tooltip.content-element",
}

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {e2e} from '../../e2e/dataE2eValues';
import {BaseButtonComponent} from "../base-button/base-button.component";

@Component({
  selector: 'vdab-ai-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss']
})
export class FabButtonComponent extends BaseButtonComponent implements OnInit, AfterViewInit {
  // Data

  /**
   * FAB-icon, only usable together with fab property!
   * Use VDAB icon-classes
   * E.g. 'action-adit' for pencil icon
   */
  @Input() fabIcon: string = "";

  @ViewChild("fabRef") fabRef!: ElementRef;

  // Lifecycle

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  // Interface methods

  // Getters & setters


  public get fabCustomIconUsed(): boolean {
    return this.fabRef?.nativeElement.children.length > 0;
  }

  public get e2e() {
    return e2e;
  }

  // Helper methods

}

<ng-container *ngIf="resultFacade.filteredSuggestedOccupationalProfiles$ | async as filteredSuggestedProfessions">
  <div class="u-mb1 hide-sm-and-down u-display-flex filter-count-container">
    <span class="">{{ resultsLabel }}: </span>
    <span class="u-font-weight-strong">{{ filteredSuggestedProfessions.length }} </span>
    <span class="">{{ tcs.getText("jobs") }}</span>
  </div>

  <div class="education-filter-title-container">
    <h4>{{ educationLevelTitle }}</h4>
    <vdab-ai-tooltip class="u-ml05" position="bottom">
      <span activator class="mdi mdi-information"></span>
      <ul content>
        <li>{{ tcs.getText("education_level_low_info") }}</li>
        <li>{{ tcs.getText("education_level_medium_info") }}</li>
        <li>{{ tcs.getText("education_level_high_info") }}</li>
      </ul>
    </vdab-ai-tooltip>
  </div>
  <ul class="c-search-filter__content u-reset-list" aria-hidden="false">
    <li activator class="c-search-filter-list-item">
      <ng-container *ngVar="lowEducationLevelValue$ | async as lowEducationLevelValue">
        <ng-container *ngVar="lowEducationLevelProfessionCount$ | async as lowEducationLevelProfessionCount">
          <vdab-ai-checkbox
            [label]="lowEducationLevelLabel"
            [value]="lowEducationLevelValue"
            (valueChange)="lowEducationLevelValueChanged($event)"
            [metaLabel]="lowEducationLevelProfessionCount"
          ></vdab-ai-checkbox>
        </ng-container>
      </ng-container>
    </li>
    <li class="c-search-filter-list-item">
      <ng-container *ngVar="mediumEducationLevelValue$ | async as mediumEducationLevelValue">
        <ng-container *ngVar="mediumEducationLevelProfessionCount$ | async as mediumEducationLevelProfessionCount">
          <vdab-ai-checkbox
            [label]="mediumEducationLevelLabel"
            [value]="mediumEducationLevelValue"
            (valueChange)="mediumEducationLevelValueChanged($event)"
            [metaLabel]="mediumEducationLevelProfessionCount"
          ></vdab-ai-checkbox>
        </ng-container>
      </ng-container>
    </li>
    <li class="c-search-filter-list-item">
      <ng-container *ngVar="highEducationLevelValue$ | async as highEducationLevelValue">
        <ng-container *ngVar="highEducationLevelProfessionCount$ | async as highEducationLevelProfessionCount">
          <vdab-ai-checkbox
            [label]="highEducationLevelLabel"
            [value]="highEducationLevelValue"
            (valueChange)="highEducationLevelValueChanged($event)"
            [metaLabel]="highEducationLevelProfessionCount"
          ></vdab-ai-checkbox>
        </ng-container>
      </ng-container>
    </li>
  </ul>

  <div class="c-divider"></div>

  <h4>{{ knelpuntberoepTitle }}</h4>
  <ul class="c-search-filter__content u-reset-list u-mb15" aria-hidden="false">
    <li class="c-search-filter-list-item">
      <ng-container *ngVar="isKnelpuntberoepValue$ | async as isKnelpuntberoepValue">
        <ng-container *ngIf="isKnelpuntberoepProfessionCount$ | async as isKnelpuntberoepProfessionCount">
          <vdab-ai-checkbox
            [label]="yesLabel"
            [value]="isKnelpuntberoepValue"
            (valueChange)="isKnelpuntberoepValueChanged($event)"
            [metaLabel]="isKnelpuntberoepProfessionCount"
          ></vdab-ai-checkbox>
        </ng-container>
      </ng-container>
    </li>
    <li class="c-search-filter-list-item">
      <ng-container *ngVar="isNoKnelpuntberoepValue$ | async as isNoKnelpuntberoepValue">
        <ng-container *ngIf="isNoKnelpuntberoepProfessionCount$ | async as isNoKnelpuntberoepProfessionCount">
          <vdab-ai-checkbox
            [label]="noLabel"
            [value]="isNoKnelpuntberoepValue"
            (valueChange)="isNoKnelpuntberoepValueChanged($event)"
            [metaLabel]="isNoKnelpuntberoepProfessionCount"
          ></vdab-ai-checkbox>
        </ng-container>
      </ng-container>
    </li>
  </ul>

  <div class="result-count-container hide-md-and-up-vdab-bp">
    <button class="c-btn -primary" (click)="onCloseModalClicked()">
      {{ filteredSuggestedProfessions.length }} {{ tcs.getText("jobs") }}
    </button>
  </div>
</ng-container>

import { Injectable } from "@angular/core";

import { capitalize } from "../../utils/text";

export interface ITextContent {
  active: string;
  agree: string;
  applied: string;
  app_name: string;
  cancel: string;
  click_here_for_more_info_profession_description: string;
  close_snackbar: string;
  copy_introduction_text1: string;
  copy_introduction_text2: string;
  copy_introduction_text3: string;
  copy_introduction_title: string;
  copy_professionwarningcard_text: string;
  copy_results_page_title: string;
  courses: string;
  disagree: string;
  discover_jobs: string;
  discover_jobs_text_at_end_of_questionnaire: string;
  education_level: string;
  education_level_low_info: string;
  education_level_medium_info: string;
  education_level_high_info: string;
  few_vacancies: string;
  filter: string;
  filter_your_results: string;
  filters: string;
  go_on: string;
  highly_educated: string;
  interesses_me: string;
  interest_in: string;
  interest_score: string;
  jobs: string;
  jobs_that_may_suit_you_based_on_interest: string;
  knelpuntberoep: string;
  loading_data_dots: string;
  less_info: string;
  lowly_educated: string;
  match: string;
  medium_educated: string;
  more_info: string;
  more_information: string;
  no: string;
  no_explanations_available_yet: string;
  no_results_match_given_criteria: string;
  orient: string;
  orientation_questions: string;
  pdf_your_results: string;
  pdf_interest_questions: string;
  pdf_professions: string;
  pdf_positively_answered_questions: string;
  pdf_negatively_answered_questions: string;
  previous: string;
  privacy: string;
  profession_warning: string;
  questions: string;
  read_everything_about_profession: string;
  results: string;
  results_page_title: string;
  save_results: string;
  save_as_pdf: string;
  save_on_vdab_be: string;
  save_on_vdab_be_error: string;
  save_on_vdab_be_explanation: string;
  save_on_vdab_be_saving: string;
  save_on_vdab_be_success: string;
  see_vacancies: string;
  see_courses: string;
  send_results: string;
  send_results_modal_title: string;
  send_results_cta1: string;
  send_results_cta2: string;
  send_results_cta3: string;
  send_results_mail_invalid: string;
  send_results_mail_empty: string;
  send_results_mail_sent: string;
  send_results_optional_attendant_mail: string;
  send_results_sending_mail_error: string;
  send_results_sending_mail: string;
  send_results_via_mail_privacy_disclaimer: string;
  send_via_mail: string;
  something_went_wrong_general_message: string;
  something_went_wrong_general_message_content_part_1: string;
  something_went_wrong_general_message_content_part_2: string;
  something_went_wrong_general_message_content_part_3: string;
  something_went_wrong_general_message_content_part_4: string;
  start_test: string;
  interest_in_profession_tag_message: string;
  temporary_results: string;
  the_job: string;
  vacancies: string;
  what_you_like_about_this_profession: string;
  what_you_dislike_about_this_profession: string;
  yes: string;
  your_email: string;
  your_result: string;
}

export enum Language {
  DUTCH = "nl_BE",
}

type Translations<T extends Language, U extends ITextContent> = {
  [K in T]: U;
};

export const translations: Translations<Language, ITextContent> = {
  nl_BE: {
    active: `actieve`,
    agree: `Akkoord`,
    applied: `toegepast`,
    app_name: `Oriënt`,
    cancel: `Annuleren`,
    click_here_for_more_info_profession_description: `Klik hier voor meer info ↓`,
    close_snackbar: `Sluit melding`,
    copy_introduction_text1: `Op basis van een aantal vragen gaan we na wat je interesses zijn.
      Zodra we een zicht hebben op je interesses, stellen we enkele beroepen voor. Daarna volgen er nog vragen.
      Hoe meer vragen je beantwoordt, hoe beter de voorgestelde beroepen bij je interesses passen.`,
    copy_introduction_text2: `De test duurt ongeveer 10 minuten.`,
    copy_introduction_text3: `Meer informatie over privacy vind je hier.`,
    copy_introduction_title: `Ontdek welke beroepen bij je interesses passen`,
    copy_professionwarningcard_text: `We wisselen vanaf nu vragen en beroepen af. Door aan te geven of
      deze beroepen je interesseren, kunnen we je resultaten verfijnen.`,
    copy_results_page_title: `Deze beroepen passen bij je op basis van je interesses`,
    courses: `Opleidingen`,
    disagree: `Niet akkoord`,
    discover_jobs: `Ontdek de beroepen`,
    discover_jobs_text_at_end_of_questionnaire: `Bedankt voor je antwoorden! We hebben nu een duidelijk beeld van welke beroepen bij je passen op basis van je interesses.`,
    education_level: `scholingsgraad`,
    education_level_low_info: `Kortgeschoolden zijn personen zonder een einddiploma van het secundair onderwijs.`,
    education_level_medium_info: `Middengeschoolden hebben het secundair onderwijs of het postsecundair, niet-hoger onderwijs met succes afgewerkt.`,
    education_level_high_info: `Hooggeschoolden beschikken over een diploma hoger onderwijs.`,
    few_vacancies: `weinig vacatures`,
    filter: `filter`,
    filter_your_results: `filter je resultaten`,
    filters: `filters`,
    go_on: `Ga verder`,
    highly_educated: `hooggeschoold`,
    interesses_me: "interesseert me",
    interest_in: "Interesse in",
    interest_score: "interessescore",
    jobs: "beroepen",
    jobs_that_may_suit_you_based_on_interest: "Beroepen die misschien bij je passen op basis van je interesse",
    knelpuntberoep: "knelpuntberoep",
    loading_data_dots: "Gegevens laden...",
    less_info: "Minder info",
    lowly_educated: "kortgeschoold",
    no: "nee",
    match: "match",
    medium_educated: "middengeschoold",
    more_info: "Meer info",
    more_information: "Meer informatie",
    no_explanations_available_yet: `Er is nog geen uitleg beschikbaar. Je moet eerst een aantal vragen beantwoorden.`,
    no_results_match_given_criteria: `Momenteel kunnen we je geen beroepen tonen die overeenkomen met jouw antwoorden op de interessevragen of de filters. Doe de test opnieuw  of pas de filters aan om beroepen te zien.`,
    orient: `Oriënt`,
    orientation_questions: `Oriëntatievragen`,
    pdf_your_results: `Jouw antwoorden in de test`,
    pdf_interest_questions: `Interessevragen`,
    pdf_professions: `Beroepen`,
    pdf_positively_answered_questions: `Positief beantwoorde vragen`,
    pdf_negatively_answered_questions: `Negatief beantwoorde vragen`,
    previous: `Vorige`,
    privacy: `privacy`,
    profession_warning: `We wisselen vanaf nu vragen en beroepen af. Door aan te geven of deze beroepen je interesseren, kunnen we je resultaten verfijnen.`,
    questions: `vragen`,
    read_everything_about_profession: `Alles over dit beroep`,
    results: `resultaten`,
    results_page_title: `Beroepen die misschien bij je passen op basis van interesse`,
    save_results: `Resultaten opslaan`,
    save_as_pdf: `Download pdf`,
    save_on_vdab_be: `Opslaan op vdab.be`,
    save_on_vdab_be_error: `Er ging iets mis bij het opslaan. Probeer het later opnieuw`,
    save_on_vdab_be_explanation: `Als je bent ingelogd, kan je deze resultaten terugvinden op www.vdab.be/orienteren`,
    save_on_vdab_be_saving: `Opslaan...`,
    save_on_vdab_be_success: `Opgeslagen`,
    see_vacancies: `Bekijk vacatures`,
    send_results: `resultaten verzenden`,
    see_courses: "Bekijk opleidingen",
    send_results_cta1: `Je toegepaste filters`,
    send_results_cta2: `Beroepen die misschien bij je passen op basis van je interesse`,
    send_results_cta3: `Je antwoorden in de test`,
    send_results_mail_empty: `Vul een e-mailadres in`,
    send_results_mail_invalid: `Ongeldig e-mailadres`,
    send_results_mail_sent: `Je resultaten werden opgestuurd naar jouw mailadres!`,
    send_results_modal_title: `Je kan je resultaten mailen naar jezelf en je begeleider. Je stuurt een e-mail met als bijlage:`,
    send_results_optional_attendant_mail: `Optioneel: e-mailadres van je begeleider`,
    send_results_sending_mail: `Je resultaten worden opgestuurd naar jouw mailadres...`,
    send_results_sending_mail_error: `Er ging iets mis bij het verzenden van de resultaten. Probeer het later nog eens`,
    send_results_via_mail_privacy_disclaimer: `We slaan de e-mail adressen niet permanent op en gebruiken deze enkel voor het versturen van de mail.`,
    send_via_mail: `Verstuur via mail`,
    something_went_wrong_general_message: `Sorry, er is iets misgegaan`,
    something_went_wrong_general_message_content_part_1: `Probeer het`,
    something_went_wrong_general_message_content_part_2: `opnieuw`,
    something_went_wrong_general_message_content_part_3: `of ga naar`,
    something_went_wrong_general_message_content_part_4: `Welke job past bij me`,
    start_test: `Start de test`,
    interest_in_profession_tag_message: `Je gaf tijdens de test aan dat dit beroep je interesseert`,
    temporary_results: `Voorlopige resultaten`,
    the_job: "Het beroep",
    vacancies: "Vacatures",
    what_you_like_about_this_profession: "Wat je leuk vindt aan dit beroep",
    what_you_dislike_about_this_profession: "Wat je minder leuk vindt aan dit beroep",
    yes: "ja",
    your_email: "Je e-mailadres",
    your_result: "Je resultaat",
  },
};

export interface ITextOptions {
  capitalize?: boolean;
  onlyFirst?: boolean;
  language?: Language;
}

export const defaultTextOptions: ITextOptions = {
  capitalize: false,
  onlyFirst: false,
};

export const capitalizeFirstLetterOnly: ITextOptions = {
  capitalize: true,
  onlyFirst: true,
};

export const getTextContent = (key: keyof ITextContent, textOptions: ITextOptions | undefined = undefined): string => {
  const language: Language = textOptions?.language ?? Language.DUTCH;
  const options: ITextOptions = {
    ...defaultTextOptions,
    ...textOptions,
    language: language,
  };
  const text = translations[language][key];
  if (options.capitalize) {
    return capitalize(text, options.onlyFirst);
  }
  return text;
};

@Injectable({
  providedIn: "root",
})
export class TextContentService {
  // Data

  // Lifecycle

  // Interface methods

  static getText(key: keyof ITextContent, textOptions: ITextOptions | undefined = undefined): string {
    const language: Language = textOptions?.language ?? Language.DUTCH;
    const options: ITextOptions = {
      ...defaultTextOptions,
      ...textOptions,
      language: language,
    };
    const text = translations[language][key];
    if (options.capitalize) {
      return capitalize(text, options.onlyFirst);
    }
    return text;
  }

  public getText(key: keyof ITextContent, textOptions: ITextOptions | undefined = undefined): string {
    return getTextContent(key, textOptions);
  }
}

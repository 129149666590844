<footer>
  <div class="c-footer__flanders">
    <div class="c-footer__flanders__container o-wrapper">
      <a
        href="https://www.vlaanderen.be"
        class="c-footer__flanders__backslash-before -logo-backslash c-footer__flanders__link -logo-link">
        Dit is een officiële website van de Vlaamse overheid
      </a>
      <div class="c-footer__flanders__container">
        <a
          class="c-footer__flanders__backslash-before c-footer__flanders__link"
          href="https://www.vdab.be/disclaimer">
          Disclaimer
        </a>
        <a
          class="c-footer__flanders__backslash-before c-footer__flanders__link"
          href="https://www.vdab.be/privacy">
          Privacy
        </a>
        <ul
          class="c-footer__flanders__backslash-before u-reset-list c-footer__flanders__language"
          aria-label="Taalkeuze">
          <li
            class="c-footer__flanders__language__item"
            aria-current="page"
            aria-label="Nederlands">
            <strong>NL</strong>
          </li>
          <li class="c-footer__flanders__language__item">
            <a
              href="https://www.vdab.be/francais"
              class="c-footer__flanders__link"
              aria-label="Français"
              hreflang="français"
              >FR</a
            >
          </li>
          <li class="c-footer__flanders__language__item">
            <a
              href="https://www.vdab.be/deutsch"
              class="c-footer__flanders__link"
              aria-label="Deutsch"
              hreflang="deutsch"
              >DE</a
            >
          </li>
          <li class="c-footer__flanders__language__item">
            <a
              href="https://www.vdab.be/english"
              class="c-footer__flanders__link"
              aria-label="English"
              hreflang="english"
              >EN</a
            >
          </li>
          <li class="c-footer__flanders__language__item">
            <a
              href="https://www.vdab.be/video"
              class="c-footer__flanders__link"
              aria-label="Andere talen"
              >+...</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import {
  BehaviorSubject,
  debounceTime,
  Observable,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  takeUntil,
  throttleTime,
} from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { objLength } from "../../../../utils/object";
import { AnswerInteractionTypes } from "../../../models/Enums";
import { INextQuestionSummary, isProfessionQuestionSummary } from "../../../models/INextQuestionSummary";
import { IQuestionHistoryLog } from "../../../models/IQuestionHistoryLog";
import { HttpService } from "../../../services/http.service";
import { StyleService } from "../../../services/style.service";
import { TextContentService } from "../../../services/text-content.service";
import { QuestionFacade } from "../../../state/Question/Question.facade";

@Component({
  selector: "app-question-card",
  templateUrl: "./question-card.component.html",
  styleUrls: ["./question-card.component.scss"],
})
export class QuestionCardComponent implements OnDestroy {
  // Data

  @ViewChild("cardImg") cardImg!: ElementRef<HTMLInputElement>;
  @ViewChild("questionText") questionTextRef!: ElementRef<HTMLInputElement>;

  public isFirstQuestion$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public goBackBtnClicked$: Subject<INextQuestionSummary> = new Subject<INextQuestionSummary>();
  public answerBtnClicked$: Subject<[INextQuestionSummary, AnswerInteractionTypes]> = new Subject<
    [INextQuestionSummary, AnswerInteractionTypes]
  >();
  public showDescriptionTrigger$: ReplaySubject<boolean | null> = new ReplaySubject<boolean | null>();
  // Toggles description if true, does nothing if false
  public toggleShowDescriptionTrigger$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public showDescription$: Observable<boolean>;
  public showInfoTooltip$: Observable<boolean>;
  public descriptionAriaLabel$!: Observable<string>;
  public showDescriptionButton$: Observable<boolean>;

  public toggleDescriptionButtonIcon$: BehaviorSubject<string> = new BehaviorSubject<string>("mdi-chevron-down");

  public professionLinkUrl$: Observable<string>;

  public goBackBtnClickedSubscription$: Subscription;
  public answerBtnSubscription$: Subscription;
  public destroy$: Subject<void> = new Subject<void>();

  public likeWithKeyboard$: Subject<void> = new Subject<void>();
  public dislikeWithKeyboard$: Subject<void> = new Subject<void>();

  // Lifecycle
  constructor(
    public tcs: TextContentService,
    public questionFacade: QuestionFacade,
    public httpService: HttpService,
    public styleService: StyleService
  ) {
    // Observables

    this.loading$ = this.createLoadingDataHandler();
    this.isFirstQuestion$ = this.isFirstQuestionHandler();
    this.showInfoTooltip$ = this.showInfoTooltipHandler();
    this.showDescriptionButton$ = this.showDescriptionButtonHandler();
    this.showDescription$ = this.showDescriptionHandler();
    this.toggleShowDescriptionTrigger$.subscribe((toggle: boolean) => {
      this.questionFacade.toggleDescription(toggle);
    });
    this.descriptionAriaLabel$ = this.descriptionAriaLabelHandler();
    const suggestedProfessionTuple$ = this.questionFacade.nextQuestionSummary$.pipe(
      map((nextQuestion: INextQuestionSummary): [string, string] => [nextQuestion.id, nextQuestion.question])
    );
    this.professionLinkUrl$ = this.httpService.createProfessionLinkUrlHandler(suggestedProfessionTuple$);

    this.goBackBtnClickedSubscription$ = this.registerGoBackBtnClickedHandler();
    this.answerBtnSubscription$ = this.registerAnswerBtnHandler();

    this.likeWithKeyboard$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.questionFacade.nextQuestionSummary$), throttleTime(100))
      .subscribe(([, nextQuestionSummary]) => {
        this.like(nextQuestionSummary);
      });
    this.dislikeWithKeyboard$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.questionFacade.nextQuestionSummary$), throttleTime(100))
      .subscribe(([, nextQuestionSummary]) => {
        this.dislike(nextQuestionSummary);
      });

    document.addEventListener("keydown", (event) => this.arrowKeyEventHandler(event));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    document.removeEventListener("keydown", (event) => this.arrowKeyEventHandler(event));
  }

  // Interface methods

  public like(nextQuestion: INextQuestionSummary) {
    this.toggleShowDescriptionTrigger$.next(false);
    this.answerBtnClicked$.next([nextQuestion, AnswerInteractionTypes.Like]);
  }

  public dislike(nextQuestion: INextQuestionSummary) {
    this.toggleShowDescriptionTrigger$.next(false);
    this.answerBtnClicked$.next([nextQuestion, AnswerInteractionTypes.Dislike]);
  }

  public goBack(nextQuestion: INextQuestionSummary) {
    this.toggleShowDescriptionTrigger$.next(false);
    this.goBackBtnClicked$.next(nextQuestion);
  }

  public toggleShowDescription() {
    this.toggleShowDescriptionTrigger$.next(true);
  }

  // Getters & setters

  // Helper methods

  public arrowKeyEventHandler(event: KeyboardEvent) {
    if (event.key === "ArrowUp") {
      this.likeWithKeyboard$.next();
    } else if (event.key === "ArrowDown") {
      this.dislikeWithKeyboard$.next();
    }
  }

  private isFirstQuestionHandler(): Observable<boolean> {
    return this.questionFacade.questionHistory$.pipe(
      takeUntil(this.destroy$),
      map((questionHistory: IQuestionHistoryLog[]) => questionHistory.length === 1),
      shareReplay(1)
    );
  }

  private showInfoTooltipHandler(): Observable<boolean> {
    return this.questionFacade.nextQuestionSummary$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.questionFacade.answeredOccupationalProfileQuestions$),
      map(
        ([currentQuestion, answeredOccupationalProfileQuestions]) =>
          isProfessionQuestionSummary(currentQuestion) && objLength(answeredOccupationalProfileQuestions.answers) === 0
      ),
      shareReplay(1)
    );
  }

  private showDescriptionButtonHandler(): Observable<boolean> {
    return this.questionFacade.nextQuestionSummary$.pipe(
      takeUntil(this.destroy$),
      map((currentQuestion: INextQuestionSummary) => isProfessionQuestionSummary(currentQuestion)),
      shareReplay(1)
    );
  }

  private showDescriptionHandler(): Observable<boolean> {
    return this.questionFacade.showDescription$.pipe(shareReplay(1));
  }

  private descriptionAriaLabelHandler(): Observable<string> {
    return this.showDescription$.pipe(
      takeUntil(this.destroy$),
      map((showDescription) => this.tcs.getText(showDescription ? "less_info" : "more_info"))
    );
  }

  private registerGoBackBtnClickedHandler(): Subscription {
    return this.goBackBtnClicked$.pipe(takeUntil(this.destroy$)).subscribe((currentQuestion: INextQuestionSummary) => {
      this.questionFacade.answerQuestion({
        currentQuestion: currentQuestion,
        interactionType: AnswerInteractionTypes.Back,
      });
    });
  }

  private createLoadingDataHandler(): Observable<boolean> {
    return this.questionFacade.loading$.pipe(
      takeUntil(this.destroy$),
      debounceTime(environment.LOADING_DELAY),
      startWith(false),
      shareReplay(1)
    );
  }

  private registerAnswerBtnHandler(): Subscription {
    return this.answerBtnClicked$
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentQuestion, answerInteractionType]) => {
        return this.questionFacade.answerQuestion({
          currentQuestion: currentQuestion,
          interactionType: answerInteractionType,
        });
      });
  }
}

<div
  class="c-donut-chart-container-wrapper u-display-inline-block"
  [style.padding-bottom]="bottomPaddingForHalfDonutChart"
  [attr.data-e2e]="e2e.vdabAiHalfDonutChartRoot"
>
  <div class="c-donut-chart-container">
    <div
      class="c-donut-chart"
      [style.--percentage]="formattedPercentage"
      [style.--fill]="color"
      [class.-no-animation]="disableAnimation"
      [attr.data-e2e]="e2e.vdabAiHalfDonutChartHuisstijlRoot"
    ></div>
    <span
      class="c-donut-chart__percentage"
      [attr.data-e2e]="e2e.vdabAiHalfDonutChartPercentage"
    >{{formattedPercentage}} %</span>
    <span
      class="c-donut-chart__text"
      [attr.data-e2e]="e2e.vdabAiHalfDonutChartTextElement"
    >{{text}}</span>
    <span
      *ngIf="enableInfoIcon"
      class="c-donut-chart__info mdi mdi-information"
      [style.top]="topPlacementForInformationIcon"
      [attr.data-e2e]="e2e.vdabAiHalfDonutChartInfoIcon"
    ></span>
  </div>
</div>

<div
  class="vdab-ai-range-slider wrapper"
  [attr.data-e2e]="dataE2eRoot"
>
  <div
    class="values u-mb15"
    [attr.data-e2e]="dataE2eTypeInputContainerElement"
  >
    <vdab-ai-input
      class="value-input-1"
      type="int"
      [value]="minValue?.toString() ?? ''"
      (valueChange)="minValueAsInputValueChangeHandler($event)"
      (onEnter)="minValueAsInputChangedHandler($event)"
      (onBlur)="minValueAsInputChangedHandler($event)"
      [error]="false"
      [errorMessage]=""
      [disabled]="disabled"
      [attr.data-e2e]="dataE2eMinTypeInputElement"
    ></vdab-ai-input>
    <span class="u-mx05">Tot</span>
    <vdab-ai-input
      class="value-input-2"
      type="int"
      [value]="maxValue?.toString() ?? ''"
      (valueChange)="maxValueAsInputValueChangeHandler($event)"
      (onEnter)="maxValueAsInputChangedHandler($event)"
      (onBlur)="maxValueAsInputChangedHandler($event)"
      [error]="false"
      [errorMessage]=""
      [disabled]="disabled"
      [attr.data-e2e]="dataE2eMaxTypeInputElement"
    ></vdab-ai-input>
  </div>
  <div class="container"
       [class.disabled]="disabled"
       [attr.data-e2e]="dataE2eSliderContainerElement"
  >
    <div #sliderTrack class="slider-track"></div>
    <input
      #minValueInputTickRef
      type="range"
      [min]="minLimit"
      [max]="maxLimit"
      [value]="minValueInputTick"
      (change)="minValueAsTickChanged()"
      [disabled]="disabled"
      [attr.data-e2e]="dataE2eMinDragInputElement"
    >
    <input
      #maxValueInputTickRef
      type="range"
      [min]="minLimit"
      [max]="maxLimit"
      [value]="maxValueInputTick"
      (change)="maxValueAsTickChanged()"
      [disabled]="disabled"
      [attr.data-e2e]="dataE2eMaxDragInputElement"
    >
  </div>
</div>

<div
  class="vdab-ai-progress-linear progress-linear-background-bar"
  [attr.data-e2e]="e2e.vdabAiProgressLinearRoot"
>
  <div
    class="progress-linear-fill-bar"
    [style.height]="height"
    [style.width]="formattedProgress"
    [style.background-color]="fillColor"
    [attr.data-e2e]="e2e.vdabAiProgressLinearProgress"
  ></div>
</div>

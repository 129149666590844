import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  OnInit,
  QueryList,
} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {TabComponent} from "../tab/tab.component";

@Component({
  selector: "vdab-ai-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
/**
 * Tabs component has a single slot containing one or multiple vdab-ai-tabs components
 *
 * See example in 'examples/tabs-example.component.html'
 * */
export class TabsComponent implements OnInit, AfterContentInit, AfterViewInit {
  // Data

  /**
   * List of all child vdab-ai-tab components that are nested inside the vdab-ai-tabs container
   * */
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  // Lifecycle

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  // contentChildren are set
  ngAfterContentInit() {
    if (this.tabs.length > 0) {

      // get all active tabs
      let activeTabs = this.tabs.filter((tab) => tab.active);
      // if there is no active tab set, activate the first
      if (activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      }
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  // Interface methods

  public selectTab(tab: TabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.setActive(false)));

    // activate the tab the user has clicked on.
    tab.setActive(true);
    this.cdr.detectChanges()
  }

  // Getters & setters

  public get e2e() {
    return e2e;
  }

  // Helper methods
}

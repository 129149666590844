import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'sortQuestionsByImpact'
})
export class SortQuestionsByImpactPipe implements PipeTransform {

  transform(questions: { [p: string]: number } ): { key: string; value: number }[] {
    const questionsOrderedByImpact: { [key: string]: number } = Object.entries(questions)
      // Sort questions so the highest impact is first
      .sort(([, questionAImpact], [, questionBImpact]) => questionBImpact - questionAImpact)
      // Convert to key-value pair (questionTitle: questionImpact)
      .reduce((r, [questionTitle, questionImpact]) => ({ ...r, [questionTitle]: questionImpact }), {});
    let out: { key: string; value: number }[] = [];
    for (const key of Object.keys(questionsOrderedByImpact)) {
      out.push({ key: key, value: questionsOrderedByImpact[key] });
    }

    out = out.slice(0, environment.AMT_OF_EXPLANATIONS_TO_SHOW_FOR_SUGGESTED_PROFESSION);

    return out;
  }

}

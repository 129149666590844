<ng-container *ngIf="textContentService.textContent$ | async as textContent">
  <div
    #snackbar
    class="c-snackbar"
    [class.-neutral]="neutral"
    [class.-warning]="warning"
    [class.-mini]="mini"
    [class.-light]="light"
    [class.is-shown]="isShown"
    role="alert"
    [attr.data-e2e]="e2e.vdabAiSnackBarRoot"
  >
    <span class="c-snackbar__content" [attr.data-e2e]="e2e.vdabAiSnackBarContentContainerElement">
      <ng-content select="[content]"></ng-content>
    </span>
    <div class="c-snackbar__actions" [attr.data-e2e]="e2e.vdabAiSnackBarActionsContainerElement">
      <ng-content select="[actions]"></ng-content>
      <button
        *ngIf="withClose"
        class="c-snackbar__close c-round-btn -ghost u-ml05"
        [attr.aria-label]="textContent.close"
        (click)="isShown = false"
        [attr.data-e2e]="e2e.vdabAiSnackBarActionsCloseBtn"
      >
        <span class="icon-close"></span>
      </button>
    </div>
  </div>
</ng-container>

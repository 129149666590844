import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { catchError, combineLatest, NEVER, Observable, of, ReplaySubject, switchMap, withLatestFrom } from "rxjs";

import { WithStatus, WithStatusError } from "../../../../utils/with-status";
import { Loading, LoadingEnum } from "../../../models/Enums";
import { INextQuestion } from "../../../models/INextQuestion";
import { ISession } from "../../../models/ISession";
import { toApi } from "../../../models/mapper";
import { HttpService } from "../../../services/http.service";
import { capitalizeFirstLetterOnly, TextContentService } from "../../../services/text-content.service";
import { ISuggestedOccupationalProfile } from "../../../models/ISuggestedOccupationalProfile";
import { Empty } from "../../../models/Empty";
import {environment} from '../../../../environments/environment';

declare global {
  interface Window {
    grecaptcha: any
  }
}

@Component({
  selector: "app-save-results-modal-form",
  templateUrl: "./save-results-modal-form.component.html",
  styleUrls: ["./save-results-modal-form.component.scss"],
})
export class SaveResultsModalFormComponent implements AfterViewInit {
  // Data

  @Input() session$: Observable<ISession> = of(Empty.session());
  @Input() filteredOccupationalProfiles$: Observable<ISuggestedOccupationalProfile[]> = of([]);

  public form!: FormGroup;
  public formMayBeValidated = false;

  public userEmailErrorMsg = "";

  public attendantEmailErrorMsg = "";

  @Output() mailSent: EventEmitter<Loading> = new EventEmitter<Loading>();

  public sendResultsButtonLabel: string;

  public canSendPdfMail = false;

  public sendPdfMail$ = new ReplaySubject<void>();

  // Lifecycle

  constructor(
    public tcs: TextContentService,
    public httpService: HttpService
  ) {
    this.sendResultsButtonLabel = this.tcs.getText("send_results", capitalizeFirstLetterOnly);
    this.form = new FormGroup({
      userEmail: new FormControl("", [Validators.required, Validators.email]),
      attendantEmail: new FormControl("", [Validators.email, this.differentEmailValidator()]),
    });

    // validate when input values change
    this.form.valueChanges.subscribe((form) => {
      this.validateEmails();
    });
  }

  ngAfterViewInit() {
    this.sendPdfMail$
      .pipe(
        withLatestFrom(combineLatest([this.recaptcha(), this.session$, this.filteredOccupationalProfiles$])),
        switchMap(([, [token, session, filteredOccupationalProfiles]]) => {
          if (this.canSendPdfMail) {
            session.suggestedOccupationalProfiles = filteredOccupationalProfiles;
            return this.httpService.sendPdfMail(toApi.Session(session), this.userEmail, this.attendantEmail || null, token);
          }
          return NEVER;
        }),
        catchError((err) => of(<WithStatusError<INextQuestion>>{ status: "error", error: err.message }))
      )
      .subscribe((response: WithStatus<any>) => {
        if (response.status === "loading") {
          this.mailSent.emit(LoadingEnum.Loading);
        } else if (response.status === "done") {
          this.mailSent.emit(LoadingEnum.Success);
          this.form.controls["userEmail"].setValue("");
          this.form.controls["attendantEmail"].setValue("");
          this.formMayBeValidated = false;
        } else if (response.status === "error") {
          this.mailSent.emit(LoadingEnum.Error);
        } else {
          console.error(`Unhandled case: ${response}`);
        }
      });
  }

  // Interface methods

  public sendPdfMail() {
    this.formMayBeValidated = true;
    if (this.canSendPdfMail) {
      this.sendPdfMail$.next();
    }
  }

  public differentEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.form && control.value && this.userEmail === control.value ? { sameEmail: true } : null;
    };
  }

  public validateEmails() {
    // Reset errors
    this.userEmailErrorMsg = "";
    this.attendantEmailErrorMsg = "";

    // Set errors based on form control's validation status
    if (this.form.controls["userEmail"].invalid) {
      if (this.userEmail.length === 0) {
        this.userEmailErrorMsg = this.tcs.getText("send_results_mail_empty", capitalizeFirstLetterOnly);
      }
      this.userEmailErrorMsg = this.tcs.getText("send_results_mail_invalid", capitalizeFirstLetterOnly);
    }

    if (this.form.controls["attendantEmail"].invalid) {
      this.attendantEmailErrorMsg = this.tcs.getText("send_results_mail_invalid", capitalizeFirstLetterOnly);
    }

    this.canSendPdfMail = this.form.valid;
  }

  public onUserEmailChanged(value: string) {
    this.formMayBeValidated = true;
    this.form.controls["userEmail"].setValue(value, { emitEvent: false });
    this.validateEmails();
  }

  public onAttendantEmailChanged(value: string) {
    this.formMayBeValidated = true;
    this.form.controls["attendantEmail"].setValue(value, { emitEvent: false });
    this.validateEmails();
  }

  // Getters and setters

  public get userEmail(): string {
    return this.form.controls["userEmail"].value;
  }

  public get attendantEmail(): string {
    return this.form.controls["attendantEmail"].value;
  }

  public get userEmailError(): boolean {
    return this.formMayBeValidated && this.form.controls["userEmail"].invalid;
  }

  public get attendantEmailError(): boolean {
    return this.formMayBeValidated && this.form.controls["attendantEmail"].invalid;
  }

  recaptcha(): Promise<string> {
    return new Promise((resolve) => {
      window.grecaptcha.enterprise.ready(async () => {
        const token = await window.grecaptcha.enterprise.execute(environment.RECAPTCHA_SITE_KEY, {action: '/api/pdf/mail'});
        resolve(token)
      });
    })

  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {e2e} from "../../e2e/dataE2eValues";
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: 'vdab-ai-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  // Data

  /**
   * Appearance of alert
   */
  @Input() appearance: "info" | "success" | "warning" | "danger" = 'info';
  /**
   * Whether alert is fixed on top
   */
  @Input() fixed: boolean = false;
  /**
   * Whether fixed alert is wide.
   * Only works when fixed property is enabled!
   */
  @Input() wide: boolean = false;
  /**
   * Whether alert is removable when clicking on close.
   * Default set to true
   */
  @Input() removable: boolean = true;


  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();

  // Lifecycle

  constructor() {
  }

  ngOnInit(): void {
    if (!this.appearance) {
      this.appearance = "info"
    }

    if (isNullOrUndefined(this.fixed)) {
      this.fixed = false
    }

    if (isNullOrUndefined(this.wide)) {
      this.wide = false
    }
    if (isNullOrUndefined(this.removable)) {
      this.removable = true
    }
  }

  // Interface methods

  public onClick(event: any) {
    this.closeClicked.emit(event);
  }

  // Getters & setters

  public get info(): boolean {
    return this.appearance === "info";
  }

  public get success(): boolean {
    return this.appearance === "success";
  }

  public get warning(): boolean {
    return this.appearance === "warning";
  }

  public get danger(): boolean {
    return this.appearance === "danger";
  }

  public get e2e() {
    return e2e;
  }

  // Helper methods

}

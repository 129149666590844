import {Component, ElementRef, EventEmitter, Input, Output, ViewChild,} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";

let checkboxId = 0;

@Component({
  selector: "vdab-ai-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
/**
 * Checkbox component
 *
 * See example in 'examples/checkbox-example.component.html'
 * */
export class CheckboxComponent{
  // Data

  /**
   * Primary label for the checkbox
   * E.g [] Filter on knelpuntberoep
   */
  @Input() label!: string;
  /**
   * Secondary label for the checkbox (part between parentheses)
   * E.g [] Filter on knelpuntberoep (12)
   */
  @Input() metaLabel: string = "";
  /**
   * Value indicating whether checkbox is checked or not
   */
  @Input() value: boolean = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("checkbox") checkbox!: ElementRef;

  public id = "checkbox-" + checkboxId++;

  public emitValueChanged() {
    this.valueChange.emit(this.value);
  }

  public get e2e() {
    return e2e;
  }
}

import { Component, OnInit } from "@angular/core";
import isEqual from "lodash.isequal";
import { combineLatest, mergeWith, Observable, shareReplay, startWith, Subject, takeUntil } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { IEnvironment } from "../../../../environments/IEnvironment";
import { Empty } from "../../../models/Empty";
import { Loading, LoadingEnum, Werkniveau } from "../../../models/Enums";
import { ISuggestedOccupationalProfile } from "../../../models/ISuggestedOccupationalProfile";
import { capitalizeFirstLetterOnly, TextContentService } from "../../../services/text-content.service";
import { QuestionFacade } from "../../../state/Question/Question.facade";
import { ResultFacade } from "../../../state/Result/Result.facade";

@Component({
  selector: "app-results-page",
  templateUrl: "./results-page.component.html",
  styleUrls: ["./results-page.component.scss"],
})
export class ResultsPageComponent implements OnInit {
  // Data

  public tempResultsTitleDesktop$: Observable<string>;
  public tempResultsTitleMobile$: Observable<string>;
  public noResultForFilterCriteriaLabel: string;
  public filterLabel: string;
  public filterYourResultsLabel: string;
  public filteredResultCount$: Observable<string>;

  public suggestedProfessions$: Observable<ISuggestedOccupationalProfile[]>;
  public selectedSuggestedProfession$: Subject<ISuggestedOccupationalProfile> =
    new Subject<ISuggestedOccupationalProfile>();
  public showExplanationModal = false;
  public showSaveResultsModal = false;

  public showMobileFilterModal = false;
  public activeFiltersCount$: Observable<number>;
  public formattedActiveFiltersCount$: Observable<string>;

  public error$: Observable<boolean>;
  public destroy$: Subject<void> = new Subject();

  // Lifecycle
  constructor(
    public tcs: TextContentService,
    public questionFacade: QuestionFacade,
    public resultFacade: ResultFacade
  ) {
    // Observables
    this.suggestedProfessions$ = this.resultFacade.filteredSuggestedOccupationalProfiles$.pipe(
      distinctUntilChanged((previous, current) => isEqual(previous, current)),
      shareReplay(1)
    );

    this.tempResultsTitleDesktop$ = this.createTempResultsTitleDesktopHandler();
    this.tempResultsTitleMobile$ = this.createTempResultsTitleMobileHandler();
    this.noResultForFilterCriteriaLabel = this.tcs.getText(
      "no_results_match_given_criteria",
      capitalizeFirstLetterOnly
    );
    this.filterLabel = this.tcs.getText("filter", capitalizeFirstLetterOnly);
    this.filterYourResultsLabel = this.tcs.getText("filter_your_results", capitalizeFirstLetterOnly);
    this.filteredResultCount$ = this.resultFacade.filteredSuggestedOccupationalProfiles$.pipe(
      takeUntil(this.destroy$),
      map(
        (filteredSuggestedProfessions: ISuggestedOccupationalProfile[]) =>
          `${filteredSuggestedProfessions.length} ${this.tcs.getText("results")}`
      )
    );

    this.error$ = this.createErrorHandler();

    this.activeFiltersCount$ = this.createActiveFiltersCountHandler();
    this.formattedActiveFiltersCount$ = this.activeFiltersCount$.pipe(
      takeUntil(this.destroy$),
      map((count: number) => {
        const suffix = count > 1 ? this.tcs.getText("filters") : this.tcs.getText("filter");
        return `${count} ${this.tcs.getText("active")} ${suffix}`;
      }),
      shareReplay(1)
    );
  }

  ngOnInit(): void {
    this.questionFacade.answerQuestion(Empty.answer());
    document.getElementsByTagName("body")[0].style.overscrollBehavior = "contain";
  }

  // Interface methods

  public openExplanationModal(suggestedProfession: ISuggestedOccupationalProfile) {
    this.showExplanationModal = true;
    this.selectedSuggestedProfession$.next(suggestedProfession);
  }

  public toggleShowMobileFilterModal() {
    this.showMobileFilterModal = !this.showMobileFilterModal;
  }

  public navigateToHome() {
    // Redirect to initial page. This forces to reset state of the application
    window.location.href = "";
  }

  public toggleBlockScrollOnBody(modalShouldOpen: boolean) {
    const body = document.getElementsByTagName("body")[0];
    if (body) {
      if (modalShouldOpen) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }

  // Getters & setters

  public get environment(): IEnvironment {
    return environment;
  }

  // Helper methods

  private createErrorHandler(): Observable<boolean> {
    const getSessionError$: Observable<boolean> = this.resultFacade.sessionLoading$.pipe(
      takeUntil(this.destroy$),
      map((loading: Loading): boolean => loading === LoadingEnum.Error),
      shareReplay(1)
    );

    return this.questionFacade.error$.pipe(takeUntil(this.destroy$), mergeWith(getSessionError$), shareReplay(1));
  }

  private createTempResultsTitleDesktopHandler(): Observable<string> {
    return this.suggestedProfessions$.pipe(
      takeUntil(this.destroy$),
      map((suggestedProfessions: ISuggestedOccupationalProfile[]) => {
        return `${this.tcs.getText("results_page_title")} (${suggestedProfessions.length})`;
      })
    );
  }

  private createTempResultsTitleMobileHandler(): Observable<string> {
    return this.suggestedProfessions$.pipe(
      takeUntil(this.destroy$),
      map((suggestedProfessions: ISuggestedOccupationalProfile[]) => {
        return `${this.tcs.getText("your_result")} (${suggestedProfessions.length})`;
      })
    );
  }

  private createActiveFiltersCountHandler(): Observable<number> {
    const combined = combineLatest(
      this.resultFacade.werkniveaus$,
      this.resultFacade.isKnelpuntBeroep$,
      this.resultFacade.isNotKnelpuntBeroep$
    );
    return combined.pipe(
      takeUntil(this.destroy$),
      map(([werkniveau, isKnelpuntberoepFilter, isNoKnelpuntberoepFilter]: [Werkniveau[], boolean, boolean]) => {
        const a: number = isKnelpuntberoepFilter ? 1 : 0;
        const b: number = isNoKnelpuntberoepFilter ? 1 : 0;
        return werkniveau.length + a + b;
      }),
      startWith(0),
      shareReplay(1)
    );
  }

  public trackBySuggestedProfession(index: number, suggestedProfession: ISuggestedOccupationalProfile): string {
    return suggestedProfession.id;
  }
}

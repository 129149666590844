import { isNullOrUndefined } from "../../utils/object";
import { IRatedAnswers } from "./IRatedAnswers";
import { ISuggestedOccupationalProfile } from "./ISuggestedOccupationalProfile";
import {Session} from '../../api/models/session';
import {DynamoDbSavedSession} from '../../api/models/dynamo-db-saved-session';

export interface ISession {
  date: Date;
  sessionId: string;
  ratedInterestQuestions: IRatedAnswers;
  ratedOccupationalProfilesQuestions: IRatedAnswers;
  suggestedOccupationalProfiles: ISuggestedOccupationalProfile[];
}

export const isSessionType = (session: Session | DynamoDbSavedSession | null | undefined): session is Session => {
  return (
    !isNullOrUndefined(session) &&
    "session_id" in session &&
    "date" in session &&
    "rated_questions" in session &&
    "rated_occupational_profiles" in session &&
    "suggested_occupational_profiles" in session
  );
};

export const isDynamoDBSavedSessionType = (
  session: Session | DynamoDbSavedSession | null | undefined
): session is DynamoDbSavedSession => {
  return (
    !isNullOrUndefined(session) &&
    "session_id" in session &&
    "metadata" in session &&
    "rated_questions" in session &&
    "rated_occupational_profiles" in session &&
    "suggested_occupational_profiles" in session
  );
};

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {VarDirective} from "./directives/ngvar";
import {AccordionComponent} from "./common/accordion/accordion.component";
import {CheckboxComponent} from "./common/checkbox/checkbox.component";
import {DialogBoxComponent} from "./common/dialog-box/dialog-box.component";
import {HalfDonutChartComponent} from "./common/half-donut-chart/half-donut-chart.component";
import {InputComponent} from "./common/input/input.component";
import {ModalComponent} from "./common/modal/modal.component";
import {ProgressLinearComponent} from "./common/progress-linear/progress-linear.component";
import {RangeSliderComponent} from "./common/range-slider/range-slider.component";
import {SnackbarComponent} from "./common/snackbar/snackbar.component";
import {TabComponent} from "./common/tab/tab.component";
import {TabsComponent} from "./common/tabs/tabs.component";
import {TagComponent} from "./common/tag/tag.component";
import {TooltipComponent} from "./common/tooltip/tooltip.component";
import {AccordionExpanderComponent} from "./common/accordion-expander/accordion-expander.component";
import {ButtonComponent} from "./common/button/button.component";
import {TextContentService} from "./services/text-content.service";
import {CommonModule} from "@angular/common";
import {AlertComponent} from "./common/alert/alert.component";
import {HeaderComponent} from "./common/header/header.component";
import {TextAreaComponent} from "./common/text-area/text-area.component";
import {BrowserModule} from "@angular/platform-browser";
import {DropdownComponent} from './common/dropdown/dropdown.component';
import {EditableDropdownComponent} from './common/editable-dropdown/editable-dropdown.component';
import {AutocompleteComponent} from "./common/autocomplete/autocomplete.component";
import {FabButtonComponent} from './common/fab-button/fab-button.component';
import {BaseButtonComponent} from './common/base-button/base-button.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    VarDirective,
    AlertComponent,
    AccordionComponent,
    AccordionExpanderComponent,
    AutocompleteComponent,
    BaseButtonComponent,
    ButtonComponent,
    CheckboxComponent,
    DialogBoxComponent,
    DropdownComponent,
    FabButtonComponent,
    HalfDonutChartComponent,
    HeaderComponent,
    InputComponent,
    ModalComponent,
    ProgressLinearComponent,
    RangeSliderComponent,
    SnackbarComponent,
    TabComponent,
    TabsComponent,
    TagComponent,
    TextAreaComponent,
    TooltipComponent,
    EditableDropdownComponent,
    FabButtonComponent,
    BaseButtonComponent,
  ],
  imports: [CommonModule, BrowserModule, FormsModule],
  providers: [TextContentService],
  exports: [
    // Directives
    VarDirective,

    // Components
    AlertComponent,
    AccordionComponent,
    AccordionExpanderComponent,
    AutocompleteComponent,
    BaseButtonComponent,
    ButtonComponent,
    CheckboxComponent,
    DialogBoxComponent,
    DropdownComponent,
    EditableDropdownComponent,
    FabButtonComponent,
    HalfDonutChartComponent,
    HeaderComponent,
    InputComponent,
    ModalComponent,
    ProgressLinearComponent,
    RangeSliderComponent,
    SnackbarComponent,
    TabComponent,
    TabsComponent,
    TagComponent,
    TextAreaComponent,
    TooltipComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AngularFrontendComponentsModule {
}

import { Selector } from "@ngxs/store";

import { isNullOrUndefined } from "../../../utils/object";
import {
  AnswerInteractionTypes,
  isBackAnswerInteractionType,
  isDislikeAnswerInteractionType,
  isInterestQuestionType,
  isLikeAnswerInteractionType,
  isMessageAnswerInteractionType,
  isProfessionQuestionType,
  isStartAnswerInteractionType,
  Loading,
} from "../../models/Enums";
import { IAnswer } from "../../models/IAnswer";
import { INextQuestion, isProfessionQuestion } from "../../models/INextQuestion";
import { INextQuestionSummary } from "../../models/INextQuestionSummary";
import { IQuestionHistoryLog } from "../../models/IQuestionHistoryLog";
import { IRatedAnswers } from "../../models/IRatedAnswers";
import { convert } from "../../models/mapper";
import { IQuestionState, QuestionState } from "./Question.state";

export class QuestionSelectors {
  @Selector([QuestionState])
  static nextQuestionInfoLoading(state: IQuestionState): Loading {
    return state.nextQuestionInfoLoading;
  }

  @Selector([QuestionState])
  static nextQuestionImgLoading(state: IQuestionState): Loading {
    return state.nextQuestionImgLoading;
  }

  @Selector([QuestionState])
  static nextQuestionImgUrl(state: IQuestionState): string {
    return state.nextQuestionImgUrl;
  }

  @Selector([QuestionState])
  static answers(state: IQuestionState): IAnswer[] {
    return state.answers;
  }

  @Selector([QuestionState])
  static lastAnswer(state: IQuestionState): IAnswer | null {
    return state.answers.length === 0 ? null : state.answers[state.answers.length - 1];
  }

  @Selector([QuestionState])
  static infoGainProgress(state: IQuestionState): number {
    return state.infoGainProgress;
  }

  @Selector([QuestionState])
  static showDescription(state: IQuestionState): boolean {
    return state.showDescription && isProfessionQuestion(state.nextQuestion);
  }

  @Selector([QuestionState])
  static questionHistoryLogs(state: IQuestionState): IQuestionHistoryLog[] {
    return state.answers.map((answer: IAnswer) => {
      return { logId: answer.currentQuestion.id, questionType: answer.currentQuestion.questionType };
    });
  }

  @Selector([QuestionState])
  static answeredQuestions(state: IQuestionState): IAnswer[] {
    return state.answers.filter(
      (questionAnswer: IAnswer) =>
        isInterestQuestionType(questionAnswer.currentQuestion.questionType) ||
        isProfessionQuestionType(questionAnswer.currentQuestion.questionType)
    );
  }

  @Selector([QuestionState])
  static nextQuestion(state: IQuestionState): INextQuestion {
    return state.nextQuestion;
  }

  @Selector([QuestionSelectors.answeredQuestions])
  static answeredInterestQuestions(answeredQuestions: IAnswer[]): IRatedAnswers {
    const filteredQuestions = answeredQuestions.filter((answeredQuestion: IAnswer) =>
      isInterestQuestionType(answeredQuestion.currentQuestion.questionType)
    );
    return convert.IAnswer.IRatedQuestions(filteredQuestions);
  }

  @Selector([QuestionSelectors.answeredQuestions])
  static answeredOccupationalProfileQuestions(answeredQuestions: IAnswer[]): IRatedAnswers {
    const filteredQuestions = answeredQuestions.filter((answeredQuestion: IAnswer) =>
      isProfessionQuestionType(answeredQuestion.currentQuestion.questionType)
    );
    return convert.IAnswer.IRatedQuestions(filteredQuestions);
  }

  @Selector([QuestionSelectors.answeredQuestions])
  static interactionType(answeredQuestions: IAnswer[]): string {
    const lastAnswer = answeredQuestions.length === 0 ? null : answeredQuestions[answeredQuestions.length - 1];
    const interactionType = lastAnswer?.interactionType ?? AnswerInteractionTypes.Undefined;

    if (isBackAnswerInteractionType(interactionType)) {
      return `previous_${lastAnswer?.currentQuestion.questionType}`;
    } else if (isMessageAnswerInteractionType(interactionType) || isStartAnswerInteractionType(interactionType)) {
      return interactionType;
    } else if (isLikeAnswerInteractionType(interactionType) || isDislikeAnswerInteractionType(interactionType)) {
      return `answer_${lastAnswer?.currentQuestion.questionType} - ${lastAnswer?.currentQuestion.id} - ${interactionType}`;
    } else {
      // If there is no lastAnswer, app is in initial state => Expected behaviour
      if (!isNullOrUndefined(lastAnswer)) {
        console.error("Unhandled case: ", answeredQuestions, lastAnswer, interactionType);
      }
      return AnswerInteractionTypes.Undefined;
    }
  }

  @Selector([QuestionSelectors.nextQuestion])
  static currentQuestion(nextQuestion: INextQuestion): INextQuestionSummary {
    return convert.INextQuestion.rawICurrentQuestion(nextQuestion);
  }
}

import { Observable, shareReplay } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

export interface WithStatusLoading<T> {
  status: "loading";
}

export interface WithStatusError<T> {
  status: "error";
  error: string;
}

export interface WithStatusDone<T> {
  status: "done";
  value: T;
}

export interface StatusStreams<T> {
  value$: Observable<T>;
  loading$: Observable<boolean>;
  isError$: Observable<boolean>;
  error$: Observable<string>;
}

// Generic value annotation type for streams
export type WithStatus<T> = WithStatusLoading<T> | WithStatusError<T> | WithStatusDone<T>;

function isWithStatusLoading<T>(v: WithStatus<T>): v is WithStatusLoading<T> {
  return v.status == "loading";
}

function isWithStatusError<T>(v: WithStatus<T>): v is WithStatusError<T> {
  return v.status == "error";
}

function isWithStatusDone<T>(v: WithStatus<T>): v is WithStatusDone<T> {
  return v.status == "done";
}

// Get value stream from annotated observable
export function getValueStream<T>(ws: Observable<WithStatus<T>>): Observable<T> {
  return ws.pipe(
    filter(isWithStatusDone),
    map((v) => v.value)
  );
}

// Get loading stream from annotated observable
export function getIsLoadingStream<T>(ws: Observable<WithStatus<T>>) {
  return ws.pipe(map(isWithStatusLoading), distinctUntilChanged(), shareReplay(1));
}

// Get whether the stream has errored from annotated observable
export function getIsErrorStream<T>(ws: Observable<WithStatus<T>>) {
  return ws.pipe(map(isWithStatusError), distinctUntilChanged(), shareReplay(1));
}

// Get the error from annotated observable
export function getErrorStream<T>(ws: Observable<WithStatus<T>>) {
  return ws.pipe(
    filter(isWithStatusError),
    map((v) => v.error),
    shareReplay(1)
  );
}

export function getStatusStreams<T>(ws: Observable<WithStatus<T>>): StatusStreams<T> {
  return {
    value$: ws.pipe(getValueStream),
    loading$: ws.pipe(getIsLoadingStream),
    isError$: ws.pipe(getIsErrorStream),
    error$: ws.pipe(getErrorStream),
  };
}

<div class="card-container">
  <div class="profession-warning-content-container u-m2">
    <div class="info-container">
      <ng-container *ngIf="questionFacade.showProfessionWarning$ | async as showProfessionWarning">
        <span class="mdi mdi-information circle-icon-big vdab-text-color-blue"></span>
        <p class="u-font-weight-strong">{{ this.tcs.getText("profession_warning") }}</p>
      </ng-container>
      <ng-container *ngIf="showEndQuestionnaire$ | async as showEndQuestionnaire">
        <span class="mdi mdi-check-circle circle-icon-big vdab-text-color-green"></span>
        <p class="u-font-weight-strong">
          {{ this.tcs.getText("discover_jobs_text_at_end_of_questionnaire") }}
        </p>
      </ng-container>
    </div>
    <ng-container *ngVar="showGoBackButtonOnEndPage$ | async as showGoBackButtonOnEndPage">
      <ng-container *ngVar="showEndQuestionnaire$ | async as showEndQuestionnaire">
        <div
          class="btn-container"
          [class.btn-container-2-col]="showGoBackButtonOnEndPage"
          [class.btn-container-1-col]="!showGoBackButtonOnEndPage"
        >
          <ng-container *ngIf="questionFacade.showProfessionWarning$ | async as showProfessionWarning">
            <button
              class="go-back-btn fab small-btn c-round-btn -primary u-m05"
              [attr.color]="styleService.styles.vdab_blue_primary"
              tabindex="8"
              [attr.aria-label]="this.tcs.getText('go_on')"
              (click)="goBack()"
            >
              <span class="mdi mdi-chevron-left"></span>
            </button>
            <button class="continue-btn c-btn -primary" (click)="goNext()">{{ this.tcs.getText("go_on") }}</button>
          </ng-container>
          <ng-container *ngIf="showEndQuestionnaire">
            <button
              *ngIf="showGoBackButtonOnEndPage"
              class="go-back-btn fab small-btn c-round-btn -primary u-m05"
              [attr.color]="styleService.styles.vdab_blue_primary"
              tabindex="8"
              [attr.aria-label]="this.tcs.getText('discover_jobs')"
              (click)="goBack()"
            >
              <span class="mdi mdi-chevron-left"></span>
            </button>
            <button class="continue-btn c-btn -primary" (click)="goToResults()">
              {{ this.tcs.getText("discover_jobs") }}
            </button>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="textContentService.textContent$ | async as textContent">
  <ng-container *ngIf="actionLabel$ | async as label">
    <div class="c-accordion__expand-all" [attr.data-e2e]="e2e.vdabAiAccordionExpanderRoot">
      <a
        class="action-expand-down"
        (click)="toggleAccordions()"
        [attr.data-e2e]="e2e.vdabAiAccordionExpanderToggleAllBtn"
      >
        {{ label}}
      </a>
    </div>
  </ng-container>
</ng-container>
<ng-content></ng-content>

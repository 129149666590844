<div class="form-row" [attr.data-e2e]="e2e.vdabAiTextAreaRoot">
  <div class="form-label">
    <label [for]="id" [attr.data-e2e]="e2e.vdabAiTextAreaLabelElement">{{label}}</label>
  </div>
  <div class="form-field" [attr.data-e2e]="e2e.vdabAiTextAreaFormField">
    <textarea
      [id]="id"
      [placeholder]="placeholder"
      [value]="value"
      (keyup)="onChange($event)"
      [cols]="_columns"
      [rows]="_rows"
      [attr.data-e2e]="e2e.vdabAiTextAreaInputElement"
      [disabled]="disabled"
    ></textarea>
  </div>
</div>

<div
  [class.c-fixed-alert-container]="fixed"
  [class.-wide]="wide"
  [attr.data-e2e]="e2e.vdabAiAlertRoot"
>
  <div
    class="c-alert"
    [class.-info]="info"
    [class.-success]="success"
    [class.-warning]="warning"
    [class.-error]="danger"
    role="alert"
    aria-live="polite"
    [attr.data-e2e]="e2e.vdabAiAlertHuisstijlRoot"
  >
    <div class="c-alert__content">
      <h4 [attr.data-e2e]="e2e.vdabAiAlertTitle">
        <ng-content select="[title]"></ng-content>
      </h4>
      <div class="alert-content-container">
        <div class="content" [attr.data-e2e]="e2e.vdabAiAlertContent">
          <ng-content select="[content]"></ng-content>
        </div>
        <a
          *ngIf="removable && (success || warning || danger)"
          class="icon-close"
          (click)="onClick($event)"
          [attr.data-e2e]="e2e.vdabAiAlertCloseBtn"
        ></a>
      </div>
    </div>
  </div>

</div>

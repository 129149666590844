<vdab-ai-modal [open]="showModal" (openChange)="openChange($event)">
  <h2 title class="u-mb0 wrap-anywhere">{{ tcs.getText("interest_in") }} {{selectedSuggestedProfession.title}}</h2>
  <div body>
    <vdab-ai-tag
      *ngIf="selectedSuggestedProfession.liked"
      class="u-mb15 u-display-inline-block"
      appearance="neutral"
      [light]="true"
    >
          <span class="profession-liked-tag"
          >{{ tcs.getText("interest_in_profession_tag_message") }} <span class="mdi mdi-thumb-up"></span
          ></span>
    </vdab-ai-tag>
    <span
      *ngIf="noExplanationsAvailable()"
    >
            {{ tcs.getText("no_explanations_available_yet") }}
          </span>
    <div *ngIf="(selectedSuggestedProfession.posLinkedQuestions | sortQuestionsByImpact).length > 0" class="u-mb15">
      <span class="modal-explanation-subtitle">{{ tcs.getText("what_you_like_about_this_profession") }}</span>
      <ul>
        <li *ngFor="let question of (selectedSuggestedProfession.posLinkedQuestions | sortQuestionsByImpact)">
          {{ question.key }}
        </li>
      </ul>
    </div>
    <div *ngIf="(selectedSuggestedProfession.negLinkedQuestions | sortQuestionsByImpact).length > 0">
      <span class="modal-explanation-subtitle">{{ tcs.getText("what_you_dislike_about_this_profession") }}</span>
      <ul>
        <li *ngFor="let question of (selectedSuggestedProfession.negLinkedQuestions | sortQuestionsByImpact)">
          {{ question.key }}
        </li>
      </ul>
    </div>
  </div>
</vdab-ai-modal>

import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ITextContent, TextContentService} from "../../services/text-content.service";
import {IDropdownItem} from "../../models/models";
import {
  combineLatest,
  mergeWith,
  Observable,
  ReplaySubject,
  scan,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  takeUntil
} from "rxjs";
import {map, withLatestFrom} from "rxjs/operators";
import {e2e} from '../../e2e/dataE2eValues';

@Component({
  selector: 'vdab-ai-editable-dropdown',
  templateUrl: './editable-dropdown.component.html',
  styleUrls: ['./editable-dropdown.component.scss']
})
export class EditableDropdownComponent<T> implements OnInit, OnChanges, AfterViewInit, AfterContentInit, OnDestroy {
  // Data

  /**
   * [Required] Items for dropdown with the IDropdownItem interface.
   * */
  @Input() items!: IDropdownItem<T>[];
  /**
   * [Optional] Label for the dropdown
   * */
  @Input() label: string = "";
  /**
   * [Optional] Placeholder for the dropdown
   * */
  @Input() placeholder: string = "";
  /**
   * [Required] Selected value for the dropdown.
   * An unselected choice is represented by null
   * */
  @Input() value: T | null = null;
  @Output() valueChange: EventEmitter<T | null> = new EventEmitter<T | null>();

  items$: Observable<IDropdownItem<T | null>[]>;
  addItems$: ReplaySubject<IDropdownItem<T | null>[]> = new ReplaySubject<IDropdownItem<T | null>[]>();
  removeItems$: ReplaySubject<IDropdownItem<T | null>[]> = new ReplaySubject<IDropdownItem<T | null>[]>();
  filteredItems$: Observable<IDropdownItem<T | null>[]>;

  public selectedLabel$: Observable<string>;
  public selectedLabelTrigger$: ReplaySubject<string> = new ReplaySubject<string>();
  public valueChangedTrigger$: ReplaySubject<T | null> = new ReplaySubject<T | null>()

  public onSelectChanged$: ReplaySubject<string> = new ReplaySubject<string>();
  public onInputChanged$: ReplaySubject<string> = new ReplaySubject<string>();

  public showOptions$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public selectOptionsVisibility$: Observable<string>;

  public arrowKeySelectedLabelIndex$: Observable<number>;
  public arrowKeySelectedLabel$: Observable<string>;
  public arrowKeyTrigger$: ReplaySubject<"UP" | "DOWN" | "ESCAPE"> = new ReplaySubject<"UP" | "DOWN" | "ESCAPE">();
  public arrowKeySelectLabelOnEnter$: ReplaySubject<void> = new ReplaySubject<void>();

  public afterViewInit$: Subject<void> = new Subject<void>();
  public destroy$: Subject<void> = new Subject<void>();

  @ViewChild("dropdown") dropdown!: ElementRef;
  @ViewChild("selectOptions") selectOptions!: ElementRef;
  @ViewChild("dropdownParent") dropdownParent!: ElementRef;

  // Lifecycle

  constructor(public textContentService: TextContentService, private cdr: ChangeDetectorRef) {
    this.items$ = this.createItemsHandler();
    this.filteredItems$ = this.createFilteredItemsHandler();
    this.onInputChanged$.next("");

    this.addItems$.next([])

    this.arrowKeySelectedLabelIndex$ = this.createArrowKeySelectedLabelIndexHandler();
    this.arrowKeySelectedLabel$ = this.createArrowKeySelectedLabelHandler();
    this.selectedLabel$ = this.createSelectedLabelHandler();

    this.selectOptionsVisibility$ = this.createSelectOptionsVisibility();

    // Subscriptions
    this.createOnSelectedLabelChangedSubscriptionHandler();
    this.createArrowKeySelectedLabelOnEnterSubscriptionHandler();

  }

  ngOnInit(): void {
    if (this.value === undefined) {
      throw new Error("The value is undefined. Set the value.")
    }
    this.addItems$.next(this.items)
  }

  ngAfterContentInit() {
    this.cdr.detectChanges()
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      const value: T | null = changes["value"].currentValue;
      this.valueChangedTrigger$.next(value)
    }
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next()
    this.valueChangedTrigger$.next(this.value)
    this.createDropdownFocusEventListener();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  // Interface methods

  public onSelectChanged(selectedLabel: string) {
    this.onSelectChanged$.next(selectedLabel)
    this.selectedLabelTrigger$.next(selectedLabel)
    this.showOptions$.next(false)
    this.dropdown?.nativeElement.focus();
  }

  public onInputClicked() {
    this.showOptions$.next(true)
  }

  public onInputChanged(event: KeyboardEvent) {
    if (event.key == "ArrowUp") {
      this.arrowKeyTrigger$.next("UP")
    } else if (event.key == "ArrowDown") {
      this.arrowKeyTrigger$.next("DOWN")
      this.showOptions$.next(true)
    } else if (event.key == "Enter") {
      this.arrowKeySelectLabelOnEnter$.next()
    } else if (event.key == "Escape") {
      this.showOptions$.next(false)
      this.arrowKeyTrigger$.next("ESCAPE")
    } else {
      // @ts-ignore
      const value = event.target.value;
      this.onInputChanged$.next(value)
    }
  }


  // Getters & setters

  public get selectOptionsTop(): string {
    return `${this.dropdown?.nativeElement.offsetTop + this.dropdown?.nativeElement.offsetHeight}px`;
  }

  public get selectOptionsWidth(): string {
    return `${this.dropdown?.nativeElement.offsetWidth}px`;
  }

  public get e2e() {
    return e2e;
  }

  // Helper methods

  private createItemsHandler(): Observable<IDropdownItem<T | null>[]> {
    return this.addItems$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.textContentService.textContent$),
      map(([itemsToAdd, textContent]: [IDropdownItem<T | null>[], ITextContent]) => (items: IDropdownItem<T | null>[]): IDropdownItem<T | null>[] => {
          return [...items, ...itemsToAdd];
        }
      ),
      scan((items: IDropdownItem<T | null>[], updateFn: (items: IDropdownItem<T | null>[]) => IDropdownItem<T | null>[]) => updateFn(items), []),
      startWith([]),
      shareReplay(1)
    )
  }

  private createFilteredItemsHandler(): Observable<IDropdownItem<T | null>[]> {
    return combineLatest(this.onInputChanged$, this.items$).pipe(
      map(([input, items]) => {
        if (input == "") return items;
        return this.items.filter((item => item.label.includes(input)))
      }),
      startWith([]),
      shareReplay(1)
    )
  }

  private createSelectOptionsVisibility(): Observable<string> {
    return this.showOptions$.pipe(
      map((showOptions: boolean) => showOptions ? 'visible' : 'hidden'),
      startWith('hidden'),
      shareReplay(1)
    );
  }

  private createDropdownFocusEventListener() {
    const dropdownFocusInHandler = (event: FocusEvent) => {
    };
    const dropdownFocusOutHandler = (event: FocusEvent) => {
      if (this.dropdownParent?.nativeElement.contains(event.relatedTarget)) return;

      this.showOptions$.next(false)
      return
    };
    this.dropdownParent.nativeElement.addEventListener("focusin", dropdownFocusInHandler);
    this.dropdownParent.nativeElement.addEventListener("focusout", dropdownFocusOutHandler);

    this.showOptions$.next(false)
  }

  private createArrowKeySelectedLabelIndexHandler(): Observable<number> {
    return this.arrowKeyTrigger$.pipe(
      withLatestFrom(combineLatest([this.filteredItems$, this.showOptions$])),
      map(([direction, [items, showOptions]]: [string, [IDropdownItem<T | null>[], boolean]]) => (index: number): number => {
        if (direction == "UP") {
          index = index - 1;
          if (index < 0) index = items.length - 1;
        } else if (direction == "DOWN") {
          if (showOptions) {
            index = index + 1;

          }
        } else if (direction == "ESCAPE") {
          index = 0;
        }

        return index % items.length;
      }),
      scan((index: number, updateFn) => updateFn(index), 0),
      startWith(0),
      shareReplay(1)
    );
  }

  private createArrowKeySelectedLabelHandler(): Observable<string> {
    return this.arrowKeySelectedLabelIndex$.pipe(
      withLatestFrom(this.filteredItems$),
      map(([index, items]: [number, IDropdownItem<T | null>[]]) => {
        if (items.length == 0) return "";
        return items[index].label
      }),
      shareReplay(1)
    )
  }

  private createSelectedLabelHandler(): Observable<string> {
    const valueChanged = this.valueChangedTrigger$.pipe(
      withLatestFrom(this.items$),
      map(([value, items]: [T | null, IDropdownItem<T | null>[]]) => {
        if (items.length == 0) return "";
        const item = items.find(item => item.value === value);
        if (item == null) {
          throw Error(`Item with value '${value}' is not provided with the items-property.`);
        }
        return item.label
      })
    );

    const arrowKeySelected = this.arrowKeySelectLabelOnEnter$.pipe(
      withLatestFrom(combineLatest([this.arrowKeySelectedLabel$])),
      map(([, [arrowKeySelectedLabel]]: [void, [string]]) => {
        return arrowKeySelectedLabel
      })
    )

    return this.selectedLabelTrigger$.pipe(
      map((trigger: string) => {
        return trigger
      }),
      mergeWith(valueChanged),
      mergeWith(arrowKeySelected),
      shareReplay(1)
    )
  }

  private createOnSelectedLabelChangedSubscriptionHandler(): Subscription {
    return this.selectedLabel$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.items$))
      .subscribe(([selectedLabel, items]: [string, IDropdownItem<T | null>[]]) => {
        if (items.length == 0) return;
        const item = items.find(item => item.label === selectedLabel);
        if (item == null) {
          throw Error(`Item with label ${selectedLabel} is not provided with the items-property.`)
        }
        this.valueChange.emit(item.value)
      })
  }

  private createArrowKeySelectedLabelOnEnterSubscriptionHandler(): Subscription {
    return this.arrowKeySelectLabelOnEnter$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.showOptions$.next(false))
  }

}

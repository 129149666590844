import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { combineLatest, Observable, ReplaySubject, Subject, Subscription, takeUntil } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { ISuggestedOccupationalProfile } from "../../../models/ISuggestedOccupationalProfile";
import { HttpService } from "../../../services/http.service";
import { StyleService } from "../../../services/style.service";
import { TextContentService } from "../../../services/text-content.service";
import { isNullOrUndefined } from "../../../../utils/object";

@Component({
  selector: "app-result-card",
  templateUrl: "./result-card.component.html",
  styleUrls: ["./result-card.component.scss"],
})
export class ResultCardComponent implements OnInit, OnChanges {
  // Data

  @Input() suggestedProfession!: ISuggestedOccupationalProfile;
  @Output() suggestedProfessionClicked: EventEmitter<ISuggestedOccupationalProfile> =
    new EventEmitter<ISuggestedOccupationalProfile>();
  public suggestedProfession$: ReplaySubject<ISuggestedOccupationalProfile> =
    new ReplaySubject<ISuggestedOccupationalProfile>();
  public vacancyLinkBtnTrigger$: Subject<void> = new Subject<void>();
  public professionLinkBtnTrigger$: Subject<void> = new Subject<void>();
  public shortageProfession$: Observable<boolean>;
  public fewVacanciesProfession$: Observable<boolean>;
  public likeBtnIcon$: Observable<string>;
  public normalizedProfession$: Observable<string>;
  public showExplanationModal = false;

  public professionLinkUrl$: Observable<string>;
  public vacancyLinkUrl$: Observable<string>;
  public courseLinkUrl$: Observable<string>;

  public destroy$: Subject<void> = new Subject<void>();

  // Lifecycle

  constructor(public tcs: TextContentService, public httpService: HttpService, public styleService: StyleService) {
    // Observables

    this.likeBtnIcon$ = this.likeBtnHandler();
    this.normalizedProfession$ = this.normalizedProfessionHandler();
    this.shortageProfession$ = this.shortageProfessionHandler();
    this.fewVacanciesProfession$ = this.fewVacanciesProfessionHandler();
    const suggestedProfessionTuple$ = this.suggestedProfession$.pipe(
      takeUntil(this.destroy$),
      map((sp: ISuggestedOccupationalProfile) => {
        const result: [string, string] = [sp.id, sp.title];
        return result;
      })
    );
    this.professionLinkUrl$ = this.httpService.createProfessionLinkUrlHandler(suggestedProfessionTuple$);
    this.vacancyLinkUrl$ = this.httpService.createVacancyLinkUrlHandler(suggestedProfessionTuple$);
    this.courseLinkUrl$ = this.httpService.createCourseUrlHandler(suggestedProfessionTuple$);

    // Subscriptions

    this.registerProfessionLinkBtnTriggerHandler();
    this.registerVacancyLinkBtnTriggerHandler();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["suggestedProfession"]) {
      this.suggestedProfession$.next(changes["suggestedProfession"].currentValue);
    }
  }

  ngOnInit(): void {
    this.suggestedProfession$.next(this.suggestedProfession);
  }

  // Interface methods

  public professionLinkClicked(event: MouseEvent): void {
    if (event.button === 0 || event.button === 1) this.professionLinkBtnTrigger$.next();
  }

  public vacancyLinkClicked(event: MouseEvent): void {
    if (event.button === 0 || event.button === 1) this.vacancyLinkBtnTrigger$.next();
  }

  public openExplanationModal(suggestedProfession: ISuggestedOccupationalProfile) {
    this.showExplanationModal = true;
    this.suggestedProfession$.next(suggestedProfession);
    this.suggestedProfessionClicked.emit(suggestedProfession);
  }

  public calculateColor(pc: number): string {
    let color;
    if (pc <= 0.65) {
      color = this.styleService.styles.vdab_turqoise_900;
    } else if (pc <= 0.75) {
      color = this.styleService.styles.vdab_turqoise_700;
    } else {
      color = this.styleService.styles.vdab_turqoise_500;
    }
    return color;
  }

  public suggestedProfessionHasConfidenceScore(suggestedProfession: ISuggestedOccupationalProfile): boolean {
    return !isNullOrUndefined(suggestedProfession.confidenceScore);
  }

  // Getters & setters

  // Helper methods

  private likeBtnHandler(): Observable<string> {
    return this.suggestedProfession$.pipe(
      takeUntil(this.destroy$),
      map((sp: ISuggestedOccupationalProfile) =>
        sp.liked ? "mdi-thumb-up vdab-text-color-green" : "mdi-thumb-up-outline"
      )
    );
  }

  private normalizedProfessionHandler(): Observable<string> {
    return this.suggestedProfession$.pipe(
      takeUntil(this.destroy$),
      map((suggestedProfession: ISuggestedOccupationalProfile) => {
        return `${suggestedProfession.title.replace(" (m/v)", "").toLowerCase().replace(/\s+/g, "-")}`;
      })
    );
  }

  private registerProfessionLinkBtnTriggerHandler(): Subscription {
    return this.professionLinkBtnTrigger$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(combineLatest([this.suggestedProfession$, this.normalizedProfession$])),
        map(
          ([, [suggestedProfession, normalizedProfession]]: [void, [ISuggestedOccupationalProfile, string]]): [
            string,
            ISuggestedOccupationalProfile
          ] => {
            const professionUrl = `${environment.PROFESSION_URL}${suggestedProfession.id}/${normalizedProfession}?utm_source=orientatietest&utm_medium=referral&utm_campaign=orient_bekijk_beroep`;
            return [professionUrl, suggestedProfession];
          }
        )
      )
      .subscribe(([professionUrl]: [string, ISuggestedOccupationalProfile]) => {
        window.open(professionUrl);
      });
  }

  private registerVacancyLinkBtnTriggerHandler(): Subscription {
    return this.vacancyLinkBtnTrigger$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(combineLatest([this.suggestedProfession$, this.normalizedProfession$])),
        map(
          ([, [suggestedProfession, normalizedProfession]]: [void, [ISuggestedOccupationalProfile, string]]): [
            string,
            ISuggestedOccupationalProfile
          ] => {
            const vacancyUrl = `${environment.VACANCIES_URL}${normalizedProfession}&utm_source=orientatietest&utm_medium=referral&utm_campaign=orient_bekijk_vacatures`;
            return [vacancyUrl, suggestedProfession];
          }
        )
      )
      .subscribe(([professionUrl]: [string, ISuggestedOccupationalProfile]) => {
        window.open(professionUrl);
      });
  }

  private shortageProfessionHandler(): Observable<boolean> {
    return this.suggestedProfession$.pipe(
      takeUntil(this.destroy$),
      map((suggestedProfession: ISuggestedOccupationalProfile) => suggestedProfession.knelpuntBeroep)
    );
  }

  private fewVacanciesProfessionHandler(): Observable<boolean> {
    return this.suggestedProfession$.pipe(
      takeUntil(this.destroy$),
      map(
        (suggestedProfession: ISuggestedOccupationalProfile) =>
          !suggestedProfession.knelpuntBeroep && suggestedProfession.weinigVacatures
      )
    );
  }
}

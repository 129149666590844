import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, UrlSegment } from "@angular/router";
import { SnackbarComponent } from "angular-frontend-components";
import {
  combineLatest,
  mergeWith,
  Observable,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
} from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { capitalize } from "../../../../utils/text";
import { WithStatus } from "../../../../utils/with-status";
import { Loading, LoadingEnum } from "../../../models/Enums";
import { ISession } from "../../../models/ISession";
import { toApi } from "../../../models/mapper";
import { HttpService } from "../../../services/http.service";
import { TextContentService } from "../../../services/text-content.service";
import { ResultFacade } from "../../../state/Result/Result.facade";

@Component({
  selector: "app-save-results-modal",
  templateUrl: "./save-results-modal.component.html",
  styleUrls: ["./save-results-modal.component.scss"],
})
export class SaveResultsModalComponent implements OnInit, OnChanges, OnDestroy {
  // Data

  public userEmail = "";
  public attendantEmail = "";
  public canSendPdfMail = false;

  public open$: Subject<boolean> = new Subject<boolean>();
  @Input() open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public sessionWithCutOffOccupationalProfiles$: Observable<ISession>;

  // Save on vdab.be
  public saveOnVdabTrigger$: Subject<void> = new Subject<void>();
  public saveOnVdabStatus$: Observable<WithStatus<any>>;
  public saveOnVdabStatusSnackbarType$: Observable<string>;
  public saveOnVdabButtonDisabled$: Observable<boolean>;

  // Form elements

  public saveResultsModalTitle$: Observable<string>;

  public showButtonsWidget$: Subject<boolean> = new Subject<boolean>();
  public showMailWidget$: Subject<boolean> = new Subject<boolean>();

  // Download pdf
  public downloadPdfTrigger$: Subject<void> = new Subject<void>();
  public downloadPdfStatus$: Observable<WithStatus<any>>;
  @ViewChild("pdf", { read: ElementRef }) pdf!: ElementRef;

  public destroy$: Subject<void> = new Subject<void>();

  @ViewChild("sendMailPdfLoadingSnackbar") sendMailPdfLoadingSnackbar!: SnackbarComponent;
  @ViewChild("sendMailPdfErrorSnackbar") sendMailPdfErrorSnackbar!: SnackbarComponent;
  @ViewChild("sendMailPdfDoneSnackbar") sendMailPdfDoneSnackbar!: SnackbarComponent;
  @ViewChild("saveOnVdabLoadingSnackbar") saveOnVdabLoadingSnackbar!: SnackbarComponent;
  @ViewChild("saveOnVdabErrorSnackbar") saveOnVdabErrorSnackbar!: SnackbarComponent;
  @ViewChild("saveOnVdabDoneSnackbar") saveOnVdabDoneSnackbar!: SnackbarComponent;
  @ViewChild("downloadPdfErrorSnackbar") downloadPdfErrorSnackbar!: SnackbarComponent;


  // ===================================================================================================================
  // Lifecycle

  constructor(
    public tcs: TextContentService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public resultFacade: ResultFacade,
    private httpService: HttpService
  ) {
    this.saveResultsModalTitle$ = this.createSaveResultsModalTitleHandler();
    this.sessionWithCutOffOccupationalProfiles$ = this.sessionWithCutOffOccupationalProfilesHandler();

    // =================================================================================================================
    // Save on vdab.be

    this.saveOnVdabButtonDisabled$ = this.activatedRoute.url.pipe(
      takeUntil(this.destroy$),
      map((urlSegments: UrlSegment[]) => {
        return urlSegments[0].path === "bekijk-resultaten";
      }),
      startWith(false),
      shareReplay(1)
    );
    this.saveOnVdabStatus$ = this.createSaveOnVdabHandler();
    this.saveOnVdabStatusSnackbarType$ = this.createSaveOnVdabStatusSnackbarTypeHandler();
    this.createSaveOnVdabSubscriptionHandler();

    // =================================================================================================================
    // Download pdf

    this.downloadPdfStatus$ = this.createDownloadPdfHandler();
    this.createDownloadPdfSubscriptionHandler();

    // General init
    this.open$.next(false);
  }

  ngOnInit(): void {
    this.open$.next(this.open);
    this.showButtonsWidget$.next(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["open"]) {
      const open = changes["open"].currentValue;
      this.open$.next(open);
      if (!open) {
        this.resetWidgetModals();
        this.showButtonsWidget$.next(true);
      }
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public onCanSendPdfMailChanged(canSendPdfMail: boolean) {
    this.canSendPdfMail = canSendPdfMail;
  }

  public onUserEmailChanged(userEmail: string) {
    this.userEmail = userEmail;
  }

  public onAttendantEmailChanged(attendantEmail: string) {
    this.attendantEmail = attendantEmail;
  }

  // ===================================================================================================================
  // Interface methods

  public openModal() {
    this.openChange.emit(true);
  }

  public closeModal() {
    this.resetWidgetModals();
    this.showButtonsWidget$.next(true);
    this.openChange.emit(false);
  }

  public showMailWidgetModal() {
    this.openModal();
    this.resetWidgetModals();
    this.showMailWidget$.next(true);
  }

  public saveOnVdab(event: Event) {
    event.preventDefault();
    this.saveOnVdabTrigger$.next();
  }

  public downloadPdf(event: Event) {
    event.preventDefault();
    this.downloadPdfTrigger$.next();
  }

  public onMailSent(mailSentStatus: Loading) {
    this.resetSendMailPdfSnackbars();
    if (mailSentStatus === LoadingEnum.Success) {
      this.sendMailPdfDoneSnackbar?.show();
      this.closeModal();
    } else if (mailSentStatus === LoadingEnum.Error) {
      this.sendMailPdfErrorSnackbar?.show();
    } else {
      this.sendMailPdfLoadingSnackbar?.show();
    }
  }

  // ===================================================================================================================
  // Getters & setters

  // ===================================================================================================================
  // Helper methods

  private resetSaveOnVdabSnackbars() {
    this.saveOnVdabLoadingSnackbar?.hide();
    this.saveOnVdabErrorSnackbar?.hide();
    this.saveOnVdabDoneSnackbar?.hide();
  }

  private resetDownloadPdfSnackbars() {
    this.downloadPdfErrorSnackbar?.hide();
  }

  private createSaveOnVdabSubscriptionHandler(): Subscription {
    return this.saveOnVdabStatus$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.resultFacade.session$))
      .subscribe(([status, session]: [WithStatus<any>, ISession]) => {
        // Hide snackbars
        this.resetSaveOnVdabSnackbars();

        // Show correct snackbar
        if (status.status === "loading") {
          this.saveOnVdabLoadingSnackbar.show();
        }
        if (status.status === "error") {
          this.saveOnVdabErrorSnackbar.show();
        }
        if (status.status === "done") {
          this.saveOnVdabDoneSnackbar.show();
          // Open VDAB orientation page
          window.open(
            environment.ORIENTATION_PAGE_URL +
              String(session.sessionId) +
              "&utm_source=orientatietest&utm_medium=referral&utm_campaign=orient_resultaten_bewaren",
            "_blank"
          );
          this.closeModal();
        }
      });
  }

  private createSaveOnVdabStatusSnackbarTypeHandler(): Observable<string> {
    return this.saveOnVdabStatus$.pipe(
      takeUntil(this.destroy$),
      map((status: WithStatus<any>) => {
        switch (status.status) {
          case "error":
            return `-warning`;
          default:
            return "-neutral";
        }
      })
    );
  }

  private resetWidgetModals() {
    this.showButtonsWidget$.next(false);
    this.showMailWidget$.next(false);
  }

  private createDownloadPdfSubscriptionHandler(): Subscription {
    return this.downloadPdfStatus$.subscribe((pdf: WithStatus<any>) => {
      this.resetDownloadPdfSnackbars();
      if (pdf.status === "error") {
        this.downloadPdfErrorSnackbar.show();
        this.closeModal();
      } else if (pdf.status === "done") {
        const blob = new Blob([pdf.value], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);

        const link = document.createElement("a");
        link.href = url;
        link.download = "orient-resultaten.pdf";
        link.click();
        this.closeModal();
      }
    });
  }

  private createSaveResultsModalTitleHandler(): Observable<string> {
    const showButtonsWidgetChanged = this.showButtonsWidget$.pipe(
      takeUntil(this.destroy$),
      map((showButtonsWidget: boolean) => this.tcs.getText(showButtonsWidget ? "save_results" : "send_results"))
    );
    const showMailWidgetChanged = this.showMailWidget$.pipe(
      takeUntil(this.destroy$),
      map((showMailWidget: boolean) => this.tcs.getText(showMailWidget ? "send_results" : "save_results"))
    );
    return showButtonsWidgetChanged.pipe(
      takeUntil(this.destroy$),
      mergeWith(showMailWidgetChanged),
      map((title: string) => capitalize(title, true)),
      shareReplay(1)
    );
  }

  private createDownloadPdfHandler(): Observable<WithStatus<Blob>> {
    return this.downloadPdfTrigger$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(
        combineLatest([
          this.sessionWithCutOffOccupationalProfiles$,
          this.resultFacade.filteredSuggestedOccupationalProfiles$,
        ])
      ),
      switchMap(([, [session, filteredSuggestedOccupationalProfiles]]) => {
        session.suggestedOccupationalProfiles = filteredSuggestedOccupationalProfiles;
        return this.httpService.downloadPdf(toApi.Session(session));
      }),
      shareReplay(1)
    );
  }

  // todo-ale: Refactor any response}
  private createSaveOnVdabHandler(): Observable<WithStatus<any>> {
    return this.saveOnVdabTrigger$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(
        combineLatest([
          this.sessionWithCutOffOccupationalProfiles$,
          this.resultFacade.filteredSuggestedOccupationalProfiles$,
        ])
      ),
      switchMap(([, [session, filteredSuggestedOccupationalProfiles]]) => {
        session.suggestedOccupationalProfiles = filteredSuggestedOccupationalProfiles;
        return this.httpService.saveOnVdab(toApi.Session(session));
      }),
      shareReplay(1)
    );
  }

  private sessionWithCutOffOccupationalProfilesHandler(): Observable<ISession> {
    return this.resultFacade.session$.pipe(
      map((session) => {
        return {
          ...session,
          suggestedOccupationalProfiles: session.suggestedOccupationalProfiles.slice(
            0,
            environment.AMT_OF_RESULTS_TO_HANDLE
          ),
        };
      })
    );
  }

  private resetSendMailPdfSnackbars() {
    this.sendMailPdfLoadingSnackbar?.hide();
    this.sendMailPdfErrorSnackbar?.hide();
    this.sendMailPdfDoneSnackbar?.hide();
  }
}

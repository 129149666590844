    <div
      class="checkbox-container u-pt05"
      [attr.data-e2e]="e2e.vdabAiCheckboxRoot"
    >
      <input
        #checkbox
        type="checkbox"
        (change)="emitValueChanged()"
        [(ngModel)]="value"
        [id]="id"
        [attr.data-e2e]="e2e.vdabAiCheckboxInputElement"
      >
      <label
        class="checkbox u-pb0"
        [for]="id"
      >
        <span
          class="checkbox-label"
          [attr.data-e2e]="e2e.vdabAiCheckboxLabelElement"
        >{{ label }}</span>
        <span
          class="u-ml05 u-text-meta"
          [attr.data-e2e]="e2e.vdabAiCheckboxMetaLabelElement"
        >{{metaLabel}}</span>
      </label>
    </div>

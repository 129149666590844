<nav class="vdab-toolbar-container">
  <div class="vdab-toolbar-logo-container">
    <a [href]="environment.APP_LOGO_URL">
      <img class="vdab-toolbar-logo u-pr1" [src]="appLogoImgUrl" />
    </a>
    <span class="vdab-toolbar-chevron action-go vdab-text-color-white u-p0 u-hide@screen"></span>
    <a href="/" class="vdab-toolbar-app-name">{{ tcs.getText("app_name") }}</a>
  </div>
  <div class="spacer"></div>
  <div class="vdab-toolbar-end-container">
    <a [href]="environment.PRIVACY_URL" target="_blank" class="vdab-toolbar-privacy vdab-text-color-white u-m0">
      {{ tcs.getText("privacy") }}
    </a>
  </div>
</nav>

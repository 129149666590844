import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  init() {
    const scriptTag = document.createElement('script')
    scriptTag.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.RECAPTCHA_SITE_KEY}`
    document.head.appendChild(scriptTag)
  }
}

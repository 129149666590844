<div
  *ngIf="open"
  class="hs-overlay"
  (click)="closeModalForOverlay($event)"
  [attr.data-e2e]="e2e.vdabAiModalRoot"
>
  <div
    class="c-modal training-info-modal"
    [class.-full-screen]="fullScreen"
    [attr.data-e2e]="e2e.vdabAiModalHuisstijlRoot"
  >
    <div
      class="c-modal__header"
      [attr.data-e2e]="e2e.vdabAiModalHeader"
    >
      <div
        class="c-modal__title"
        [attr.data-e2e]="e2e.vdabAiModalHeaderTitleContainer"
      >
        <ng-content select="[title]"></ng-content>
      </div>
      <a rel="button"
         (click)="toggleOpen()"
         class="action-lone action-cancel c-modal__close"
         aria-label="Sluiten"
         [attr.data-e2e]="e2e.vdabAiModalHeaderCloseButton"
      >
      </a>
    </div>
    <div
      class="c-modal__body"
      [style.background-color]="backgroundColor"
      [attr.data-e2e]="e2e.vdabAiModalBodyContainer"
    >
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</div>

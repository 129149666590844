/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DynamoDbSavedSession } from '../models/dynamo-db-saved-session';
import { getSessionApiOrientGetSessionSessionSessionIdGet } from '../fn/orient/get-session-api-orient-get-session-session-session-id-get';
import { GetSessionApiOrientGetSessionSessionSessionIdGet$Params } from '../fn/orient/get-session-api-orient-get-session-session-session-id-get';
import { NextQuestion } from '../models/next-question';
import { saveSessionApiOrientSaveSessionSaveSessionPut } from '../fn/orient/save-session-api-orient-save-session-save-session-put';
import { SaveSessionApiOrientSaveSessionSaveSessionPut$Params } from '../fn/orient/save-session-api-orient-save-session-save-session-put';
import { sendMailApiOrientMailMailPost } from '../fn/orient/send-mail-api-orient-mail-mail-post';
import { SendMailApiOrientMailMailPost$Params } from '../fn/orient/send-mail-api-orient-mail-mail-post';
import { sendPdfApiOrientPdfPdfPost$Json } from '../fn/orient/send-pdf-api-orient-pdf-pdf-post-json';
import { SendPdfApiOrientPdfPdfPost$Json$Params } from '../fn/orient/send-pdf-api-orient-pdf-pdf-post-json';
import { sendPdfApiOrientPdfPdfPost$Pdf } from '../fn/orient/send-pdf-api-orient-pdf-pdf-post-pdf';
import { SendPdfApiOrientPdfPdfPost$Pdf$Params } from '../fn/orient/send-pdf-api-orient-pdf-pdf-post-pdf';
import { updateAnswersApiOrientUpdateAnswersUpdateAnswersPost } from '../fn/orient/update-answers-api-orient-update-answers-update-answers-post';
import { UpdateAnswersApiOrientUpdateAnswersUpdateAnswersPost$Params } from '../fn/orient/update-answers-api-orient-update-answers-update-answers-post';

@Injectable({ providedIn: 'root' })
export class OrientService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAnswersApiOrientUpdateAnswersUpdateAnswersPost()` */
  static readonly UpdateAnswersApiOrientUpdateAnswersUpdateAnswersPostPath = '/api/orient/update-answers/update_answers';

  /**
   * Update Answers.
   *
   * Return followup question and suggestions based on answered questions and rated occupational profiles.
   *
   * post
   *     request body:
   *         Dictionary with a session id, rated questions and occupational profiles.
   *     response:
   *         200: Dictionary with the next question.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnswersApiOrientUpdateAnswersUpdateAnswersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnswersApiOrientUpdateAnswersUpdateAnswersPost$Response(params: UpdateAnswersApiOrientUpdateAnswersUpdateAnswersPost$Params, context?: HttpContext): Observable<StrictHttpResponse<NextQuestion>> {
    return updateAnswersApiOrientUpdateAnswersUpdateAnswersPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Answers.
   *
   * Return followup question and suggestions based on answered questions and rated occupational profiles.
   *
   * post
   *     request body:
   *         Dictionary with a session id, rated questions and occupational profiles.
   *     response:
   *         200: Dictionary with the next question.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnswersApiOrientUpdateAnswersUpdateAnswersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnswersApiOrientUpdateAnswersUpdateAnswersPost(params: UpdateAnswersApiOrientUpdateAnswersUpdateAnswersPost$Params, context?: HttpContext): Observable<NextQuestion> {
    return this.updateAnswersApiOrientUpdateAnswersUpdateAnswersPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<NextQuestion>): NextQuestion => r.body)
    );
  }

  /** Path part for operation `getSessionApiOrientGetSessionSessionSessionIdGet()` */
  static readonly GetSessionApiOrientGetSessionSessionSessionIdGetPath = '/api/orient/get-session/session/{session_id}';

  /**
   * Get Session.
   *
   * Get session information if a user has saved their session on aws.
   *
   * If the session is from an older competent release, convert the id and data to the current release
   * using the Comeet API.
   *
   * :param session_id: unique identifier for the session.
   * :param competent_release_settings: setting object containing competent release settings.
   * :param comeet_settings: setting object containing Comeet settings.
   * :param occupational_profile_ids: List of the occupational profile IDs in Orient
   * :param occupational_profiles: Dataframe with the occupational profile data, including occupations outside of Orient
   * :param bottle_neck_professions: list of occupational profile ids tagged with the knelpuntberoep label.
   * :param few_vacancies: list of occupants profile ids tagged with the 'weinig vacatures' label.
   * :param model_settings: The Orient model settings
   * :param aws_settings: AWS settings object
   * :return: a dictionary containing all the information needed for the frontend.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSessionApiOrientGetSessionSessionSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessionApiOrientGetSessionSessionSessionIdGet$Response(params: GetSessionApiOrientGetSessionSessionSessionIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<DynamoDbSavedSession>> {
    return getSessionApiOrientGetSessionSessionSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Session.
   *
   * Get session information if a user has saved their session on aws.
   *
   * If the session is from an older competent release, convert the id and data to the current release
   * using the Comeet API.
   *
   * :param session_id: unique identifier for the session.
   * :param competent_release_settings: setting object containing competent release settings.
   * :param comeet_settings: setting object containing Comeet settings.
   * :param occupational_profile_ids: List of the occupational profile IDs in Orient
   * :param occupational_profiles: Dataframe with the occupational profile data, including occupations outside of Orient
   * :param bottle_neck_professions: list of occupational profile ids tagged with the knelpuntberoep label.
   * :param few_vacancies: list of occupants profile ids tagged with the 'weinig vacatures' label.
   * :param model_settings: The Orient model settings
   * :param aws_settings: AWS settings object
   * :return: a dictionary containing all the information needed for the frontend.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSessionApiOrientGetSessionSessionSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessionApiOrientGetSessionSessionSessionIdGet(params: GetSessionApiOrientGetSessionSessionSessionIdGet$Params, context?: HttpContext): Observable<DynamoDbSavedSession> {
    return this.getSessionApiOrientGetSessionSessionSessionIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<DynamoDbSavedSession>): DynamoDbSavedSession => r.body)
    );
  }

  /** Path part for operation `saveSessionApiOrientSaveSessionSaveSessionPut()` */
  static readonly SaveSessionApiOrientSaveSessionSaveSessionPutPath = '/api/orient/save-session/save_session';

  /**
   * Save Session.
   *
   * Save the session in a dynamoDB table on AWS
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveSessionApiOrientSaveSessionSaveSessionPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSessionApiOrientSaveSessionSaveSessionPut$Response(params: SaveSessionApiOrientSaveSessionSaveSessionPut$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return saveSessionApiOrientSaveSessionSaveSessionPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Save Session.
   *
   * Save the session in a dynamoDB table on AWS
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveSessionApiOrientSaveSessionSaveSessionPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSessionApiOrientSaveSessionSaveSessionPut(params: SaveSessionApiOrientSaveSessionSaveSessionPut$Params, context?: HttpContext): Observable<any> {
    return this.saveSessionApiOrientSaveSessionSaveSessionPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `sendPdfApiOrientPdfPdfPost()` */
  static readonly SendPdfApiOrientPdfPdfPostPath = '/api/orient/pdf/pdf';

  /**
   * Send Pdf.
   *
   * The Endpoint for transferring PDF to front end upon user request.
   * This will be triggered when user clicks on the download PDF button in the front end.
   * The frontend makes a POST request to the backend bringing the Answers dict.
   * This dict will be used in the suggestions_to_pdf to create a pdf_path.
   *
   * :param session: Session object from data_models.
   * :param background_tasks: BackgroundTasks is an API class where according to FastAPI documentation.
   * :param questions: Pandas dataframe of the questions used in orient and their information.
   * :param pdf_settings: Settings object containing information about the pdf.
   * :param occupational_profiles: Pandas dataframe of all the occupational profiles and their information.
   * "You can define background tasks to be run after returning a response."
   * :return: encoded pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendPdfApiOrientPdfPdfPost$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendPdfApiOrientPdfPdfPost$Json$Response(params: SendPdfApiOrientPdfPdfPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return sendPdfApiOrientPdfPdfPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Pdf.
   *
   * The Endpoint for transferring PDF to front end upon user request.
   * This will be triggered when user clicks on the download PDF button in the front end.
   * The frontend makes a POST request to the backend bringing the Answers dict.
   * This dict will be used in the suggestions_to_pdf to create a pdf_path.
   *
   * :param session: Session object from data_models.
   * :param background_tasks: BackgroundTasks is an API class where according to FastAPI documentation.
   * :param questions: Pandas dataframe of the questions used in orient and their information.
   * :param pdf_settings: Settings object containing information about the pdf.
   * :param occupational_profiles: Pandas dataframe of all the occupational profiles and their information.
   * "You can define background tasks to be run after returning a response."
   * :return: encoded pdf file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendPdfApiOrientPdfPdfPost$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendPdfApiOrientPdfPdfPost$Json(params: SendPdfApiOrientPdfPdfPost$Json$Params, context?: HttpContext): Observable<any> {
    return this.sendPdfApiOrientPdfPdfPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Send Pdf.
   *
   * The Endpoint for transferring PDF to front end upon user request.
   * This will be triggered when user clicks on the download PDF button in the front end.
   * The frontend makes a POST request to the backend bringing the Answers dict.
   * This dict will be used in the suggestions_to_pdf to create a pdf_path.
   *
   * :param session: Session object from data_models.
   * :param background_tasks: BackgroundTasks is an API class where according to FastAPI documentation.
   * :param questions: Pandas dataframe of the questions used in orient and their information.
   * :param pdf_settings: Settings object containing information about the pdf.
   * :param occupational_profiles: Pandas dataframe of all the occupational profiles and their information.
   * "You can define background tasks to be run after returning a response."
   * :return: encoded pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendPdfApiOrientPdfPdfPost$Pdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendPdfApiOrientPdfPdfPost$Pdf$Response(params: SendPdfApiOrientPdfPdfPost$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return sendPdfApiOrientPdfPdfPost$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Pdf.
   *
   * The Endpoint for transferring PDF to front end upon user request.
   * This will be triggered when user clicks on the download PDF button in the front end.
   * The frontend makes a POST request to the backend bringing the Answers dict.
   * This dict will be used in the suggestions_to_pdf to create a pdf_path.
   *
   * :param session: Session object from data_models.
   * :param background_tasks: BackgroundTasks is an API class where according to FastAPI documentation.
   * :param questions: Pandas dataframe of the questions used in orient and their information.
   * :param pdf_settings: Settings object containing information about the pdf.
   * :param occupational_profiles: Pandas dataframe of all the occupational profiles and their information.
   * "You can define background tasks to be run after returning a response."
   * :return: encoded pdf file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendPdfApiOrientPdfPdfPost$Pdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendPdfApiOrientPdfPdfPost$Pdf(params: SendPdfApiOrientPdfPdfPost$Pdf$Params, context?: HttpContext): Observable<any> {
    return this.sendPdfApiOrientPdfPdfPost$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `sendMailApiOrientMailMailPost()` */
  static readonly SendMailApiOrientMailMailPostPath = '/api/orient/mail/mail';

  /**
   * Send Mail.
   *
   * Send mail with results to the specified email address.
   * this makes use of the pdf generation.
   *
   * :param session: Session object from data_models.
   * :param mail_addresses: List of email addresses to send the mails to.
   * :param background_tasks: BackgroundTasks is an API class where according to FastAPI documentation,
   * "You can define background tasks to be run after returning a response."
   * :param questions: List of questions used in orient.
   * :param pdf_settings: PDFSettings object containing information about pdf settings.
   * :param occupational_profiles: Pandas DataFrame containing information about all the occupational profiles.
   * :param email_settings: EmailSettings object tontaining information about email settings.
   * :param mail_cc: optional parameter to incloud an email address in cc
   * :return: http response code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMailApiOrientMailMailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMailApiOrientMailMailPost$Response(params: SendMailApiOrientMailMailPost$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return sendMailApiOrientMailMailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Mail.
   *
   * Send mail with results to the specified email address.
   * this makes use of the pdf generation.
   *
   * :param session: Session object from data_models.
   * :param mail_addresses: List of email addresses to send the mails to.
   * :param background_tasks: BackgroundTasks is an API class where according to FastAPI documentation,
   * "You can define background tasks to be run after returning a response."
   * :param questions: List of questions used in orient.
   * :param pdf_settings: PDFSettings object containing information about pdf settings.
   * :param occupational_profiles: Pandas DataFrame containing information about all the occupational profiles.
   * :param email_settings: EmailSettings object tontaining information about email settings.
   * :param mail_cc: optional parameter to incloud an email address in cc
   * :return: http response code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMailApiOrientMailMailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMailApiOrientMailMailPost(params: SendMailApiOrientMailMailPost$Params, context?: HttpContext): Observable<string> {
    return this.sendMailApiOrientMailMailPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}

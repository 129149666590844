import {Component, Input} from "@angular/core";

@Component({
  selector: "vdab-ai-base-button",
  styleUrls: ["./base-button.component.scss"],
  template: ''
})
/**
 * Button component has 1 content slot:
 * 1. text: Represents the content of the button
 *
 * See example in 'examples/button-example.component.html'
 * */
export class BaseButtonComponent {
  // Data

  /**
   * Button appearance
   */
  @Input() appearance: "primary" | "secundary" | "tertiary" | "ghost" | "light" | "warning" | "danger" = "primary";
  /**
   * Determines whether button is disabled
   */
  @Input() disabled: boolean = false;

  // Lifecycle

  // Interface methods

  // Getters & setters

  public get isDefault() {
    return !this.disabled && (
      this.appearance === "primary" || !(this.appearance === "secundary" || this.appearance === "tertiary" || this.appearance === "ghost" || this.appearance === "light" || this.appearance === "warning" || this.appearance === "danger")
    );
  }

  public get primary() {
    return !this.disabled && this.appearance === "primary"
  }

  public get secundary() {
    return !this.disabled && this.appearance === "secundary"
  }

  public get tertiary() {
    return !this.disabled && this.appearance === "tertiary"
  }

  public get ghost() {
    return !this.disabled && this.appearance === "ghost"
  }

  public get light() {
    return !this.disabled && this.appearance === "light"
  }

  public get warning() {
    return !this.disabled && this.appearance === "warning"
  }

  public get danger() {
    return !this.disabled && this.appearance === "danger"
  }

  // Helper methods
}

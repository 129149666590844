import { QuestionTypesEnum } from "./Enums";
import { ISuggestedOccupationalProfile } from "./ISuggestedOccupationalProfile";

export interface IQuestionInfo {
  type: QuestionTypesEnum;
}

export interface IInterestQuestionInfo extends IQuestionInfo {
  questionId: string;
  questionString: string;
  questionDescription: string;
}

export interface IProfessionQuestionInfo extends IQuestionInfo {
  occupationalProfileId: string;
  occupationalProfileTitle: string;
  occupationalProfileDescription: string;
}

export interface IEndQuestionInfo extends IQuestionInfo {
  suggestedOccupationalProfiles: ISuggestedOccupationalProfile[];
}

export interface INextQuestion {
  questionInfo: IInterestQuestionInfo | IProfessionQuestionInfo | IEndQuestionInfo;
  informationGainProgress: number;
}

export interface IInterestQuestion extends INextQuestion {
  questionInfo: IInterestQuestionInfo;
  // informationGainProgress: number;
}

export interface IProfessionQuestion extends INextQuestion {
  questionInfo: IProfessionQuestionInfo;
}

export interface IEndQuestion extends INextQuestion {
  questionInfo: IEndQuestionInfo;
  // suggestedOccupationalProfiles: ISuggestedOccupationalProfile[];
}

export function isInterestQuestion(question: INextQuestion | null | undefined): question is IInterestQuestion {
  return question?.questionInfo.type === QuestionTypesEnum.InterestQuestion;
}

export function isProfessionQuestion(question: INextQuestion | null | undefined): question is IProfessionQuestion {
  return question?.questionInfo.type === QuestionTypesEnum.ProfessionQuestion;
}

export function isEndQuestion(question: INextQuestion | null | undefined): question is IEndQuestion {
  return question?.questionInfo.type === QuestionTypesEnum.End;
}

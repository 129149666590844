import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {$color_blue_300, $color_blue_primary, $color_grey_300, $color_grey_600} from "../../styles/styles";
import {e2e} from "../../e2e/dataE2eValues";

@Component({
  selector: "vdab-ai-range-slider",
  templateUrl: "./range-slider.component.html",
  styleUrls: ["./range-slider.component.scss"],
})
/**
 * Range range-slider component:
 *
 * See example in 'examples/range-range-slider-example.component.html'
 * */
export class RangeSliderComponent implements OnInit, AfterViewInit, OnChanges {
  // Data

  /**
   * [Required] Lower value of the range range-slider. Must be smaller or equal than the upper value
   * */
  @Input() minValue!: number;
  @Output() minValueChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * [Required] Upper value of the range range-slider. Must be greater or equal than the lower value
   * */
  @Input() maxValue!: number;
  @Output() maxValueChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * [Required] Lower bound for the lower value the range range-slider. Must be greater or equal than the upper bound value
   * */
  @Input() minLimit!: number;

  /**
   * [Required] Upper bound for the upper value the range range-slider. Must be smaller or equal than the upper bound value
   * */
  @Input() maxLimit!: number;

  /**
   * Whether the range-slider is disabled
   * */
  @Input() disabled!: boolean;

  /**
   * Data e2e attribute
   * */
  @Input() dataE2e!: string;

  /**
   * Overlap allowed for lower and upper values
   * */
  private minGap = 0;
  public minValueInputTick: number = this.minValue;
  public maxValueInputTick: number = this.maxValue;
  @ViewChild("sliderTrack") sliderTrack!: ElementRef;
  @ViewChild("minValueInputTickRef") minValueInputTickRef!: ElementRef;
  @ViewChild("maxValueInputTickRef") maxValueInputTickRef!: ElementRef;

  // Lifecycle
  constructor() {
  }

  ngOnInit(): void {
    this.minValueInputTick = this.minValue;
    this.maxValueInputTick = this.maxValue;
  }

  ngAfterViewInit(): void {
    this.redrawSlider();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["minValue"]) {
      this.minValue = changes["minValue"].currentValue;
      this.minValueInputTick = this.minValue;
      this.redrawSlider();
    }

    if (changes["maxValue"]) {
      this.maxValue = changes["maxValue"].currentValue;
      this.maxValueInputTick = this.maxValue;
      this.redrawSlider();
    }
  }

  // Interface methods

  public minValueAsInputValueChangeHandler(minValue: number) {
    const maxValue = parseInt(this.maxValueInputTickRef.nativeElement.value);
    this.minValueChange.emit(minValue);
  }

  public minValueAsInputChangedHandler(minValue: number) {
    const maxValue = parseInt(this.maxValueInputTickRef.nativeElement.value);
    minValue = Number(minValue);
    if (!this.checkValidValueChange(minValue, maxValue)) {
      minValue = maxValue - this.minGap;
    }

    this.minValueChange.emit(minValue);
    this.redrawSlider();
  }

  public maxValueAsInputValueChangeHandler(maxValue: number) {
    const minValue = parseInt(this.minValueInputTickRef.nativeElement.value);
    this.maxValueChange.emit(maxValue);
  }

  public maxValueAsInputChangedHandler(maxValue: number) {
    const minValue = parseInt(this.minValueInputTickRef.nativeElement.value);
    maxValue = Number(maxValue);
    if (!this.checkValidValueChange(minValue, maxValue)) {
      maxValue = minValue + this.minGap;
    }

    this.maxValueChange.emit(maxValue);
    this.redrawSlider();
  }

  public minValueAsTickChanged() {
    let minValue = parseInt(this.minValueInputTickRef.nativeElement.value);
    const maxValue = parseInt(this.maxValueInputTickRef.nativeElement.value);

    if (!this.checkValidValueChange(minValue, maxValue)) {
      minValue = maxValue - this.minGap; // upperBound
    }
    this.minValueChange.emit(minValue);
    this.minValueInputTickRef.nativeElement.value = minValue;
    this.redrawSlider();
  }

  public maxValueAsTickChanged() {
    const minValue = parseInt(this.minValueInputTickRef.nativeElement.value);
    let maxValue = parseInt(this.maxValueInputTickRef.nativeElement.value);

    if (!this.checkValidValueChange(minValue, maxValue)) {
      maxValue = minValue + this.minGap; // lowerBound
    }
    this.maxValueChange.emit(maxValue);
    this.maxValueInputTickRef.nativeElement.value = maxValue;
    this.redrawSlider();
  }

  /**
   * Draws the range-slider color between the lower and upper values
   * */
  public redrawSlider() {
    if (!(this.minValueInputTickRef != null && this.maxValueInputTickRef != null)) return;

    const sliderFillColor = this.disabled ? $color_grey_600 : $color_blue_primary;
    const backgroundColor = this.disabled ? $color_grey_300 : $color_blue_300;
    const minValueForDOM = this.calculateMinValueForDOM();
    const maxValueForDOM = this.calculateMaxValueForDOM();
    this.sliderTrack.nativeElement.style.background = `linear-gradient(to right, ${backgroundColor} ${minValueForDOM}% , ${sliderFillColor} ${minValueForDOM}% , ${sliderFillColor} ${maxValueForDOM}%, ${backgroundColor} ${maxValueForDOM}%)`;
  }

  // Getters & setters

  public get e2e() {
    return e2e;
  }

  public get dataE2eRoot(): string {
    return this.dataE2e != null ? `${this.e2e.vdabAiRangeSliderRoot}-${this.dataE2e}` : this.e2e.vdabAiRangeSliderRoot;
  }

  public get dataE2eTypeInputContainerElement(): string {
    return this.dataE2e != null ? `${this.e2e.vdabAiRangeSliderTypeInputContainerElement}-${this.dataE2e}` : this.e2e.vdabAiRangeSliderTypeInputContainerElement;
  }

  public get dataE2eMinTypeInputElement(): string {
    return this.dataE2e != null ? `${this.e2e.vdabAiRangeSliderMinTypeInputElement}-${this.dataE2e}` : this.e2e.vdabAiRangeSliderMinTypeInputElement;
  }

  public get dataE2eMaxTypeInputElement(): string {
    return this.dataE2e != null ? `${this.e2e.vdabAiRangeSliderMaxTypeInputElement}-${this.dataE2e}` : this.e2e.vdabAiRangeSliderMaxTypeInputElement;
  }

  public get dataE2eSliderContainerElement(): string {
    return this.dataE2e != null ? `${this.e2e.vdabAiRangeSliderSliderContainerElement}-${this.dataE2e}` : this.e2e.vdabAiRangeSliderSliderContainerElement;
  }

  public get dataE2eMinDragInputElement(): string {
    return this.dataE2e != null ? `${this.e2e.vdabAiRangeSliderMinDragInputElement}-${this.dataE2e}` : this.e2e.vdabAiRangeSliderMinDragInputElement;
  }

  public get dataE2eMaxDragInputElement(): string {
    return this.dataE2e != null ? `${this.e2e.vdabAiRangeSliderMaxDragInputElement}-${this.dataE2e}` : this.e2e.vdabAiRangeSliderMaxDragInputElement;
  }

  // Helper methods

  private checkValidValueChange(minValue: number, maxValue: number) {
    return maxValue - minValue >= this.minGap;
  }

  private calculateMinValueForDOM(): number {
    return ((this.minValue - this.minLimit) / (this.maxLimit - this.minLimit)) * 100;
  }

  private calculateMaxValueForDOM(): number {
    return ((this.maxValue - this.minLimit) / (this.maxLimit - this.minLimit)) * 100;
  }
}

import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ISuggestedOccupationalProfile} from "../../../models/ISuggestedOccupationalProfile";
import {TextContentService} from "../../../services/text-content.service";
import {isNullOrUndefined} from "../../../../utils/object";

@Component({
  selector: "app-suggested-profession-explanation-modal",
  templateUrl: "./suggested-profession-explanation-modal.component.html",
  styleUrls: ["./suggested-profession-explanation-modal.component.scss"],
})
export class SuggestedProfessionExplanationModalComponent {
  @Input() selectedSuggestedProfession!: ISuggestedOccupationalProfile;
  @Input() showModal = false;
  @Output() showModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public tcs: TextContentService) {
  }

  openChange(open: boolean) {
    this.showModal = open
    this.showModalChange.emit(open)
  }

  public noExplanationsAvailable(
  ): boolean {
    return (
      (isNullOrUndefined(this.selectedSuggestedProfession.posLinkedQuestions) &&
      isNullOrUndefined(this.selectedSuggestedProfession.negLinkedQuestions)) ||
      (!Object.keys(this.selectedSuggestedProfession.posLinkedQuestions).length &&
        !Object.keys(this.selectedSuggestedProfession.negLinkedQuestions).length)
    );
  }
}

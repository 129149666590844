import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { catchError, Observable, tap, throwError } from "rxjs";

import { uuidv4 } from "../../../utils/uuid";
import { Empty } from "../../models/Empty";
import { Loading, LoadingEnum, Werkniveau } from "../../models/Enums";
import { ISession } from "../../models/ISession";
import { ISuggestedOccupationalProfileFilter } from "../../models/ISuggestedOccupationalProfileFilter";
import { HttpService } from "../../services/http.service";
import {
  LoadSessionAction,
  SetInterestQuestions,
  SetIsKnelpuntBeroepAction,
  SetIsNotKnelpuntBeroepAction,
  SetOccupationalProfileQuestions,
  SetSuggestedOccupationalProfilesAction,
  SetWerkniveauFilterAction,
  StartSession,
} from "./Result.actions";

export interface IResultState {
  session: ISession;
  filter: ISuggestedOccupationalProfileFilter;
  sessionLoading: Loading;
}

const defaultResultState: IResultState = {
  session: Empty.session(),
  filter: Empty.suggestedOccupationalProfileFilter(),
  sessionLoading: LoadingEnum.Success,
};

@State<IResultState>({
  name: "session",
  defaults: defaultResultState,
})
@Injectable()
export class ResultState {
  constructor(private store: Store, private httpService: HttpService) {}

  @Action(StartSession)
  startSession(ctx: StateContext<IResultState>) {
    ctx.patchState({
      session: { ...ctx.getState().session, sessionId: uuidv4() },
    });
  }

  @Action(SetInterestQuestions)
  setInterestQuestions(ctx: StateContext<IResultState>, action: SetInterestQuestions) {
    ctx.patchState({
      session: {
        ...ctx.getState().session,
        ratedInterestQuestions: action.interestQuestions,
      },
    });
  }

  @Action(SetOccupationalProfileQuestions)
  setOccupationalProfileQuestions(ctx: StateContext<IResultState>, action: SetOccupationalProfileQuestions) {
    ctx.patchState({
      session: {
        ...ctx.getState().session,
        ratedOccupationalProfilesQuestions: action.occupationalProfileQuestions,
      },
    });
  }

  @Action(SetSuggestedOccupationalProfilesAction)
  setSuggestedOccupationalProfiles(ctx: StateContext<IResultState>, action: SetSuggestedOccupationalProfilesAction) {
    ctx.patchState({
      session: {
        ...ctx.getState().session,
        suggestedOccupationalProfiles: action.suggestedOccupationalProfiles,
      },
    });
  }

  @Action(SetWerkniveauFilterAction)
  setWerkniveauFilter(ctx: StateContext<IResultState>, action: SetWerkniveauFilterAction) {
    const werkniveaus = ctx.getState().filter.werkniveaus;
    let newWerkniveaus = werkniveaus;

    if (action.enable && !werkniveaus.includes(action.werkniveau)) {
      // Werkniveau needs to be added and is not yet part of werkniveaus to include
      newWerkniveaus = [...werkniveaus, action.werkniveau];
    } else if (!action.enable && werkniveaus.includes(action.werkniveau)) {
      // Werkniveau needs to be removed and is part of werkniveaus to include
      newWerkniveaus = werkniveaus.filter((w: Werkniveau) => w !== action.werkniveau);
    }
    ctx.patchState({
      filter: {
        ...ctx.getState().filter,
        werkniveaus: newWerkniveaus,
      },
    });
  }

  @Action(SetIsKnelpuntBeroepAction)
  setIsKnelpuntBeroep(ctx: StateContext<IResultState>, action: SetIsKnelpuntBeroepAction) {
    ctx.patchState({
      filter: {
        ...ctx.getState().filter,
        isKnelpuntBeroep: action.isKnelpuntBeroep,
      },
    });
  }

  @Action(SetIsNotKnelpuntBeroepAction)
  setIsNotKnelpuntBeroep(ctx: StateContext<IResultState>, action: SetIsNotKnelpuntBeroepAction) {
    ctx.patchState({
      filter: {
        ...ctx.getState().filter,
        isNotKnelpuntBeroep: action.isNotKnelpuntBeroep,
      },
    });
  }

  @Action(LoadSessionAction)
  loadSession(ctx: StateContext<IResultState>, action: LoadSessionAction): Observable<ISession> {
    ctx.patchState({
      sessionLoading: LoadingEnum.Loading,
    });
    return this.httpService.loadSession(action.sessionId).pipe(
      tap((session) => {
        ctx.patchState({
          session,
          sessionLoading: LoadingEnum.Success,
        });
      }),
      catchError((err, caught: Observable<ISession>) => {
        ctx.patchState({
          sessionLoading: LoadingEnum.Error,
        });
        console.error("Error while loading next question: ", err);
        return throwError(err);
      })
    );
  }
}

import {AfterViewInit, Component, OnInit} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {BaseButtonComponent} from "../base-button/base-button.component";

@Component({
  selector: "vdab-ai-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
/**
 * Button component has 1 content slot:
 * 1. text: Represents the content of the button
 *
 * See example in 'examples/button-example.component.html'
 * */
export class ButtonComponent extends BaseButtonComponent implements OnInit, AfterViewInit {
  // Data

  // Lifecycle

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  // Interface methods

  // Getters & setters
  
  public get e2e() {
    return e2e;
  }

  // Helper methods
}

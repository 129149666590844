<a
  #fabRef
  class="c-round-btn"
  [class]="fabIcon"
  [class.-primary]="isDefault"
  [class.-secundary]="secundary"
  [class.-tertiary]="tertiary"
  [class.-ghost]="ghost"
  [class.-light]="light"
  [class.-warning]="warning"
  [class.-danger]="danger"
  [class.disabled]="disabled"
  [class.center-custom-fab-icon]="fabCustomIconUsed"
  [attr.data-e2e]="e2e.vdabAiFabButtonRoot"
>
  <ng-content select="[fabCustomIcon]"></ng-content>
</a>

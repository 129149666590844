<ng-container *ngVar="open$ | async as opened">
  <ng-container *ngVar="saveOnVdabButtonDisabled$ | async as saveOnVdabButtonDisabled">
    <!-- Desktop buttons -->
    <div class="hide-md-and-down u-text-align-end">
      <button
        class="c-btn -ninja u-mb1 u-ml15"
        (click)="saveOnVdab($event)"
        [class.u-display-hidden]="saveOnVdabButtonDisabled"
      >
        <vdab-ai-tooltip class="u-pt05 u-display-inline-block" position="bottom">
          <span activator><span class="u-mr05 mdi mdi-content-save"></span>{{ tcs.getText("save_on_vdab_be") }}</span>
          <span content>{{ tcs.getText("save_on_vdab_be_explanation") }}</span>
        </vdab-ai-tooltip>
      </button>
      <button class="c-btn -ninja u-mb1 u-ml15" (click)="showMailWidgetModal()">
        <span class="u-mr05 mdi mdi-email"></span>{{ tcs.getText("send_via_mail") }}
      </button>
      <button class="c-btn -ninja u-mb1 u-ml15" (click)="downloadPdf($event)">
        <span class="u-mr05 mdi mdi-download"></span>{{ tcs.getText("save_as_pdf") }}
      </button>
    </div>
    <vdab-ai-modal [open]="opened" (openChange)="openChange.emit($event)">
      <h2 title class="u-mb0">{{ saveResultsModalTitle$ | async }}</h2>
      <div body>
        <ng-container *ngIf="showButtonsWidget$ | async">
          <!-- Mobile buttons -->
          <button
            class="c-btn -ninja"
            (click)="saveOnVdab($event)"
            [class.u-display-inline-block]="!saveOnVdabButtonDisabled"
            [class.u-display-hidden]="saveOnVdabButtonDisabled"
          >
            <vdab-ai-tooltip class="u-pt05 u-display-inline-block" position="bottom">
              <span activator
              ><span class="u-mr05 mdi mdi-content-save"></span>{{ tcs.getText("save_on_vdab_be") }}
              </span>
              <span content>{{ tcs.getText("save_on_vdab_be_explanation") }}</span>
            </vdab-ai-tooltip>
          </button>
          <button class="c-btn -ninja u-display-block" (click)="showMailWidgetModal()">
            <span class="u-mr05 mdi mdi-email"></span>{{ tcs.getText("send_via_mail") }}
          </button>
          <button class="c-btn -ninja u-display-block" (click)="downloadPdf($event)">
            <span class="u-mr05 mdi mdi-download"></span>{{ tcs.getText("save_as_pdf") }}
          </button>
        </ng-container>
        <ng-container *ngIf="showMailWidget$ | async">
          <app-save-results-modal-form
            [session$]="sessionWithCutOffOccupationalProfiles$"
            [filteredOccupationalProfiles$]="resultFacade.filteredSuggestedOccupationalProfiles$"
            (mailSent)="onMailSent($event)"
          ></app-save-results-modal-form>
        </ng-container>
      </div>
    </vdab-ai-modal>
    <div class="c-snackbar__container">
      <vdab-ai-snackbar #sendMailPdfLoadingSnackbar appearance="neutral">
        <div content>
          <span class="mdi mdi-check-outline u-mr05"></span>{{ tcs.getText("send_results_sending_mail") }}
        </div>
      </vdab-ai-snackbar>
      <vdab-ai-snackbar #sendMailPdfErrorSnackbar appearance="warning">
        <div content>
          <span class="mdi mdi-alert-circle-outline u-mr05"></span>{{ tcs.getText("send_results_sending_mail_error") }}
        </div>
      </vdab-ai-snackbar>
      <vdab-ai-snackbar #sendMailPdfDoneSnackbar appearance="neutral">
        <div content><span class="mdi mdi-check-outline u-mr05"></span>{{ tcs.getText("send_results_mail_sent") }}</div>
      </vdab-ai-snackbar>
      <ng-container *ngVar="saveOnVdabStatus$ | async as saveOnVdabStatus">
        <vdab-ai-snackbar #saveOnVdabLoadingSnackbar appearance="neutral">
          <div content>
            {{ tcs.getText("save_on_vdab_be_saving") }}
          </div>
        </vdab-ai-snackbar>
        <vdab-ai-snackbar #saveOnVdabErrorSnackbar appearance="warning">
          <div content>
            <span class="mdi mdi-alert-circle-outline u-mr05"></span>{{ tcs.getText("save_on_vdab_be_error") }}
          </div>
        </vdab-ai-snackbar>
        <vdab-ai-snackbar #saveOnVdabDoneSnackbar appearance="neutral">
          <div content>
            <span class="mdi mdi-check-outline u-mr05"></span>{{ tcs.getText("save_on_vdab_be_success") }}
          </div>
        </vdab-ai-snackbar>
        <vdab-ai-snackbar #downloadPdfErrorSnackbar appearance="warning">
          <div content>
            <span class="mdi mdi-alert-circle-outline u-mr05"></span
            >{{ tcs.getText("send_results_sending_mail_error") }}
          </div>
        </vdab-ai-snackbar>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

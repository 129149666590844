import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import isEqual from "lodash.isequal";
import { Observable, shareReplay } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

import { Loading, Werkniveau } from "../../models/Enums";
import { IRatedAnswers } from "../../models/IRatedAnswers";
import { ISession } from "../../models/ISession";
import { ISuggestedOccupationalProfile } from "../../models/ISuggestedOccupationalProfile";
import {
  LoadSessionAction,
  SetInterestQuestions,
  SetIsKnelpuntBeroepAction,
  SetIsNotKnelpuntBeroepAction,
  SetOccupationalProfileQuestions,
  SetSuggestedOccupationalProfilesAction,
  SetWerkniveauFilterAction,
  StartSession,
} from "./Result.actions";
import { ResultSelectors } from "./Result.selectors";

@Injectable({
  providedIn: "root",
})
export class ResultFacade {
  // ===================================================================================================================
  // Data

  public session$: Observable<ISession>;

  public suggestedOccupationalProfiles$: Observable<ISuggestedOccupationalProfile[]>;
  public filteredSuggestedOccupationalProfiles$: Observable<ISuggestedOccupationalProfile[]>;
  public werkniveaus$: Observable<Werkniveau[]>;
  public isKnelpuntBeroep$: Observable<boolean>;
  public isNotKnelpuntBeroep$: Observable<boolean>;

  public sessionLoading$: Observable<Loading>;

  // ===================================================================================================================
  // Lifecycle

  constructor(private store: Store, private router: Router) {
    // Selectors
    this.session$ = this.store.select(ResultSelectors.session).pipe(shareReplay(1));
    this.suggestedOccupationalProfiles$ = this.store
      .select(ResultSelectors.suggestedOccupationalProfiles)
      .pipe(shareReplay(1));
    this.filteredSuggestedOccupationalProfiles$ = this.store
      .select(ResultSelectors.filteredSuggestedOccupationalProfiles)
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return isEqual(prev, curr);
        }),
        shareReplay(1)
      );
    this.werkniveaus$ = this.store.select(ResultSelectors.werkniveaus).pipe(shareReplay(1));
    this.isKnelpuntBeroep$ = this.store.select(ResultSelectors.isKnelpuntBeroep).pipe(shareReplay(1));
    this.isNotKnelpuntBeroep$ = this.store.select(ResultSelectors.isNotKnelpuntBeroep).pipe(shareReplay(1));

    this.sessionLoading$ = this.store.select(ResultSelectors.sessionLoading).pipe(shareReplay(1));

    // Effects

    // Workaround to access current route in service
    // Source: https://stackoverflow.com/questions/39977962/angular-2-0-2-activatedroute-is-empty-in-a-service
    this.router.routerState.root.children[0].url.subscribe((urlSegments) => {
      const url = urlSegments[0].path;
      if (url === "bekijk-resultaten") {
        const sessionId = urlSegments[1].path;
        this.loadSession(sessionId);
      }
    });
  }

  // ===================================================================================================================
  // Main methods

  startSession() {
    this.store.dispatch(new StartSession());
  }

  loadSession(sessionId: string) {
    this.store.dispatch(new LoadSessionAction(sessionId));
  }

  setInterestQuestions(questions: IRatedAnswers) {
    return this.store.dispatch(new SetInterestQuestions(questions));
  }

  setOccupationalProfileQuestions(questions: IRatedAnswers) {
    return this.store.dispatch(new SetOccupationalProfileQuestions(questions));
  }

  setSuggestedOccupationalProfiles(suggestedOccupationalProfiles: ISuggestedOccupationalProfile[]) {
    return this.store.dispatch(new SetSuggestedOccupationalProfilesAction(suggestedOccupationalProfiles));
  }

  setWerkniveauFilter(werkniveau: Werkniveau, enable: boolean) {
    return this.store.dispatch(new SetWerkniveauFilterAction(werkniveau, enable));
  }

  setIsKnelpuntBeroep(enable: boolean) {
    return this.store.dispatch(new SetIsKnelpuntBeroepAction(enable));
  }

  setIsNotKnelpuntBeroep(enable: boolean) {
    return this.store.dispatch(new SetIsNotKnelpuntBeroepAction(enable));
  }
}

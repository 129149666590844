export const imgReaderFromBlob = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  let encoded = "";
  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      const dataUrl: any = reader.result;
      const base64 = dataUrl.split(",")[1];
      encoded = "data:image/png;base64, " + base64;
      return resolve(encoded);
    };
    reader.readAsDataURL(blob);
  });
};

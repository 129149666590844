import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import { combineLatest, Observable, shareReplay, Subject, takeUntil } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import { Empty } from "../../../models/Empty";
import { AnswerInteractionTypes } from "../../../models/Enums";
import { isInterestQuestionSummary, isProfessionQuestionSummary } from "../../../models/INextQuestionSummary";
import { QuestionFacade } from "../../../state/Question/Question.facade";

@Component({
  selector: "app-card-referrer",
  templateUrl: "./card-referrer.component.html",
  styleUrls: ["./card-referrer.component.scss"],
})
export class CardReferrerComponent implements OnInit, OnDestroy {
  // Data

  public showQuestionCard$: Observable<boolean>;
  public showMessageCard$: Observable<boolean>;

  refreshCard = false;

  private destroy$: Subject<void> = new Subject<void>();

  // Lifecycle

  constructor(private questionFacade: QuestionFacade, private cdr: ChangeDetectorRef) {
    // Observables

    this.showMessageCard$ = this.createShowMessageCardHandler();
    this.showQuestionCard$ = this.createShowQuestionCardHandler();
  }

  ngOnInit(): void {
    const answer = Empty.answer();
    answer.interactionType = AnswerInteractionTypes.Start;
    this.questionFacade.answerQuestion(answer);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  // Helper methods

  private createShowQuestionCardHandler(): Observable<boolean> {
    return combineLatest(this.questionFacade.nextQuestionSummary$, this.showMessageCard$).pipe(
      takeUntil(this.destroy$),
      map(([currentQuestion, showMessageCard]) => {
        // when using Google Translate, the binding in the question card was lost, which resulted in that the question didn't changed anymore.
        this.refreshQuestionCard()
        const isQuestion = isInterestQuestionSummary(currentQuestion) || isProfessionQuestionSummary(currentQuestion);
        return !showMessageCard && isQuestion;
      }),
      shareReplay(1)
    );
  }

  private refreshQuestionCard() {
    this.refreshCard = true;
    this.cdr.detectChanges();
    this.refreshCard = false;
    this.cdr.detectChanges();
  }

  private createShowMessageCardHandler(): Observable<boolean> {
    return combineLatest(this.questionFacade.showProfessionWarning$, this.questionFacade.showEndMessage$).pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      map(([showProfessionWarning, showEndMessage]) => showProfessionWarning || showEndMessage),
      shareReplay(1)
    );
  }
}

export const $small_screen_bp = 600;
export const $medium_screen_bp = 960;
export const $large_screen_bp = 1370;

//burgundy
export const $color_burgundy_500 = "#ba0047";
export const $color_burgundy_100 = "#fbf6f8";
export const $color_burgundy_300 = "#e0bdca";
export const $color_burgundy_a500 = "#900c3f";
export const $color_burgundy_700 = "#810134";
export const $color_burgundy_900 = "#6a002a";
export const $color_burgundy = $color_burgundy_500;

//red
export const $color_red_a500 = "#f80000";
export const $color_red_100 = "#fff8f8";
export const $color_red_200 = "#fbd0d0";
export const $color_red_300 = "#ff9999";
export const $color_red_500 = "#ed1514";
export const $color_red_700 = "#c80001";
export const $color_red_900 = "#9e0000";
export const $color_red = $color_red_500;

//orange
export const $color_orange_a500 = "#ff8600";
export const $color_orange_100 = "#fef7f1";
export const $color_orange_200 = "#FDEEDA";
export const $color_orange_300 = "#fdd9a8";
export const $color_orange_500 = "#fb9612";
export const $color_orange_700 = "#e25910";
export const $color_orange_900 = "#c43e00";
export const $color_orange = $color_orange_500;

//yellow
export const $color_yellow_a500 = "#ffe200";
export const $color_yellow_100 = "#fdfde9";
export const $color_yellow_200 = "#fafad2";
export const $color_yellow_300 = "#fff79e";
export const $color_yellow_500 = "#ffd82a";
export const $color_yellow_700 = "#fcbd01";
export const $color_yellow_900 = "#b88a02";
export const $color_yellow_1100 = "#5e5008";
export const $color_yellow = $color_yellow_500;

//light-green
export const $color_lightgreen_200 = "#ebf5dd";
export const $color_lightgreen_400 = "#d8f2a4";
export const $color_lightgreen_500 = "#7eba19";
export const $color_lightgreen_600 = "#6a9129";
export const $color_lightgreen = $color_lightgreen_500;

//green
export const $color_green_a500 = "#00D719";
export const $color_green_100 = "#f7fcf7";
export const $color_green_200 = "#EBF5DD";
export const $color_green_300 = "#c4edc9";
export const $color_green_500 = "#5bcf6a";
export const $color_green_600 = "#00a14b";
export const $color_green_700 = "#1d8229";
export const $color_green_900 = "#104917";
export const $color_green = $color_green_500;

//turquoise
export const $color_turquoise_a500 = "#00c5b3";
export const $color_turquoise_100 = "#f9fdfd";
export const $color_turquoise_200 = "#eaf6f5";
export const $color_turquoise_300 = "#c8e8e5";
export const $color_turquoise_500 = "#49b4aa";
export const $color_turquoise_700 = "#0c887e";
export const $color_turquoise_900 = "#004747";
export const $color_turquoise = $color_turquoise_500;

//blue
export const $color_blue_a500 = "#1a93ff";
export const $color_blue_100 = "#eff7fe";
export const $color_blue_200 = "#dbecf8";
export const $color_blue_300 = "#c4def0";
export const $color_blue_400 = "#82bfff";
export const $color_blue_500 = "#2d89cc";
export const $color_blue_700 = "#0a5d98";
export const $color_blue_900 = "#004475";
export const $color_blue = $color_blue_500;
export const $color_blue_primary = $color_blue_500;

//indigo
export const $color_indigo_a500 = "#002a7f";
export const $color_indigo_100 = "#eef0f4";
export const $color_indigo_300 = "#c4cbdb";
export const $color_indigo_500 = "#2a4278";
export const $color_indigo_700 = "#102759";
export const $color_indigo_900 = "#041847";
export const $color_indigo = $color_indigo_500;

//purple
export const $color_purple_a500 = "#48006a";
export const $color_purple_100 = "#f4f3f6";
export const $color_purple_300 = "#d0cbd6";
export const $color_purple_400 = "#796694";
export const $color_purple_500 = "#52426a";
export const $color_purple_700 = "#443757";
export const $color_purple_900 = "#322a40";
export const $color_purple = $color_purple_500;

//grey
export const $color_grey_a500 = "#2F2F2F";
export const $color_grey_100 = "#f8f7f8";
export const $color_grey_200 = "#f1f2f3";
export const $color_grey_300 = "#e3e5e7";
export const $color_grey_400 = "#dcdcdc";
export const $color_grey_500 = "#c9cbce";
export const $color_grey_600 = "#aeb0b6";
export const $color_grey_700 = "#9097a0";
export const $color_grey_800 = "#6a707b";
export const $color_grey_900 = "#474b50";
export const $color_grey = $color_grey_500;

//black and white
export const $color_white = "#ffffff";
export const $color_black = "#000000";

//specials
export const $transparent_black_00 = "transparentize($color-black, 1)";
export const $transparent_black_05 = "transparentize($color-black, .95)";
export const $transparent_black_10 = "transparentize($color-black, .9)";
export const $transparent_black_15 = "transparentize($color-black, .85)";
export const $transparent_black_20 = "transparentize($color-black, .8)";
export const $transparent_black_25 = "transparentize($color-black, .75)";
export const $transparent_black_30 = "transparentize($color-black, .7)";
export const $transparent_black_40 = "transparentize($color-black, .6)";
export const $transparent_black_50 = "transparentize($color-black, .5)";
export const $transparent_black_60 = "transparentize($color-black, .4)";
export const $transparent_black_70 = "transparentize($color-black, .3)";
export const $transparent_black_75 = "transparentize($color-black, .25)";
export const $transparent_black_80 = "transparentize($color-black, .2)";
export const $transparent_black_90 = "transparentize($color-black, .1)";
export const $transparent_black_95 = "transparentize($color-black, .05)";

export const $transparent_white_00 = "transparentize($color-white, 1)";
export const $transparent_white_15 = "transparentize($color-white, .85)";
export const $transparent_white_25 = "transparentize($color-white, .75)";
export const $transparent_white_50 = "transparentize($color-white, .5)";
export const $transparent_white_75 = "transparentize($color-white, .25)";

export const $transparent_blue_00 = "transparentize($color-blue, 1)";
export const $transparent_blue_15 = "transparentize($color-blue, .85)";
export const $transparent_blue_25 = "transparentize($color-blue, .75)";
export const $transparent_blue_50 = "transparentize($color-blue, .5)";
export const $transparent_blue_75 = "transparentize($color-blue, .25)";

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DialogBoxComponent } from "angular-frontend-components";
import {
  combineLatest,
  fromEvent,
  mergeWith,
  Observable,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  takeUntil,
  throttleTime,
} from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { IEnvironment } from "../../../../environments/IEnvironment";
import { ISuggestedOccupationalProfile } from "../../../models/ISuggestedOccupationalProfile";
import { StyleService } from "../../../services/style.service";
import { TextContentService } from "../../../services/text-content.service";
import { QuestionFacade } from "../../../state/Question/Question.facade";
import { ResultFacade } from "../../../state/Result/Result.facade";

@Component({
  selector: "app-orient-test-page",
  templateUrl: "./orient-test-page.component.html",
  styleUrls: ["./orient-test-page.component.scss"],
})
export class OrientTestPageComponent implements OnInit, AfterViewInit, OnDestroy {
  // Data

  public infoGainProgress$: Observable<number>;
  public showExplanationModal = false;
  public selectedSuggestedProfession$: Subject<ISuggestedOccupationalProfile> =
    new Subject<ISuggestedOccupationalProfile>();

  public currentSessionIdSubscription$: Subscription;

  public windowResize$: Observable<Event | null>;
  public isDesktopViewport$: Observable<boolean>;
  public cardHeight$: Observable<string>;

  @ViewChild("errorDialog") errorDialog!: DialogBoxComponent;

  public afterViewInit$: Subject<void> = new Subject<void>();
  public destroy$: Subject<void> = new Subject<void>();

  // Lifecycle
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public tcs: TextContentService,
    public resultFacade: ResultFacade,
    public questionFacade: QuestionFacade,
    public styleService: StyleService
  ) {
    // Observables

    this.infoGainProgress$ = this.questionFacade.infoGainProgress$.pipe(
      map((infoGainProgress: number) => {
        return Math.min(Number(infoGainProgress.toFixed(2)) * 100, 100);
      }),
      startWith(0),
      shareReplay(1)
    );

    this.windowResize$ = this.createWindowResizeHandler();
    this.isDesktopViewport$ = this.createIsDesktopViewportHandler();
    this.isDesktopViewport$.subscribe();

    this.cardHeight$ = this.createCardHeightHandler();

    // Subscriptions
    this.currentSessionIdSubscription$ = this.registerCurrentSessionIdHandler();
  }

  ngOnInit(): void {
    this.resultFacade.startSession();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.currentSessionIdSubscription$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next();
    this.cdr.detectChanges();
  }

  // Interface methods

  public openExplanationModal(suggestedProfession: ISuggestedOccupationalProfile) {
    this.showExplanationModal = true;
    this.selectedSuggestedProfession$.next(suggestedProfession);
  }

  // Getters & setters

  public get environment(): IEnvironment {
    return environment;
  }

  // Helper methods

  private createWindowResizeHandler(): Observable<Event | null> {
    return fromEvent(window, "resize").pipe(startWith(null), shareReplay(1));
  }

  private createIsDesktopViewportHandler(): Observable<boolean> {
    const checkViewport = () => {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      return vw >= this.styleService.styles.medium_screen_bp;
    };
    const checkViewportOnCreate = this.afterViewInit$.pipe(map(() => checkViewport()));
    return this.windowResize$.pipe(
      takeUntil(this.destroy$),
      map(() => checkViewport()),
      mergeWith(checkViewportOnCreate),
      throttleTime(100),
      startWith(checkViewport()),
      shareReplay(1)
    );
  }

  private createCardHeightHandler(): Observable<string> {
    return combineLatest(this.windowResize$, this.afterViewInit$).pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.isDesktopViewport$),
      map(() => {
        const toolbarElement: HTMLElement | null = document.querySelector(".background-arc");
        const toolbarElementHeight = toolbarElement?.clientHeight ?? 0;
        const orientProgressElement: HTMLElement | null = document.getElementById("orient-progress");
        const orientProgressElementHeight = orientProgressElement?.clientHeight ?? 0;
        const padding = 32;
        const cardHeight = window.innerHeight - toolbarElementHeight - orientProgressElementHeight - padding;
        return `${cardHeight}px !important`;
      }),
      shareReplay(1)
    );
  }

  private registerCurrentSessionIdHandler(): Subscription {
    return this.resultFacade.session$
      .pipe(
        map((session) => {
          const queryParams: Params = { s: session.sessionId };
          return queryParams;
        }),
        shareReplay(1)
      )
      .subscribe(async (queryParams) => {
        await this.router.navigate([], {
          relativeTo: this.route,

          queryParams: queryParams,

          queryParamsHandling: "merge", // remove to replace all query params by provided
        });
      });
  }
}

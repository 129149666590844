<nav
  class="vdab-toolbar-container u-px15 u-pt025"
  [attr.data-e2e]="e2e.vdabAiHeaderRoot"
>
  <div class="vdab-toolbar-logo-container">
    <a [href]="appLogoLinkUrl" [attr.data-e2e]="e2e.vdabAiHeaderAppLogoLinkUrl">
      <img class="vdab-toolbar-logo u-pr1" [attr.data-e2e]="e2e.vdabAiHeaderAppLogoUrl" [src]="appLogoUrl">
    </a>
    <span class="vdab-toolbar-chevron action-go vdab-text-color-white u-p0 u-hide@screen"></span>
    <a
      href="/"
      class="vdab-toolbar-app-name"
      [attr.data-e2e]="e2e.vdabAiHeaderAppName"
    >{{ appName}}</a>
  </div>
  <div class="spacer"></div>
  <div class="vdab-toolbar-end-container" [attr.data-e2e]="e2e.vdabAiHeaderToolbarContent">
    <ng-content select="[toolbar-content]"></ng-content>
  </div>
</nav>

<div
  #dialog
  class="dialogbox"
  [attr.data-e2e]="e2e.vdabAiDialogBoxRoot"
>
  <div
    class="dialogbox-content"
    [class.dialogbox-info]="isDefault"
    [class.dialogbox-warning]="warning"
    [class.dialogbox-danger]="danger"
    role="alert"
    aria-live="polite"
    [attr.data-e2e]="e2e.vdabAiDialogBoxHuisstijlRoot"
  >
    <h1
      class="h3"
      [attr.data-e2e]="e2e.vdabAiDialogBoxTitle"
    >
      <ng-content select="[title]"></ng-content>
    </h1>
    <div
      class="alert-content-container"
      [attr.data-e2e]="e2e.vdabAiDialogBoxContent"
    >
      <ng-content select="[content]"></ng-content>
    </div>
    <div
      class="buttons-container u-mt1"
      [attr.data-e2e]="e2e.vdabAiDialogBoxButtons"
    >
      <ng-content select="[buttons]"></ng-content>
    </div>
  </div>
</div>

<div
  #tooltip
  class="tooltip"
  [class.top]="top"
  [class.bottom]="bottom"
  [class.left]="left"
  [class.right]="right"
  [attr.data-e2e]="e2e.vdabAiTooltipRoot"
>
  <div #tooltipTarget class="tooltip-target" [attr.data-e2e]="e2e.vdabAiTooltipTargetElement">
    <ng-content #activator select="[activator]" style="z-index: -1;"></ng-content>
  </div>
  <div #tooltipContent class="tooltip-content" [attr.data-e2e]="e2e.vdabAiTooltipContentElement">
    <ng-content #content select="[content]"></ng-content>
  </div>
</div>

<ng-container *ngIf="textContentService.textContent$ | async as textContent">
  <ng-container *ngVar="selectedLabel$ | async as selectedLabel">
    <ng-container *ngVar="showOptions$ | async as showOptions">
      <div
        class="dropdown"
        #dropdownParent
        [attr.data-e2e]="e2e.vdabAiEditableDropdownRoot"
      >
        <div class="dropdown-label u-mb025">
          <label for="dropdown" [attr.data-e2e]="e2e.vdabAiEditableDropdownLabelElement">{{label}}</label>
        </div>
        <div class="form-field field-select">
          <div class="form-field-icon select">
            <div class="select-input">
              <input
                id="dropdown"
                #dropdown
                [placeholder]="placeholder"
                [value]="selectedLabel"
                (click)="onInputClicked()"
                (keyup)="onInputChanged($event)"
                [attr.data-e2e]="e2e.vdabAiEditableDropdownInputElement"
              >
              <span class="field-icon icon-arrow-down"></span>
            </div>
            <ng-container *ngIf="filteredItems$ | async as items">
              <div
                #selectOptions
                class="select-options"
                [style.visibility]="selectOptionsVisibility$ | async"
                [style.top]="selectOptionsTop"
                [style.width]="selectOptionsWidth"
                [class.hover]="false"
                [attr.data-e2e]="e2e.vdabAiEditableDropdownOptionsContainerElement"
              >
                <ng-container *ngVar="arrowKeySelectedLabelIndex$ | async as arrowKeySelectedLabelIndex">
                <span
                  *ngFor="let item of items;let i = index;"
                  class="select-option"
                  [class.hover]="i == arrowKeySelectedLabelIndex"
                  (click)="onSelectChanged(item.label)"
                  tabindex="0"
                >{{item.label}}</span>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

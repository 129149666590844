import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";

@Component({
  selector: "vdab-ai-dialog-box",
  templateUrl: "./dialog-box.component.html",
  styleUrls: ["./dialog-box.component.scss"],
})
/**
 * Dialogbox component has 3 content slots:
 * 1. title: Represents the title of the Dialogbox
 * 2. content: Represents the text content of the Dialogbox
 * 3. buttons: Represent the action buttons of the Dialogbox (e.g. Ok, cancel, ...)
 *
 * See example in 'examples/dialog-box-example.component.html'
 * */
export class DialogBoxComponent implements OnInit {
  // Data

  /**
   * Appearance of DialogBox
   */
  @Input() appearance: 'info' | "warning" | "danger" = "info";

  @ViewChild("dialog") dialog!: ElementRef;

  // Lifecycle
  constructor() {
  }

  ngOnInit(): void {
    if (this.appearance == null)
      this.appearance = "info";
  }

  // Interface methods

  // Getters & setters

  public get isDefault() {
    return this.appearance === "info" || !(this.appearance === "warning" || this.appearance === "danger");
  }

  public get info() {
    return this.appearance === "info"
  }

  public get warning() {
    return this.appearance === "warning"
  }

  public get danger() {
    return this.appearance === "danger"
  }

  public get e2e() {
    return e2e;
  }

  // Helper methods
}

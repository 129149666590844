import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface ITextContent {
  close: string;
  collapse_all: string;
  dropdown_select: string;
  expand_all: string;
}

export enum Language {
  DUTCH = "nl_BE",
}

type Translations<T extends Language, U extends ITextContent> = {
  [K in T]: U;
};

export const translations: Translations<Language, ITextContent> = {
  nl_BE: {
    close: `Sluiten`,
    collapse_all: `Klap alles dicht`,
    dropdown_select: `Selecteer...`,
    expand_all: `Klap alles open`,
  },
};

@Injectable({
  providedIn: "root",
})
export class TextContentService {
  public textContent$: Observable<ITextContent>;
  public language$: BehaviorSubject<Language> = new BehaviorSubject<Language>(Language.DUTCH);

  constructor() {
    this.textContent$ = this.language$.pipe(map((language) => translations[language]));
  }

  public changeLanguage(lang: Language) {
    this.language$.next(lang);
  }
}

import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";

@Component({
  selector: "vdab-ai-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"],
})
/**
 * Tab component has a single slot containing the body of the tab
 *
 * See example in 'examples/tabs-example.component.html'
 * */
export class TabComponent implements OnInit, AfterViewInit {
  // Data

  /**
   * Title for the tab header
   * */
  @Input("tabTitle") title!: string;

  /**
   * Property indicating if tab is active. There can only be 1 active tab at any given time
   * */
  @Input() active: boolean = false;


  // Lifecycle

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngAfterContentInit(): void {
    this.cdr.detectChanges();
  }

  // Interface methods
  // Getters & setters

  public get e2e() {
    return e2e;
  }

  public setActive(active: boolean) {
    this.active = active;
    this.cdr.detectChanges();
  }

  // Helper methods
}

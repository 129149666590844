<ng-container *ngIf="questionFacade.nextQuestionSummary$ | async as currentQuestion">
  <ng-container *ngVar="loading$ | async as loading">
    <div class="question-img">
      <ng-container *ngIf="!loading; else loadingSpinner">
        <ng-container *ngIf="imgUrl$ | async as imgUrl">
          <div #cardImg class="orient-img" [style.background-image]="imgUrl"></div>
        </ng-container>
      </ng-container>
      <ng-template #loadingSpinner>
        <div class="loading-container">
          <div class="alert" role="alert" aria-live="polite" style="text-align: center">
            <span class="c-spinner"></span>
            <div class="content">{{ this.tcs.getText("loading_data_dots") }}</div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="questionFacade.nextQuestionSummary$ | async as currentQuestion">
  <ng-container *ngVar="loading$ | async as loading">
    <div class="card-container" id="active">
      <!-- The question image -->
      <app-question-card-image></app-question-card-image>

      <!-- The question itself -->
      <div class="question-text">
        <ng-container *ngVar="showDescriptionButton$ | async as showDescriptionButton">
          <div class="entity-text">
            <!-- More information button -->

            <ng-container *ngVar="descriptionAriaLabel$ | async as descriptionAriaLabel">
              <button
                *ngIf="showDescriptionButton"
                class="info-btn c-round-btn fab -primary"
                tabindex="2"
                [attr.aria-label]="descriptionAriaLabel"
                (click)="toggleShowDescription()"
              >
                <ng-container *ngIf="toggleDescriptionButtonIcon$ | async as icon">
                  <span class="mdi mdi-information-variant" [class]="icon"></span>
                </ng-container>
              </button>
              <ng-container *ngIf="showInfoTooltip$ | async">
                <div [attr.color]="styleService.styles.vdab_blue_primary" class="tooltip u-p05" attach>
                  <span>{{ tcs.getText("click_here_for_more_info_profession_description") }}</span>
                </div>
              </ng-container>
            </ng-container>

            <div>
              <!-- The question to be answered -->
              <!-- https://developers.google.com/web/fundamentals/accessibility/focus/using-tabindex -->

              <h3 #questionText tabindex="1" class="entity-string u-pr2 u-pl2 u-pb1 u-m0">
                {{ currentQuestion.question }}
              </h3>
              <!-- Description of the question -->
              <ng-container *ngIf="showDescription$ | async as showDescription">
                <p class="entity-description u-mb1">
                  {{ currentQuestion.description }}
                  <br/>
                  <a class="entity-description-more-info" [href]="professionLinkUrl$ | async" target="_blank"
                  >{{ tcs.getText("more_info") }} <span class="mdi mdi-open-in-new"></span
                  ></a>
                </p>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- BUTTONS -->
      <div class="question-btn-container">
        <ng-container *ngVar="isFirstQuestion$ | async as firstQuestion">
          <ng-container *ngIf="!firstQuestion">
            <!-- Go back button -->
            <button
              class="fab elevated small-btn orient-blue-background vdab-text-color-white c-round-btn u-m05"
              [attr.color]="styleService.styles.vdab_blue_primary"
              tabindex="8"
              [attr.aria-label]="tcs.getText('previous')"
              (click)="goBack(currentQuestion)"
            >
              <span class="mdi mdi-chevron-left"></span>
            </button>
            <ng-template>
              <div class="fab small-btn u-m05"></div>
            </ng-template>
          </ng-container>
        </ng-container>

        <!-- Like button -->
        <button
          *ngVar="loading"
          [class]="{ 'disable-button': loading }"
          class="fab elevated large-btn orient-green-background vdab-text-color-white c-round-btn u-m05"
          [attr.color]="styleService.styles.vdab_green"
          tabindex="3"
          [attr.aria-label]="tcs.getText('agree')"
          (click)="loading ? null : like(currentQuestion)"
          [disabled]="loading"
        >
          <span class="mdi mdi-thumb-up"></span>
        </button>
        <!-- Dislike button -->
        <button
          *ngVar="loading"
          [class]="{ 'disable-button': loading }"
          class="fab elevated large-btn orient-orange-background vdab-text-color-white c-round-btn u-m05"
          [attr.color]="styleService.styles.vdab_orange"
          tabindex="4"
          [attr.aria-label]="tcs.getText('disagree')"
          (click)="loading ? null : dislike(currentQuestion)"
          [disabled]="loading"
        >
          <span class="mdi mdi-thumb-down"></span>
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

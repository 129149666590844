import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: "vdab-ai-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
/**
 * Modal component has 2 content slots:
 * 1. title: Represents the title of the Modal
 * 2. body: Represents the body of the modal
 *
 * See example in 'examples/modal-example.component.html'
 * */
export class ModalComponent implements OnInit, OnChanges, OnDestroy {
  // Data

  /**
   * Css background color for the modal.
   * Standard css colors can be used
   * E.g. white, rgb(0, 0, 0), #ffffff
   * Default: white
   * */
  @Input() backgroundColor: string = "white";
  /**
   * Indicates whether modal is opened or not
   * */
  @Input() open: boolean = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() fullScreen: boolean = false;

  // Lifecycle

  constructor() {
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.backgroundColor)) {
      this.backgroundColor = "white";
    }

    if (isNullOrUndefined(this.open)) {
      this.open = false;
    }

    if (isNullOrUndefined(this.fullScreen)) {
      this.fullScreen = false;
    }

    document.addEventListener("keyup", (event: KeyboardEvent) => this.handleEscapeKeyHandler(event));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["open"]) {
      const open = changes["open"].currentValue;
      this.openChange.emit(open);
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener("keyup", (event: KeyboardEvent) => this.handleEscapeKeyHandler(event));
  }

  // Interface methods

  toggleOpen() {
    const toggle = !this.open;
    this.open = toggle;
    this.openChange.emit(toggle);
  }

  closeModalForOverlay(event: Event) {
    event.preventDefault();

    // Only close for background overlay
    const target: Element | null = event.target as Element;
    if (target == null || !target.classList.contains("hs-overlay")) return;

    this.closeModal();
  }

  closeModal() {
    this.open = false;
    this.openChange.emit(false);
  }

  // Getters & setters

  public get e2e() {
    return e2e
  }

  // Helper methods
  private handleEscapeKeyHandler(event: KeyboardEvent) {
    if (this.open && event.key === "Escape") {
      this.closeModal();
    }
  }
}

import {Component, Input, OnInit} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {$color_blue_400} from "../../styles/styles";
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: "vdab-ai-progress-linear",
  templateUrl: "./progress-linear.component.html",
  styleUrls: ["./progress-linear.component.scss"],
})
/**
 * Progress linear component:
 *
 * See example in 'examples/progress-line-example.component.html'
 * */
export class ProgressLinearComponent implements OnInit {
  // Data

  /**
   * Height for the progress line as style string.
   * Default value is '1px'
   * E.g. '200px' or '0.5rem'
   * */
  @Input() height!: string;
  /**
   * Progress as a float between 0 and 100, representing the width as a percentage
   * E.g. Progress = 18, represents 18% width of the progress
   * */
  @Input() progress: number = 0;
  /**
   * CSS fill color for progress line
   * Default value is $color_blue_400 ("#82bfff")
   * E.g. 'lightgreen' or #ffffff
   * */
  @Input() fillColor: string = $color_blue_400;

  // Lifecycle
  constructor() {
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.height)) {
      this.height = "1px";
    }
    if (isNullOrUndefined(this.progress)) {
      this.progress = 0;
    }
    if (isNullOrUndefined(this.fillColor)) {
      this.fillColor = $color_blue_400;
    }
  }

  // Getters & setters
  public get formattedProgress(): string {
    if (this.progress < 0 || this.progress > 100) {
      throw Error("Progress should be a float between 0 and 1!");
    }

    const progress = Number(this.progress?.toFixed(0)) ?? 0;
    return `${progress}%`;
  }

  public get e2e() {
    return e2e;
  }
}

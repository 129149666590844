<ng-container *ngIf="textContentService.textContent$ | async as textContent">
  <div
    class="dropdown"
    [attr.data-e2e]="e2e.vdabAiDropdownRoot"
  >
    <div class="dropdown-label u-mb025">
      <label
        for="dropdown"
        [attr.data-e2e]="e2e.vdabAiDropdownLabelElement"
      >{{label}}</label>
    </div>
    <div class="form-field field-select">
      <div class="form-field-icon">
        <ng-container *ngIf="items$ | async as items">
          <select
            id="dropdown"
            (change)="onSelectChanged($event)"
            [attr.data-e2e]="e2e.vdabAiDropdownSelectElement"
          >
            <option *ngFor="let item of items" [selected]="item.value === value">{{item.label}}</option>
          </select>
        </ng-container>
        <span class="field-icon icon-arrow-down"></span>
      </div>
    </div>
  </div>
</ng-container>

<div
  [attr.data-e2e]="e2e.vdabAiAccordionRoot"
  class="c-accordion"
  aria-role="accordion"
>
  <div
    class="c-accordion__header"
    [attr.aria-expanded]="collapsed"
    [attr.data-e2e]="e2e.vdabAiAccordionToggleBtn"
    (click)="toggleCollapse()">
    <button
      class="c-accordion__button -h4"
    >
      <div [attr.data-e2e]="e2e.vdabAiAccordionTitle">
        <ng-content select="[title]"></ng-content>
      </div>
    </button>
    <a class="c-accordion__toggle-btn action-show" [attr.data-e2e]="e2e.vdabAiAccordionButtonText">
      <ng-content select="[buttonText]"></ng-content>
    </a>
  </div>
  <div
    class="c-accordion__content"
    [class]="{ 'content-hidden': collapsed}"
    [attr.aria-hidden]="collapsed"
    [attr.data-e2e]="e2e.vdabAiAccordionContent"
  >
    <ng-content></ng-content>
  </div>
</div>

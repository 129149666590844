import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";

@Component({
  selector: "vdab-ai-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
})
/**
 * Tooltip component has a single slot containing the activator of the tooltip
 * (activates the tooltip body on hover)
 *
 * See example in 'examples/tooltip-example.component.html'
 * */
export class TooltipComponent implements OnInit, AfterViewInit, OnDestroy {
  // Data

  /**
   * Text to be displayed inside the tooltip
   * */
  // @Input() text!: string;
  /**
   * Positions the body of the tooltip
   * */
  @Input() position: "top" | "bottom" | "left" | "right" = "bottom";

  @ViewChild("tooltip") tooltip!: ElementRef;
  @ViewChild("activator") activator!: ElementRef;
  @ViewChild("tooltipTarget") tooltipTarget!: ElementRef;
  @ViewChild("tooltipContent") tooltipContent!: ElementRef;

  // Lifecycle

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const mouseEnterHandler = (event: MouseEvent) => {
      const target = this.tooltipTarget.nativeElement;
      const content = this.tooltipContent.nativeElement;
      if (target == null || content == null) throw Error("Something went wrong rendering tooltip elements");
      content.style.display = "inline-block";
      this.placeTooltip();
    };
    this.tooltipTarget.nativeElement.addEventListener("mouseenter", mouseEnterHandler);

    const mouseLeaveHandler = (event: MouseEvent) => {
      this.tooltipContent.nativeElement.style.display = "none";
    };
    this.tooltipTarget.nativeElement.addEventListener("mouseleave", mouseLeaveHandler);

    this.placeTooltip();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
  }

  // Interface methods

  // Getters & setters

  public get e2e() {
    return e2e;
  }

  public get isDefault() {
    return !(this.position === "top" || this.position === "bottom" || this.position === "left" || this.position === "right");
  }

  public get top() {
    return this.position === "top";
  }

  public get bottom() {
    return this.position === "bottom" || this.isDefault;
  }

  public get left() {
    return this.position === "left";
  }

  public get right() {
    return this.position === "right";
  }

  // Helper methods

  private placeTooltip() {
    if (this.top) {
      this.placeCenterX();
      this.placeTop();
    } else if (this.left) {
      this.placeCenterY();
      this.placeLeft();
    } else if (this.right) {
      this.placeCenterY();
      this.placeRight();
    } else {
      this.placeCenterX();
      this.placeBottom();
    }
  }

  private placeLeft() {
    const target = this.tooltipTarget.nativeElement;
    const content = this.tooltipContent.nativeElement;
    const docEl = document.documentElement;
    if (target == null || content == null) throw Error("Something went wrong rendering tooltip elements");

    const targetLeft = target.offsetLeft - 16;
    let shiftedContentLeft = targetLeft - content.offsetWidth;

    if (shiftedContentLeft + content.offsetWidth > docEl.offsetWidth) {
      if (shiftedContentLeft + content.offsetWidth / 2 <= docEl.offsetWidth) {
        shiftedContentLeft = docEl.offsetWidth - content.offsetWidth;
      } else {
        shiftedContentLeft = docEl.offsetWidth - content.offsetWidth / 2;
      }
      this.placeBottom();
    }
    if (shiftedContentLeft < 0) {
      shiftedContentLeft = 0;
      this.placeBottom();
    }

    this.tooltipContent.nativeElement.style.left = `${shiftedContentLeft}px`;
  }

  private placeCenterX() {
    const target = this.tooltipTarget.nativeElement;
    const content = this.tooltipContent.nativeElement;
    const docEl = document.documentElement;
    if (target == null || content == null) throw Error("Something went wrong rendering tooltip elements");

    const targetHalfWidth = target.offsetWidth / 2;
    const targetMidX = target.offsetLeft + targetHalfWidth;
    const contentMidX = content.offsetWidth / 2;
    let shiftedContentMidX = targetMidX - contentMidX;

    if (shiftedContentMidX < 0) shiftedContentMidX = 0;
    if (shiftedContentMidX + content.offsetWidth > docEl.offsetWidth)
      shiftedContentMidX = target.offsetLeft - contentMidX;

    this.tooltipContent.nativeElement.style.left = `${shiftedContentMidX}px`;
  }

  private placeRight() {
    const target = this.tooltipTarget.nativeElement;
    const content = this.tooltipContent.nativeElement;
    const docEl = document.documentElement;
    if (target == null || content == null) throw Error("Something went wrong rendering tooltip elements");

    let shiftedContentRight = target.offsetLeft + target.offsetWidth + 16;

    if (shiftedContentRight + content.offsetWidth > docEl.offsetWidth) {
      if (shiftedContentRight + content.offsetWidth / 2 > docEl.offsetWidth) {
        shiftedContentRight = docEl.offsetWidth - content.offsetWidth;
      } else {
        shiftedContentRight = docEl.offsetWidth - content.offsetWidth / 2;
      }
      this.placeBottom();
    }
    if (shiftedContentRight < 0) {
      shiftedContentRight = 0;
      this.placeBottom();
    }

    this.tooltipContent.nativeElement.style.left = `${shiftedContentRight}px`;
  }

  private placeBottom() {
    const target = this.tooltipTarget.nativeElement;
    const content = this.tooltipContent.nativeElement;
    if (target == null || content == null) throw Error("Something went wrong rendering tooltip elements");

    const targetBottomY = target.offsetTop + target.offsetHeight + 16;
    const contentBottomY = content.offsetHeight;
    let shiftedContentBottomY = targetBottomY;

    if (shiftedContentBottomY < 0) shiftedContentBottomY = 0;

    this.tooltipContent.nativeElement.style.top = `${shiftedContentBottomY}px`;
  }

  private placeCenterY() {
    const target = this.tooltipTarget.nativeElement;
    const content = this.tooltipContent.nativeElement;
    const docEl = document.documentElement;
    if (target == null || content == null) throw Error("Something went wrong rendering tooltip elements");

    const targetMidY = target.offsetTop + target.offsetHeight / 2;
    const contentMidY = content.offsetHeight / 2;
    let shiftedContentBottomY = targetMidY - contentMidY;

    if (shiftedContentBottomY < 0) shiftedContentBottomY = 0;
    if (shiftedContentBottomY + content.offsetHeight / 2 > docEl.offsetHeight)
      shiftedContentBottomY = docEl.offsetHeight - content.offsetHeight / 2;

    this.tooltipContent.nativeElement.style.top = `${shiftedContentBottomY}px`;
  }

  private placeTop() {
    const target = this.tooltipTarget.nativeElement;
    const content = this.tooltipContent.nativeElement;
    if (target == null || content == null) throw Error("Something went wrong rendering tooltip elements");

    const targetTopY = target.offsetTop - 16;
    const contentTopY = content.offsetHeight;
    let shiftedContentBottomY = targetTopY - contentTopY;

    if (shiftedContentBottomY < 0) shiftedContentBottomY = 0;

    this.tooltipContent.nativeElement.style.top = `${shiftedContentBottomY}px`;
  }
}

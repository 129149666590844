import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/views/home/home.component";
import { OrientTestPageComponent } from "./components/views/orient-test-page/orient-test-page.component";
import { ResultsPageComponent } from "./components/views/results-page/results-page.component";

export const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "vragen", component: OrientTestPageComponent },
  { path: "resultaten", component: ResultsPageComponent },
  { path: "bekijk-resultaten/:sessionId", component: ResultsPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

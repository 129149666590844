import { Werkniveau } from "../../models/Enums";
import { ISuggestedOccupationalProfile } from "../../models/ISuggestedOccupationalProfile";

export const filterOnKnelpuntberoep = (
  suggestedProfessions: ISuggestedOccupationalProfile[],
  isKnelpuntberoepFilter: boolean,
  isNoKnelpuntberoepFilter: boolean
): ISuggestedOccupationalProfile[] => {
  return suggestedProfessions.filter((suggestedProfession: ISuggestedOccupationalProfile) => {
    // Either both are checked or both are unchecked
    // In this case allow all entries to be shown (filter none)
    if ((isKnelpuntberoepFilter && isNoKnelpuntberoepFilter) || !(isKnelpuntberoepFilter || isNoKnelpuntberoepFilter)) {
      return true;
    }
    if (isKnelpuntberoepFilter) {
      return suggestedProfession.knelpuntBeroep;
    }
    if (isNoKnelpuntberoepFilter) {
      return !suggestedProfession.knelpuntBeroep;
    }
    return false;
  });
};

export const filterOnWerkniveau = (
  suggestedProfessions: ISuggestedOccupationalProfile[],
  werkniveaFilter: Werkniveau[]
): ISuggestedOccupationalProfile[] => {
  return suggestedProfessions.filter((suggestedProfession: ISuggestedOccupationalProfile) => {
    if (werkniveaFilter.length === 0) return suggestedProfession;
    for (const werkniveau of suggestedProfession.werkniveaus) {
      const werkniveauIncludesProfession = werkniveaFilter.includes(werkniveau as Werkniveau);
      if (werkniveauIncludesProfession) {
        return true;
      }
    }
    // console.debug("Filtering out profession: ", suggestedProfession);
    return false;
  });
};

import {Component, OnInit} from "@angular/core";
import { Route, Router } from "@angular/router";

import { routes } from "./app-routing.module";
import {result} from 'lodash';
import {RecaptchaService} from './services/recaptcha.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // Data

  // Lifecycle
  constructor(public router: Router, private recaptchaService: RecaptchaService) {}

  // Interface methods

  // Helper methods

  // Getters & setters

  get isResultsPage() {
    const result = this.router.url.startsWith(
      `/${routes.filter((route: Route) => route.path === "resultaten")[0].path}`
    );
    return result;
  }

  get isHomePage(): boolean {
    return this.router.url.split("?")[0] === "/";
  }

  ngOnInit(): void {
    this.recaptchaService.init()
  }
}

<body>
  <main class="content-frame u-mb3" [class.eu-logo]="isHomePage">
    <!-- The blue ellipse on the background -->
    <div class="background-arc u-pt025">
      <app-vdab-toolbar class="orient-app-container-wrapper"></app-vdab-toolbar>
    </div>
    <div class="o-wrapper u-my0 main-content-container">
      <!-- All the content -->
      <div id="main-content">
        <!-- The toolbar/header -->
        <div id="content-header"></div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</body>

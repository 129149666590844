import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {Observable, ReplaySubject, shareReplay} from "rxjs";
import {map} from "rxjs/operators";
import {e2e} from "../../e2e/dataE2eValues";

@Component({
  selector: "vdab-ai-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
/**
 * Input component:
 *
 * See example in 'examples/input-example.component.html'
 * */
export class InputComponent implements OnInit, OnChanges, AfterViewInit {
  // Data

  @Input() validatorFn?: (input: string | number) => boolean;
  /**
   * Type for input
   * */
  @Input() type: "string" | "int" | "float" = "string";
  /**
   * Precision for float type input
   * */
  @Input() step: number | null = null;
  /**
   * Label for input
   * */
  @Input() label: string = "";
  /**
   * Placeholder for input
   * */
  @Input() placeholder: string = "";
  /**
   * Prefix for input
   * */
  @Input() prefix: string = "";
  /**
   * Affix for input
   * */
  @Input() affix: string = "";
  /**
   * Width of the input.
   * It accepts the same input as the css width property
   * E.g. '350px', '100%', ...
   * Default value is 'auto'
   * */
  @Input() width: string = "auto";
  /**
   * Indicates whether there is an error for the input
   * */
  @Input() error?: boolean;
  /**
   * Error message displayed when there is an error for the input
   * */
  @Input() errorMessage?: string;
  /**
   * Value displayed inside the input
   * */
  @Input() value!: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Whether the input is disabled
   * */
  @Input() disabled!: boolean;

  public id$: ReplaySubject<string> = new ReplaySubject<string>();
  public value$: ReplaySubject<string | number> = new ReplaySubject<string | number>();
  public label$: ReplaySubject<string> = new ReplaySubject<string>();

  public isError$: Observable<boolean>;

  @ViewChild("input") input!: ElementRef;

  // Lifecycle
  constructor(private cdRef: ChangeDetectorRef) {
    this.isError$ = this.value$.pipe(
      map((value: string | number) => this.validatorFn != null && this.value != "" && !this.validatorFn(value)),
      shareReplay(1)
    );
  }

  ngOnInit() {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes["label"]) {
      this.label$.next(changes["label"].currentValue);
    }
    if (changes["value"]) {
      const value = changes["value"].currentValue;
      this.value = value;
      this.nextValue(value);
    }
  }

  ngAfterViewInit(): void {
    this.id$.next(`checkbox-${this.label}`);
    this.nextValue(this.value);
    this.label$.next(this.label);
    this.cdRef.detectChanges();
  }

  // Interface methods

  public emitValueChanged(event: any) {
    const value = event.target.value;
    this.nextValue(value);
    this.valueChange.emit(value);
  }

  public onBlurHandler(event: any) {
    const value = event.target.value;
    this.nextValue(value);
    this.onBlur.emit(value);
  }

  public onEnterHandler(event: KeyboardEvent) {
    if (event.key == "Enter") {
      // @ts-ignore
      const value = event.target.value;
      this.onEnter.emit(value);
    }
    // const value = event.target.value;
    // this.nextValue(value);
    // this.onBlur.emit(value);
  }

  // Getters & setters

  public get inputType(): string {
    if (this.type === "int" || this.type === "float") {
      return "number";
    } else {
      return "text";
    }
  }

  public get inputStep(): number | null {
    if (this.type === "int") {
      return 1;
    } else if (this.type === "float" && this.step != null) {
      return this.step;
    } else {
      return 1;
    }
  }

  public get e2e() {
    return e2e;
  }

  // Helper methods

  private nextValue(value: string | number) {
    if (typeof this.value === "number") {
      value = Number(value);
    } else if (typeof this.value === "string") {
      // Do nothing
    } else {
      throw Error(`Unsupported type for InputComponent: ${value}`);
    }
    this.value$.next(value);
  }
}

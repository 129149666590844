import { Selector } from "@ngxs/store";

import { Loading, Werkniveau } from "../../models/Enums";
import { ISession } from "../../models/ISession";
import { ISuggestedOccupationalProfile } from "../../models/ISuggestedOccupationalProfile";
import { ISuggestedOccupationalProfileFilter } from "../../models/ISuggestedOccupationalProfileFilter";
import { filterOnKnelpuntberoep, filterOnWerkniveau } from "./Result.helpers";
import { IResultState, ResultState } from "./Result.state";

export class ResultSelectors {
  @Selector([ResultState])
  static session(state: IResultState): ISession {
    return state.session;
  }

  @Selector([ResultState])
  static suggestedOccupationalProfiles(state: IResultState): ISuggestedOccupationalProfile[] {
    return state.session.suggestedOccupationalProfiles;
  }

  @Selector([ResultState])
  static werkniveaus(state: IResultState): Werkniveau[] {
    return state.filter.werkniveaus;
  }

  @Selector([ResultState])
  static isKnelpuntBeroep(state: IResultState): boolean {
    return state.filter.isKnelpuntBeroep;
  }

  @Selector([ResultState])
  static isNotKnelpuntBeroep(state: IResultState): boolean {
    return state.filter.isNotKnelpuntBeroep;
  }

  @Selector([ResultSelectors.werkniveaus, ResultSelectors.isKnelpuntBeroep, ResultSelectors.isNotKnelpuntBeroep])
  static filter(
    werkniveaus: Werkniveau[],
    isKnelpuntBeroep: boolean,
    isNotKnelpuntBeroep: boolean
  ): ISuggestedOccupationalProfileFilter {
    return {
      werkniveaus,
      isKnelpuntBeroep,
      isNotKnelpuntBeroep,
    };
  }

  @Selector([ResultState])
  static sessionLoading(state: IResultState): Loading {
    return state.sessionLoading;
  }

  @Selector([ResultSelectors.suggestedOccupationalProfiles, ResultSelectors.filter])
  static filteredSuggestedOccupationalProfiles(
    suggestedOccupationalProfiles: ISuggestedOccupationalProfile[],
    filter: ISuggestedOccupationalProfileFilter
  ): ISuggestedOccupationalProfile[] {
    let filteredSuggestedProfessions: ISuggestedOccupationalProfile[];
    // Filtering on werkniveau
    const actualWerkniveauFilter =
      filter.werkniveaus.length === 0 ? [Werkniveau.W1, Werkniveau.W2, Werkniveau.W3] : filter.werkniveaus;
    filteredSuggestedProfessions = filterOnWerkniveau(suggestedOccupationalProfiles, actualWerkniveauFilter);

    // Filtering on knelpuntberoep
    filteredSuggestedProfessions = filterOnKnelpuntberoep(
      filteredSuggestedProfessions,
      filter.isKnelpuntBeroep,
      filter.isNotKnelpuntBeroep
    );

    return filteredSuggestedProfessions;
  }
}

<span
  #tag
  class="c-tag tag-text"
  [class.-light]="light"
  [class.-neutral]="neutral"
  [class.-success]="success"
  [class.-info]="info"
  [class.-warning]="warning"
  [class.-danger]="danger"
  [class.-yellow]="yellowColor"
  [class.-lightgreen]="lightgreenColor"
  [class.-turquoise]="turquoiseColor"
  [class.-blue]="blueColor"
  [class.-purple]="purpleColor"
  [class.-burgundy]="burgundyColor"
  [class.-grey]="greyColor"
  [class.-black]="blackColor"
  [attr.data-e2e]="e2e.vdabAiTagRoot"
>
  <div [attr.data-e2e]="e2e.vdabAiTagContentContainerElement">
    <ng-content></ng-content>
  </div>
  <span *ngIf="removable" class="mdi mdi-close close-icon u-ml05"
        [style.background-color]="removableIconBackgroundColor"
        [class.hover]="mouseHover"
        (click)="onClick($event)"
        [attr.data-e2e]="e2e.vdabAiTagCloseBtnElement"
  ></span>
  </span>

<div class="send-results-via-mail-container">
  <section class="send-results-via-mail-intro">
    {{ tcs.getText("send_results_modal_title") }}
    <ul>
      <li>{{ tcs.getText("send_results_cta1") }}</li>
      <li>{{ tcs.getText("send_results_cta2") }}</li>
      <li>{{ tcs.getText("send_results_cta3") }}</li>
    </ul>
  </section>
  <div class="send-results-via-mail-form-container u-mt1">
    <div class="form-container -bg-spacing send-results-via-mail-input">
      <form class="basic-form" [formGroup]="form">
        <div class="form-row u-mt0 mail-form-row">
          <vdab-ai-input
            class="u-mt1"
            [label]="tcs.getText('your_email')"
            [placeholder]="tcs.getText('your_email')"
            [value]="form.controls['userEmail'].value"
            (valueChange)="onUserEmailChanged($event)"
            width="100%"
            [error]="userEmailError"
            [errorMessage]="userEmailErrorMsg"
          ></vdab-ai-input>

          <vdab-ai-input
            class="u-mt1"
            [label]="tcs.getText('send_results_optional_attendant_mail')"
            [placeholder]="tcs.getText('send_results_optional_attendant_mail')"
            [value]="form.controls['attendantEmail'].value"
            (valueChange)="onAttendantEmailChanged($event)"
            width="100%"
            [error]="attendantEmailError"
            [errorMessage]="attendantEmailErrorMsg"
          ></vdab-ai-input>

          <button class="c-btn -primary u-mt1" [disabled]="!canSendPdfMail" (click)="sendPdfMail()">
            {{ sendResultsButtonLabel }}
          </button>
          <span class="mail-privacy-disclaimer u-mt1" style="font-size: 0.8rem">
            {{ tcs.getText("send_results_via_mail_privacy_disclaimer") }}
          </span>
        </div>
      </form>
    </div>
  </div>
</div>

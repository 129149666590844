import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: "vdab-ai-accordion",
  templateUrl: "./accordion.component.html",
  styleUrls: ["./accordion.component.scss"],
})
/**
 * Accordion component has 2 content slots:
 * 1. title: Represents the title of the Accordion
 * 2. buttonText: Represents the text of the button to open/close the accordion
 *
 * See example in 'examples/accordion-example.component.html'
 * */
export class AccordionComponent implements OnInit, OnChanges {
  // Data

  /**
   * Property indicating whether accordion is collapsed or not
   * */
  @Input() collapsed: boolean = true;

  /**
   * Emits collapsed status when accordion is toggled
   */
  @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Lifecycle
  constructor(public cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.collapsed)) {
      this.collapsed = true
    }
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["collapsed"]) {
      const collapsed = changes["collapsed"].currentValue
      this.setCollapsedStatus(collapsed)
    }
    this.cdr.detectChanges();
  }

  // Interface methods
  public open() {
    this.setCollapsedStatus(true);
  }

  public close() {
    this.setCollapsedStatus(false);
  }

  toggleCollapse() {
    this.setCollapsedStatus(!this.collapsed)
  }

  private setCollapsedStatus(collapsed: boolean) {
    this.collapsed = collapsed;
    this.collapsedChange.emit(collapsed);
    this.cdr.detectChanges();
  }

  // Getters & setters

  public get e2e() {
    return e2e;
  }

  // Helper methods
}

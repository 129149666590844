import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-angular-frontend-components',
  template: `
    <p>
      angular-components-lib works!
    </p>
  `,
  styles: []
})
export class AngularFrontendComponentsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Session } from '../../models/session';

export interface SendMailApiOrientMailMailPost$Params {
  mail_address: string;
  mail_cc?: (string | null);
  recaptcha_token: string;
      body: Session
}

export function sendMailApiOrientMailMailPost(http: HttpClient, rootUrl: string, params: SendMailApiOrientMailMailPost$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, sendMailApiOrientMailMailPost.PATH, 'post');
  if (params) {
    rb.query('mail_address', params.mail_address, {});
    rb.query('mail_cc', params.mail_cc, {});
    rb.query('recaptcha_token', params.recaptcha_token, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<string>;
    })
  );
}

sendMailApiOrientMailMailPost.PATH = '/api/orient/mail/mail';

import { getTextContent } from "../services/text-content.service";
import { Empty } from "./Empty";
import {AnswerInteractionTypes, isStringInEnum, QuestionTypesEnum, Werkniveau, WerkniveauMapper} from "./Enums";
import { IAnswer } from "./IAnswer";
import {
  IEndQuestionInfo,
  IInterestQuestionInfo,
  INextQuestion,
  IProfessionQuestionInfo,
  IQuestionInfo,
  isEndQuestion,
  isInterestQuestion,
  isProfessionQuestion,
} from "./INextQuestion";
import { INextQuestionSummary } from "./INextQuestionSummary";
import { IRatedAnswers } from "./IRatedAnswers";
import { isDynamoDBSavedSessionType, ISession, isSessionType } from "./ISession";
import { ISuggestedOccupationalProfile } from "./ISuggestedOccupationalProfile";
import {UserInteractionOptions} from '../../api/models/user-interaction-options';
import {SuggestedOccupationalProfile} from '../../api/models/suggested-occupational-profile';
import {Answers} from '../../api/models/answers';
import {Session} from '../../api/models/session';
import {DynamoDbSavedSession} from '../../api/models/dynamo-db-saved-session';
import {NextQuestion} from '../../api/models/next-question';
import {NextQuestionQuestion} from '../../api/models/next-question-question';

// =====================================================================================================================
// To API

export const toApi = {
  RatedQuestions: (ratedQuestions: IRatedAnswers): { [key: string]: UserInteractionOptions } => {
    const mapped: { [key: string]: UserInteractionOptions } = {};
    Object.entries(ratedQuestions.answers).forEach(([key, value]) => {
      if (isStringInEnum(value, UserInteractionOptions)) {
        mapped[key] = value as unknown as UserInteractionOptions;
      } else {
        throw Error(`Value ${value} is not a valid UserInteractionOption`);
      }
    });

    return mapped;
  },
  SuggestedOccupationalProfile: (
    suggestedOccupationalProfile: ISuggestedOccupationalProfile
  ): SuggestedOccupationalProfile => {
    return {
      occupational_profile_id: suggestedOccupationalProfile.id,
      occupational_profile_title: suggestedOccupationalProfile.title,
      occupational_profile_description: suggestedOccupationalProfile.description,
      cdf95_score: suggestedOccupationalProfile.cdf95Score,
      confidence_score: suggestedOccupationalProfile.confidenceScore,
      positive_linked_questions: suggestedOccupationalProfile.posLinkedQuestions,
      negative_linked_questions: suggestedOccupationalProfile.negLinkedQuestions,
      unasked_linked_questions: suggestedOccupationalProfile.unaskedLinkedQuestions,
      knelpuntberoep: suggestedOccupationalProfile.knelpuntBeroep,
      weinig_vacatures: suggestedOccupationalProfile.weinigVacatures,
      liked: suggestedOccupationalProfile.liked,
      werkniveau: suggestedOccupationalProfile.werkniveaus,
    };
  },
  AnswersFromISession: (session: ISession): Answers => {
    return {
      session_id: session.sessionId,
      rated_questions: toApi.RatedQuestions(session.ratedInterestQuestions),
      rated_occupational_profiles: toApi.RatedQuestions(session.ratedOccupationalProfilesQuestions),
    };
  },
  Session: (session: ISession): Session => {
    return {
      session_id: session.sessionId,
      date: session.date.toISOString(),
      rated_questions: toApi.RatedQuestions(session.ratedInterestQuestions),
      rated_occupational_profiles: toApi.RatedQuestions(session.ratedOccupationalProfilesQuestions),
      suggested_occupational_profiles: session.suggestedOccupationalProfiles.map((sp) =>
        toApi.SuggestedOccupationalProfile(sp)
      ),
    };
  },
};

// =====================================================================================================================
// From API

export const fromApi = {
  QuestionTypes: (questionType: string): QuestionTypesEnum => {
    if (!isStringInEnum(questionType, QuestionTypesEnum)) {
      throw Error(`QuestionType ${questionType} is not in enum QuestionTypes`);
    }

    return questionType as QuestionTypesEnum;
  },
  Werkniveau: (werkniveau: string): Werkniveau => {
    if (!Object.keys(WerkniveauMapper).includes(werkniveau)) {
      throw Error(`Werkniveau ${werkniveau} is not in WerkniveauMapper`);
    }

    return WerkniveauMapper[werkniveau];
  },
  IRatedQuestions: (ratedQuestions: { [key: string]: string }): IRatedAnswers => {
    const mapped: IRatedAnswers = {
      answers: {},
    };
    Object.entries(ratedQuestions).forEach(([key, value]) => {
      if (isStringInEnum(value, AnswerInteractionTypes)) {
        mapped.answers[key] = value as AnswerInteractionTypes;
      } else {
        throw Error(`AnswerInteractionType ${value} is not in enum AnswerInteractionTypes`);
      }
    });

    return mapped;
  },
  ISuggestedOccupationalProfiles: (
    suggestedProfession: SuggestedOccupationalProfile
  ): ISuggestedOccupationalProfile => {
    return <ISuggestedOccupationalProfile>{
      id: suggestedProfession.occupational_profile_id,
      title: suggestedProfession.occupational_profile_title,
      description: suggestedProfession.occupational_profile_description,
      cdf95Score: suggestedProfession.cdf95_score,
      confidenceScore: suggestedProfession.confidence_score,
      posLinkedQuestions: suggestedProfession.positive_linked_questions,
      negLinkedQuestions: suggestedProfession.negative_linked_questions,
      unaskedLinkedQuestions: suggestedProfession.unasked_linked_questions,
      knelpuntBeroep: suggestedProfession.knelpuntberoep,
      weinigVacatures: suggestedProfession.weinig_vacatures,
      liked: suggestedProfession.liked,
      werkniveaus: suggestedProfession.werkniveau.map((w) => fromApi.Werkniveau(w)),
    };
  },

  ISession: (session: Session | DynamoDbSavedSession): ISession => {
    if (isSessionType(session)) {
      return {
        date: new Date(session.date),
        sessionId: session.session_id,
        ratedInterestQuestions: fromApi.IRatedQuestions(session.rated_questions),
        ratedOccupationalProfilesQuestions: fromApi.IRatedQuestions(session.rated_occupational_profiles),
        suggestedOccupationalProfiles: session.suggested_occupational_profiles?.map((sp) =>
          fromApi.ISuggestedOccupationalProfiles(sp)
        ),
      };
    } else if (isDynamoDBSavedSessionType(session)) {
      return {
        date: new Date(session.metadata.date),
        sessionId: session.session_id,
        ratedInterestQuestions: fromApi.IRatedQuestions(session.rated_questions),
        ratedOccupationalProfilesQuestions: fromApi.IRatedQuestions(session.rated_occupational_profiles),
        suggestedOccupationalProfiles: session.suggested_occupational_profiles?.map((sp) =>
          fromApi.ISuggestedOccupationalProfiles(sp)
        ),
      };
    } else {
      throw Error(`Unknown session: ${JSON.stringify(session)}`);
    }
  },
  INextQuestion: (nextQuestion: NextQuestion): INextQuestion => {
    const questionType: QuestionTypesEnum = fromApi.QuestionTypes(nextQuestion.action_type);
    let actionObject: IQuestionInfo;
    if (nextQuestion.action_type === QuestionTypesEnum.InterestQuestion) {
      if ('question_id' in nextQuestion.action_object) {
        actionObject = {
          type: questionType,
          questionId: nextQuestion.action_object.question_id,
          questionString: nextQuestion.action_object.short_description,
          questionDescription: nextQuestion.action_object.long_description,
        } as IInterestQuestionInfo;
      }
    } else if (nextQuestion.action_type === QuestionTypesEnum.ProfessionQuestion) {
      if ('occupational_profile_id' in nextQuestion.action_object) {
        actionObject = {
          type: questionType,
          occupationalProfileId: nextQuestion.action_object.occupational_profile_id,
          occupationalProfileTitle: nextQuestion.action_object.occupational_profile_title,
          occupationalProfileDescription: nextQuestion.action_object.occupational_profile_description,
        } as IProfessionQuestionInfo;
      }
    } else {
      actionObject = {
        type: questionType,
        // DO NOT TOUCH THIS
        // Workaround for limitations of OPENAPI generator:
        // It doesn't correctly convert the action_object to the correct type
        // @ts-ignore
        suggestedOccupationalProfiles: (nextQuestion.action_object as SuggestedOccupationalProfile[]).map(
          (sp: SuggestedOccupationalProfile) => fromApi.ISuggestedOccupationalProfiles(sp)
        ),
      } as IEndQuestionInfo;
    }
    return <INextQuestion>{
    // @ts-ignore
      questionInfo: actionObject,
      informationGainProgress: nextQuestion.information_gain_progress,
    };
  },
};

// =====================================================================================================================
// Helpers

/*
 * Converts internal interfaces
 */
export const convert = {
  INextQuestion: {
    ICurrentQuestion: (nextQuestion: INextQuestion): INextQuestionSummary => {
      if (isInterestQuestion(nextQuestion)) {
        return {
          id: nextQuestion.questionInfo.questionId,
          question: nextQuestion.questionInfo.questionString,
          description: nextQuestion.questionInfo.questionDescription,
          questionType: QuestionTypesEnum.InterestQuestion,
        };
      } else if (isProfessionQuestion(nextQuestion)) {
        return {
          id: nextQuestion.questionInfo.occupationalProfileId,
          question: `${getTextContent("the_job")} ${
            nextQuestion.questionInfo.occupationalProfileTitle
          } ${getTextContent("interesses_me")}}`,
          description: nextQuestion.questionInfo.occupationalProfileDescription,
          questionType: QuestionTypesEnum.ProfessionQuestion,
        };
      } else if (isEndQuestion(nextQuestion)) {
        return Empty.nextQuestionSummary(QuestionTypesEnum.End);
      } else {
        return Empty.nextQuestionSummary();
      }
    },
    rawICurrentQuestion: (nextQuestion: INextQuestion): INextQuestionSummary => {
      if (isInterestQuestion(nextQuestion)) {
        return {
          id: nextQuestion.questionInfo.questionId,
          question: nextQuestion.questionInfo.questionString,
          description: nextQuestion.questionInfo.questionDescription,
          questionType: QuestionTypesEnum.InterestQuestion,
        };
      } else if (isProfessionQuestion(nextQuestion)) {
        return {
          id: nextQuestion.questionInfo.occupationalProfileId,
          question: nextQuestion.questionInfo.occupationalProfileTitle,
          description: nextQuestion.questionInfo.occupationalProfileDescription,
          questionType: QuestionTypesEnum.ProfessionQuestion,
        };
      } else if (isEndQuestion(nextQuestion)) {
        return Empty.nextQuestionSummary(QuestionTypesEnum.End);
      } else {
        return Empty.nextQuestionSummary();
      }
    },
  },
  IAnswer: {
    IRatedQuestions: (answers: IAnswer[]): IRatedAnswers => {
      return answers.reduce((acc: IRatedAnswers, answer: IAnswer) => {
        acc.answers[answer.currentQuestion.id] = answer.interactionType;
        return acc;
      }, Empty.ratedQuestions());
    },
  },
};

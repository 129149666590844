<button
  class="c-btn"
  [class.-primary]="isDefault"
  [class.-secundary]="secundary"
  [class.-tertiary]="tertiary"
  [class.-ghost]="ghost"
  [class.-light]="light"
  [class.-warning]="warning"
  [class.-danger]="danger"
  [class.disabled]="disabled"
  [attr.data-e2e]="e2e.vdabAiButtonRoot"
>
  <ng-content></ng-content>
</button>

import { Werkniveau } from "../../models/Enums";
import { IRatedAnswers } from "../../models/IRatedAnswers";
import { ISuggestedOccupationalProfile } from "../../models/ISuggestedOccupationalProfile";

export class StartSession {
  static readonly type = "[OrientTestPage] - New session";
}

export class SetInterestQuestions {
  static readonly type = "[OrientTestPage] - Set interest questions";

  constructor(public interestQuestions: IRatedAnswers) {}
}

export class SetOccupationalProfileQuestions {
  static readonly type = "[OrientTestPage] - Set occupational profile questions";

  constructor(public occupationalProfileQuestions: IRatedAnswers) {}
}

export class SetSuggestedOccupationalProfilesAction {
  static readonly type = "[OrientResultPage] - Set suggested occupational profiles";

  constructor(public suggestedOccupationalProfiles: ISuggestedOccupationalProfile[]) {}
}

export class LoadSessionAction {
  static readonly type = "[OrientResultPage] - Load session";

  constructor(public sessionId: string) {}
}

export class SetWerkniveauFilterAction {
  static readonly type = "[OrientResultPage] - Set werkniveau filter";

  constructor(public werkniveau: Werkniveau, public enable: boolean) {}
}

export class SetIsKnelpuntBeroepAction {
  static readonly type = "[OrientResultPage] - Set isKnelpuntBeroep filter";

  constructor(public isKnelpuntBeroep: boolean) {}
}

export class SetIsNotKnelpuntBeroepAction {
  static readonly type = "[OrientResultPage] - Set isNotKnelpuntBeroep filter";

  constructor(public isNotKnelpuntBeroep: boolean) {}
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {IDropdownItem} from "../../models/models";
import {ITextContent, TextContentService} from "../../services/text-content.service";
import {Observable, ReplaySubject, scan, shareReplay, startWith, Subject, Subscription, takeUntil} from "rxjs";
import {map, withLatestFrom} from "rxjs/operators";
import {e2e} from '../../e2e/dataE2eValues';

@Component({
  selector: 'vdab-ai-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent<T> implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  // Data

  /**
   * [Required] Items for dropdown with the IDropdownItem interface.
   * */
  @Input() items!: IDropdownItem<T>[];
  /**
   * [Optional] Label for the dropdown
   * */
  @Input() label: string = "";
  /**
   * [Required] Selected value for the dropdown.
   * An unselected choice is represented by null
   * */
  @Input() value!: T | null;
  @Output() valueChange: EventEmitter<T | null> = new EventEmitter<T | null>();

  items$: Observable<IDropdownItem<T | null>[]>;
  addItems$: ReplaySubject<IDropdownItem<T | null>[]> = new ReplaySubject<IDropdownItem<T | null>[]>();
  removeItems$: ReplaySubject<IDropdownItem<T | null>[]> = new ReplaySubject<IDropdownItem<T | null>[]>();
  public onSelectChanged$: ReplaySubject<string> = new ReplaySubject<string>();

  public afterViewInit$: Subject<void> = new Subject<void>();
  public destroy$: Subject<void> = new Subject<void>();

  // Lifecycle

  constructor(public textContentService: TextContentService) {
    this.items$ = this.createItemsHandler();
    this.addItems$.next([])
    this.createOnSelectChangedSubscriptionHandler();
  }

  ngOnInit(): void {
    this.addItems$.next(this.items)
  }


  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next()
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  // Interface methods

  public onSelectChanged(selected: any) {
    const label = selected.target.value;
    this.onSelectChanged$.next(label)
  }


  // Getters & setters

  public get e2e() {
    return e2e;
  }

  // Helper methods

  private createItemsHandler(): Observable<IDropdownItem<T | null>[]> {
    return this.addItems$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.textContentService.textContent$),
      map(([itemsToAdd, textContent]: [IDropdownItem<T | null>[], ITextContent]) => (items: IDropdownItem<T | null>[]): IDropdownItem<T | null>[] => {
          const emptyItem: IDropdownItem<T | null> = {label: textContent.dropdown_select, value: null}
          if (items.length <= 0 || !items.map(item => item.label).includes(textContent.dropdown_select)) {
            items = [emptyItem, ...items];
          }
          return [...items, ...itemsToAdd];
        }
      ),
      scan((items: IDropdownItem<T | null>[], updateFn: (items: IDropdownItem<T | null>[]) => IDropdownItem<T | null>[]) => updateFn(items), []),
      startWith([]),
      shareReplay(1)
    )
  }

  private createOnSelectChangedSubscriptionHandler(): Subscription {
    return this.onSelectChanged$
      .pipe(withLatestFrom(this.items$))
      .subscribe(([selectedLabel, items]: [string, IDropdownItem<T | null>[]]) => {
        const item = items.find(item => {
          return item.label === selectedLabel;
        });
        if (item == null) {
          throw Error(`Item with label '${selectedLabel}' is not provided with the items-property.`)
        }
        this.valueChange.emit(item.value)
      })
  }

}

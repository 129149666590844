import { Component } from "@angular/core";
import { fromEvent, mergeWith, Observable, shareReplay, startWith, Subject, takeUntil } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { IEnvironment } from "../../../../environments/IEnvironment";
import { StyleService } from "../../../services/style.service";
import { TextContentService } from "../../../services/text-content.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  // Data

  public isDesktopViewport$: Observable<boolean>;
  public afterViewInit$: Subject<void> = new Subject<void>();
  public destroy$: Subject<void> = new Subject<void>();

  // Lifecycle

  constructor(public tcs: TextContentService, public styleService: StyleService) {
    this.isDesktopViewport$ = this.createIsDesktopViewportHandler();
  }

  // Interface methods
  // Getters & setters

  public get environment(): IEnvironment {
    return environment;
  }

  // Helper methods

  private createIsDesktopViewportHandler(): Observable<boolean> {
    const checkViewport = () => {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      return vw >= this.styleService.styles.medium_screen_bp;
    };
    const checkViewportOnCreate = this.afterViewInit$.pipe(map(() => checkViewport()));
    return fromEvent(window, "resize").pipe(
      takeUntil(this.destroy$),
      map((event: Event) => checkViewport()),
      mergeWith(checkViewportOnCreate),
      startWith(checkViewport()),
      shareReplay(1)
    );
  }
}

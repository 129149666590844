import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from "@angular/core";
import {ITextContent, TextContentService} from "../../services/text-content.service";
import {mergeWith, Observable, ReplaySubject, shareReplay, startWith} from "rxjs";
import {map, withLatestFrom} from "rxjs/operators";
import {AccordionComponent} from "../accordion/accordion.component";
import {e2e} from "../../e2e/dataE2eValues";
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: "vdab-ai-accordion-expander",
  templateUrl: "./accordion-expander.component.html",
  styleUrls: ["./accordion-expander.component.scss"],
})
/**
 * Accordion Expander component has a single slot, containing one or more vdab-ai-accordion components.
 * The expander is able to open and close all the child vdab-ai-accordion components
 *
 * See example in 'examples/accordion-example.component.html'
 * */
export class AccordionExpanderComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit {
  // Data

  /**
   * Property indicating whether accordion is collapsed or not
   * */
  @Input() collapsed: boolean = true;

  /**
   * Emits collapsed status when accordion is toggled
   */
  @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Property indicating whether the child vdab-ai-accordion components are all open or closed together
   * */
  public actionLabel$: Observable<string>;
  /**
   * Property indicating whether accordion is collapsed or not
   * */
  public collapsed$: Observable<boolean>;
  private collapsedWhenToggled$: Observable<boolean>;
  public collapsedWhenPropertyChanged$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  /**
   * Property indicating whether action has been triggered by the user
   * */
  public toggleCollapseTrigger$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  /**
   * List of all child vdab-ai-accordion components that are nested inside the vdab-ai-accordion-expander container
   * */
  @ContentChildren(AccordionComponent) accordions!: QueryList<AccordionComponent>;

  // Lifecycle

  constructor(public textContentService: TextContentService, public cdr: ChangeDetectorRef) {
    if (isNullOrUndefined(this.collapsed)) {
      this.collapsed = true;
    }

    // Observables


    this.collapsedWhenToggled$ = this.createCollapsedHandler();
    this.collapsed$ = this.collapsedWhenToggled$.pipe(mergeWith(this.collapsedWhenPropertyChanged$));

    this.actionLabel$ = this.collapsed$.pipe(
      withLatestFrom(this.textContentService.textContent$),
      map(([collapsed, textContent]: [boolean, ITextContent]) => {
        return collapsed ? textContent.expand_all : textContent.collapse_all;
      }),
      startWith(""),
      shareReplay(1)
    );

    // Initialise action clicked
    this.toggleCollapseTrigger$.next(!this.collapsed);

    // Subscriptions
    this.collapsedWhenToggled$.subscribe((collapsed: boolean) => {
      this.collapsed = collapsed;
      this.collapsedChange.emit(collapsed)
    });

  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.collapsed)) {
      this.collapsed = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["collapsed"]) {
      const collapsed = changes["collapsed"].currentValue;
      this.collapsedWhenPropertyChanged$.next(collapsed)
    }
    this.cdr.detectChanges();
  }

  // contentChildren are set
  ngAfterContentInit() {
    // Change collapsed status for all vdab-ai-accordion components when collapsed$ has changed
    this.collapsed$.subscribe((collapsed: boolean) => {
      this.accordions.toArray().forEach((accordion) => (collapsed ? accordion.open() : accordion.close()));
    });
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  // Interface methods

  public toggleAccordions() {
    this.toggleCollapseTrigger$.next(!this.collapsed);
  }

  // Getters & setters

  public get e2e() {
    return e2e;
  }

  // Helper methods

  private createCollapsedHandler(): Observable<boolean> {
    return this.toggleCollapseTrigger$.asObservable();
  }
}

<div class="vdab-ai-tabs c-sub-navbar u-my1" [attr.data-e2e]="e2e.vdabAiTabsRoot">
  <nav id="sub-navbar-menu" class="c-sub-navbar__menu" aria-label="Sub-navigatiemenu">
    <ul class="c-sub-navbar__menu-list" [attr.data-e2e]="e2e.vdabAiTabsTabContainerElement">
      <li
        *ngFor="let tab of tabs"
        (click)="selectTab(tab)"
        class="c-sub-navbar__menu-item"
        [class.-active]="tab.active"
        aria-current="true"
        [attr.data-e2e]="e2e.vdabAiTabsTabHeaderElement+ '_' + tab.title"
      >
        <a class="c-sub-navbar__menu-link u-text-decoration-none"
           [class.-active]="tab.active">{{tab.title}}</a>
      </li>
    </ul>
  </nav>
</div>
<ng-content></ng-content>

import { IAnswer } from "../../models/IAnswer";
import { ISession } from "../../models/ISession";

export class AnswerQuestionAction {
  static readonly type = "[OrientTestPage] - Add answer";

  constructor(public answer: IAnswer) {}
}

export class LoadNextQuestionInfoAction {
  static readonly type = "[OrientTestPage] - Load next question info";

  constructor(public session: ISession) {}
}

export class SetShowDescriptionAction {
  static readonly type = "[OrientTestPage] - Set show description";

  constructor(public toggle: boolean) {}
}

export class LoadNextQuestionImgAction {
  static readonly type = "[OrientTestPage] - Load next question img";

  constructor() {}
}

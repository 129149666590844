<div>
  <div class="c-search-filter desktop-filter">
    <div class="title-container u-mb15">
      <span class="u-display-block vdab-text-color-grey">{{ filtersLabel }}</span>
    </div>
    <div class="c-search-filter__container">
      <app-result-page-filter-helper #desktopFilter></app-result-page-filter-helper>
    </div>
  </div>
</div>

<vdab-ai-modal
  [open]="showMobileFilterModal"
  (openChange)="changeModalStatus($event)"
  [backgroundColor]="styleService.styles.vdab_light_blue"
  [fullScreen]="true"
>
  <h2 title class="u-mb0">{{ filtersLabel }}</h2>
  <div body>
    <app-result-page-filter-helper
      #mobileFilter
      (closeModal)="onCloseModalFromFilterHelper()"
    ></app-result-page-filter-helper>
  </div>
</vdab-ai-modal>

import { AnswerInteractionTypes, QuestionTypesEnum } from "./Enums";
import { IAnswer } from "./IAnswer";
import { INextQuestion, IQuestionInfo } from "./INextQuestion";
import { INextQuestionSummary } from "./INextQuestionSummary";
import { IRatedAnswers } from "./IRatedAnswers";
import { ISession } from "./ISession";
import { ISuggestedOccupationalProfile } from "./ISuggestedOccupationalProfile";
import { ISuggestedOccupationalProfileFilter } from "./ISuggestedOccupationalProfileFilter";

export class Empty {
  public static ratedQuestions(): IRatedAnswers {
    return { answers: {} };
  }

  public static nextQuestionSummary(questionType: QuestionTypesEnum = QuestionTypesEnum.Start): INextQuestionSummary {
    return {
      id: "",
      question: "",
      description: "",
      questionType: questionType,
    };
  }

  public static actionObject(): IQuestionInfo {
    return { type: QuestionTypesEnum.Start };
  }

  public static nextQuestion(): INextQuestion {
    return <INextQuestion>{
      questionInfo: Empty.actionObject(),
      informationGainProgress: 0,
    };
  }

  public static answer(): IAnswer {
    return {
      currentQuestion: Empty.nextQuestionSummary(),
      interactionType: AnswerInteractionTypes.Undefined,
    };
  }

  public static suggestedOccupationalProfile(): ISuggestedOccupationalProfile {
    return {
      id: "",
      title: "",
      description: "",
      cdf95Score: 0,
      confidenceScore: 0,
      posLinkedQuestions: {},
      negLinkedQuestions: {},
      unaskedLinkedQuestions: {},
      knelpuntBeroep: false,
      weinigVacatures: false,
      liked: false,
      werkniveaus: [],
    };
  }

  public static session(): ISession {
    return {
      date: new Date(),
      sessionId: "",
      ratedInterestQuestions: Empty.ratedQuestions(),
      ratedOccupationalProfilesQuestions: Empty.ratedQuestions(),
      suggestedOccupationalProfiles: [],
    };
  }

  public static suggestedOccupationalProfileFilter(): ISuggestedOccupationalProfileFilter {
    return {
      werkniveaus: [],
      isKnelpuntBeroep: false,
      isNotKnelpuntBeroep: false,
    };
  }
}

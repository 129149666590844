// =====================================================================================================================
// General

export const isStringInEnum = <T>(value: string, enumObj: T): boolean => {
  return Object.values(enumObj).includes(value);
};

// =====================================================================================================================
// Loading

export enum LoadingEnum {
  Loading = "loading",
  Success = "success",
  Error = "error",
}

export type Loading = `${LoadingEnum}`;

export const isLoading = (status: Loading) => {
  return status === LoadingEnum.Loading;
};

export const isSuccess = (status: Loading) => {
  return status === LoadingEnum.Success;
};

export const isError = (status: Loading) => {
  return status === LoadingEnum.Error;
};

// =====================================================================================================================
// AnswerInteractionTypes

export enum AnswerInteractionTypes {
  "Undefined" = "undefined",
  "Start" = "start",
  "Like" = "like",
  "Dislike" = "dislike",
  "Back" = "back",
  "Message" = "message",
}

export function isBackAnswerInteractionType(interactionType: AnswerInteractionTypes | null | undefined): boolean {
  return interactionType === AnswerInteractionTypes.Back;
}

export function isMessageAnswerInteractionType(interactionType: AnswerInteractionTypes | null | undefined): boolean {
  return interactionType === AnswerInteractionTypes.Message;
}

export function isStartAnswerInteractionType(interactionType: AnswerInteractionTypes | null | undefined): boolean {
  return interactionType === AnswerInteractionTypes.Start;
}

export const isLikeAnswerInteractionType = (interactionType: AnswerInteractionTypes | null | undefined): boolean => {
  return interactionType === AnswerInteractionTypes.Like;
};

export const isDislikeAnswerInteractionType = (interactionType: AnswerInteractionTypes | null | undefined): boolean => {
  return interactionType === AnswerInteractionTypes.Dislike;
};

export function isMoveForwardKindAnswerInteractionType(
  interactionType: AnswerInteractionTypes | null | undefined
): boolean {
  return (
    isStartAnswerInteractionType(interactionType) ||
    isLikeAnswerInteractionType(interactionType) ||
    isDislikeAnswerInteractionType(interactionType) ||
    isMessageAnswerInteractionType(interactionType)
  );
}

export function isUndefinedAnswerInteractionType(interactionType: AnswerInteractionTypes | null | undefined): boolean {
  return interactionType === AnswerInteractionTypes.Undefined;
}

// =====================================================================================================================
// ActionTypes

export enum QuestionTypesEnum {
  Start = "initial_load",
  InterestQuestion = "question",
  ProfessionQuestion = "occupational profile",
  ProfessionWarning = "professionWarning",
  End = "end",
}

export function isStartQuestionType(actionType: QuestionTypesEnum | undefined): boolean {
  return actionType === QuestionTypesEnum.Start;
}

export function isEndQuestionType(actionType: QuestionTypesEnum | undefined): boolean {
  return actionType === QuestionTypesEnum.End;
}

export function isInterestQuestionType(actionType: QuestionTypesEnum | undefined): boolean {
  return actionType === QuestionTypesEnum.InterestQuestion;
}

export function isProfessionQuestionType(actionType: QuestionTypesEnum | undefined): boolean {
  return actionType === QuestionTypesEnum.ProfessionQuestion;
}

export function isProfessionWarningQuestionType(actionType: QuestionTypesEnum | undefined): boolean {
  return actionType === QuestionTypesEnum.ProfessionWarning;
}

// =====================================================================================================================
// Werkniveau

export enum Werkniveau {
  W1 = "work_level_1",
  W2 = "work_level_2",
  W3 = "work_level_3",
  ManualReview = "Manual review",
}

export const WerkniveauMapper: Record<string, Werkniveau> = {
  'W1': Werkniveau.W1,
  'work_level_1': Werkniveau.W1,
  'W2': Werkniveau.W2,
  'work_level_2': Werkniveau.W2,
  'W3': Werkniveau.W3,
  'work_level_3': Werkniveau.W3,
  'Manual review': Werkniveau.ManualReview
}

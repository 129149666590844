export interface IStyleConstants {
  small_screen_bp: number;
  medium_screen_bp: number;
  large_screen_bp: number;

  vdab_blue_300: string;
  vdab_blue_400: string;
  vdab_blue_500: string;
  vdab_blue_100: string;
  vdab_indigo_900: string;

  vdab_grey_100: string;
  vdab_grey_300: string;
  vdab_grey_600: string;
  vdab_grey: string;
  vdab_grey_primary_meta: string;
  vdab_blue_primary: string;
  vdab_blue_primary_heading: string;
  vdab_green: string;
  vdab_orange: string;
  vdab_light_blue: string;
  vdab_white: string;

  vdab_turqoise_500: string;
  vdab_turqoise_700: string;
  vdab_turqoise_900: string;

  vdab_success_light: string;
  vdab_success: string;

  vdab_error_light: string;
  vdab_error: string;
}

export const StyleConstants: IStyleConstants = {
  small_screen_bp: 600,
  medium_screen_bp: 960,
  large_screen_bp: 1370,

  vdab_blue_300: "#C4DEF0",
  vdab_blue_400: "#82BFFF",
  vdab_blue_500: "#2d89cc",
  vdab_blue_100: "#eff7fe",
  vdab_indigo_900: "#041847",

  vdab_grey_100: "#f8f7f8",
  vdab_grey_300: "#e3e5e7",
  vdab_grey_600: "#aeb0b6",
  vdab_grey: "#9198A1",
  vdab_grey_primary_meta: "#6A707B",
  vdab_blue_primary: "#1769A4",
  vdab_blue_primary_heading: "#102759",
  vdab_green: "#67c721",
  vdab_orange: "#ff9d00",
  vdab_light_blue: "#eff7fe",
  vdab_white: "#ffffff",

  vdab_turqoise_500: "#49B4AA",
  vdab_turqoise_700: "#33827A",
  vdab_turqoise_900: "#004747",

  vdab_success_light: "#ebf5dd",
  vdab_success: "#1d8229",

  vdab_error_light: "#ebf5dd",
  vdab_error: "#1d8229",
};

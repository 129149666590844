import { ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { debounceTime, Observable, shareReplay, startWith, Subject, takeUntil } from "rxjs";

import { environment } from "../../../../environments/environment";
import { HttpService } from "../../../services/http.service";
import { TextContentService } from "../../../services/text-content.service";
import { QuestionFacade } from "../../../state/Question/Question.facade";

@Component({
  selector: "app-question-card-image",
  templateUrl: "./question-card-image.component.html",
  styleUrls: ["./question-card-image.component.scss"],
})
export class QuestionCardImageComponent implements OnDestroy {
  // Data

  @ViewChild("cardImg") cardImg!: ElementRef<HTMLInputElement>;

  // public rawImg$: Observable<WithStatus<Blob>>;
  public imgUrl$: Observable<string>;
  public loading$: Observable<boolean>;

  public destroy$: Subject<void> = new Subject<void>();

  constructor(
    public tcs: TextContentService,
    public questionFacade: QuestionFacade,
    public httpService: HttpService,
    private cdr: ChangeDetectorRef
  ) {
    this.imgUrl$ = this.questionFacade.nextQuestionImgUrl$;
    this.loading$ = this.createLoadingDataHandler();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  // Helper methods

  private createLoadingDataHandler(): Observable<boolean> {
    return this.questionFacade.loading$.pipe(
      takeUntil(this.destroy$),
      debounceTime(environment.LOADING_DELAY),
      startWith(false),
      shareReplay(1)
    );
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ITextContent, TextContentService} from "../../services/text-content.service";
import {IDropdownItem} from "../../models/models";
import {
  combineLatest,
  debounceTime,
  mergeWith,
  Observable,
  ReplaySubject,
  scan,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  takeUntil
} from "rxjs";
import {map, withLatestFrom} from "rxjs/operators";
import {e2e} from '../../e2e/dataE2eValues';

@Component({
  selector: 'vdab-ai-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T> implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  // Data

  /**
   * [Required] Items for dropdown with the IDropdownItem interface.
   * */
  @Input() items!: IDropdownItem<T>[];
  /**
   * [Optional] Label for the dropdown
   * */
  @Input() label: string = "";
  /**
   * [Optional] Placeholder for the dropdown
   * */
  @Input() placeholder: string = "";
  /**
   * [Required] Selected value for the dropdown.
   * An unselected choice is represented by null
   * */
    // @Input() value!: T | null;
  @Output() valueChange: EventEmitter<T | null> = new EventEmitter<T | null>();
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();

  public items$: Observable<IDropdownItem<T | null>[]>;
  public addItems$: ReplaySubject<IDropdownItem<T | null>[]> = new ReplaySubject<IDropdownItem<T | null>[]>();
  public removeItems$: ReplaySubject<IDropdownItem<T | null>[]> = new ReplaySubject<IDropdownItem<T | null>[]>();
  public replaceItems$: ReplaySubject<IDropdownItem<T | null>[]> = new ReplaySubject<IDropdownItem<T | null>[]>();

  public selectedLabel$: Observable<string>;
  public rawSelectedLabel$: Observable<string>;

  public onSelectChanged$: ReplaySubject<string> = new ReplaySubject<string>();

  public showOptions$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public selectOptionsVisibility$: Observable<string>;

  public arrowKeySelectedLabelIndex$: Observable<number>;
  public arrowKeySelectedLabel$: Observable<string>;
  public arrowKeyTrigger$: ReplaySubject<"UP" | "DOWN" | "ENTER" | "ESCAPE"> = new ReplaySubject<"UP" | "DOWN" | "ENTER" | "ESCAPE">();
  public arrowKeySelectLabelOnEnter$: ReplaySubject<void> = new ReplaySubject<void>();
  public clearSelectedLabel$: ReplaySubject<void> = new ReplaySubject<void>();

  public afterViewInit$: Subject<void> = new Subject<void>();
  public destroy$: Subject<void> = new Subject<void>();

  @ViewChild("dropdown") dropdown!: ElementRef;
  @ViewChild("selectOptions") selectOptions!: ElementRef;
  @ViewChild("dropdownParent") dropdownParent!: ElementRef;

  // Lifecycle

  constructor(public textContentService: TextContentService, private cdr: ChangeDetectorRef) {
    this.items$ = this.createItemsHandler();

    this.addItems$.next([])

    this.arrowKeySelectedLabelIndex$ = this.createArrowKeySelectedLabelIndexHandler();
    this.arrowKeySelectedLabel$ = this.createArrowKeySelectedLabelHandler();
    this.rawSelectedLabel$ = this.createRawSelectedLabelHandler();
    this.selectedLabel$ = this.createSelectedLabelHandler();

    this.selectOptionsVisibility$ = this.createSelectOptionsVisibility();

    // Subscriptions
    this.createOnSelectedLabelChangedSubscriptionHandler();
    this.clearSelectedLabel$.subscribe(v => {
    })
    this.createArrowKeySelectedLabelOnEnterSubscriptionHandler();
    // this.createArrowKeySelectedLabelSubscriptionHandler();

    this.arrowKeyTrigger$.next("ENTER")

  }

  ngOnInit(): void {
    this.addItems$.next(this.items)
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      const value: T | null = changes["value"].currentValue;
      // this.valueChangedTrigger$.next(value)
    }
    if (changes["items"]) {
      const items: IDropdownItem<T | null>[] = changes["items"].currentValue;
      this.replaceItems$.next(items)
    }
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next()
    this.createDropdownFocusEventListener();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  // Interface methods

  public onSelectChanged(selectedLabel: string) {
    this.onSelectChanged$.next(selectedLabel)
    this.showOptions$.next(false)
    this.dropdown?.nativeElement.focus();

  }

  public onInputClicked() {
    this.showOptions$.next(true)
  }

  public onInputChanged(event: KeyboardEvent) {
    this.showOptions$.next(true)
    if (event.key == "ArrowUp") {
      this.arrowKeyTrigger$.next("UP")
    } else if (event.key == "ArrowDown") {
      this.arrowKeyTrigger$.next("DOWN")
    } else if (event.key == "Enter") {
      this.arrowKeySelectLabelOnEnter$.next()
    } else if (event.key == "Escape") {
      this.showOptions$.next(false)
      this.arrowKeyTrigger$.next("ESCAPE")
    } else {
      // @ts-ignore
      const value = event.target.value;
      this.inputChange.emit(value)
    }
  }


  // Getters & setters

  public get selectOptionsTop(): string {
    return `${this.dropdown?.nativeElement.offsetTop + this.dropdown?.nativeElement.offsetHeight}px`;
  }

  public get selectOptionsWidth(): string {
    return `${this.dropdown?.nativeElement.offsetWidth}px`;
  }

  public get e2e() {
    return e2e;
  }


  // Helper methods

  private createItemsHandler(): Observable<IDropdownItem<T | null>[]> {
    const itemsToAdd: Observable<IDropdownItem<T | null>[]> = this.addItems$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.textContentService.textContent$),
      map(([itemsToAdd, textContent]: [IDropdownItem<T | null>[], ITextContent]) => (items: IDropdownItem<T | null>[]): IDropdownItem<T | null>[] => {
          return [...items, ...itemsToAdd];
        }
      ),
      scan((items: IDropdownItem<T | null>[], updateFn: (items: IDropdownItem<T | null>[]) => IDropdownItem<T | null>[]) => updateFn(items), []),
    )

    return itemsToAdd.pipe(
      mergeWith(this.replaceItems$),
      startWith([]),
      shareReplay(1)
    )
  }

  private createSelectOptionsVisibility(): Observable<string> {
    return combineLatest(this.showOptions$, this.items$).pipe(
      map(([showOptions, items]: [boolean, IDropdownItem<T | null>[]]) => showOptions && items.length > 0 ? 'visible' : 'hidden'),
      startWith('hidden'),
      shareReplay(1)
    );
  }

  private createDropdownFocusEventListener() {
    const dropdownFocusInHandler = (event: FocusEvent) => {
    };
    const dropdownFocusOutHandler = (event: FocusEvent) => {
      if (this.dropdownParent?.nativeElement.contains(event.relatedTarget)) return;

      this.showOptions$.next(false)
      return
    };
    this.dropdownParent.nativeElement.addEventListener("focusin", dropdownFocusInHandler);
    this.dropdownParent.nativeElement.addEventListener("focusout", dropdownFocusOutHandler);

    this.showOptions$.next(false)
  }

  private createArrowKeySelectedLabelIndexHandler(): Observable<number> {
    return this.arrowKeyTrigger$.pipe(
      withLatestFrom(combineLatest([this.items$, this.showOptions$])),
      map(([direction, [items, showOptions]]: [string, [IDropdownItem<T | null>[], boolean]]) => (index: number): number => {
        if (direction == "UP") {
          index -= 1;
          if (index < 0) index = items.length - 1;
        } else if (direction == "DOWN") {
          if (showOptions) {
            index += 1;
          }
        } else if (direction == "ESCAPE") {
          index = 0;
        }

        return index % items.length;
      }),
      scan((index: number, updateFn) => updateFn(index), 0),
      startWith(0),
      shareReplay(1)
    );
  }

  private createArrowKeySelectedLabelHandler(): Observable<string> {
    return combineLatest(this.arrowKeySelectedLabelIndex$, this.items$).pipe(
      map(([index, items]: [number, IDropdownItem<T | null>[]]) => {
        if (items.length == 0) return "";
        return items[index].label
      }),
      startWith(""),
      shareReplay(1)
    )
  }

  private createRawSelectedLabelHandler(): Observable<string> {
    const arrowKeySelected = this.arrowKeySelectLabelOnEnter$.pipe(
      withLatestFrom(combineLatest([this.arrowKeySelectedLabel$])),
      map(([, [arrowKeySelectedLabel]]: [void, [string]]) => {
        return arrowKeySelectedLabel
      })
    )

    return this.onSelectChanged$.pipe(
      mergeWith(arrowKeySelected),
      shareReplay(1)
    )
  }

  private createSelectedLabelHandler(): Observable<string> {
    const clear = this.clearSelectedLabel$.pipe(debounceTime(0), map(() => ""));
    return clear.pipe(
      mergeWith(this.rawSelectedLabel$),
      startWith(""),
    )
  }

  private createOnSelectedLabelChangedSubscriptionHandler(): Subscription {
    return this.rawSelectedLabel$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.items$))
      .subscribe(([selectedLabel, items]: [string, IDropdownItem<T | null>[]]) => {
        if (items.length == 0) {
          return
        }
        const item = items.find(item => item.label === selectedLabel);
        if (item == null) {
          throw Error(`Item with label ${selectedLabel} is not provided with the items-property.`)
        }

        this.valueChange.emit(item.value)
        this.clearSelectedLabel$.next()
        return;
      })
  }

  private createArrowKeySelectedLabelOnEnterSubscriptionHandler(): Subscription {
    return this.arrowKeySelectLabelOnEnter$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showOptions$.next(false)
      })
  }

}

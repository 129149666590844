<div>
  <div id="orient-progress" class="full-width-override orient-blue-background">
    <div class="orient-top-container orient-app-container-wrapper">
      <h1 class="page-title vdab-text-color-white u-mb025">
        {{ tcs.getText("orientation_questions") }}
      </h1>
      <ng-container *ngVar="infoGainProgress$ | async as infoGainProgress">
        <vdab-ai-progress-linear class="u-mb1" height="3px" [progress]="infoGainProgress"></vdab-ai-progress-linear>
      </ng-container>
    </div>
  </div>
  <ng-container *ngVar="isDesktopViewport$ | async as isDesktopViewport">
    <div class="orient-test-container">
      <ng-container *ngIf="cardHeight$ | async as cardHeight">
        <app-card-referrer [style.height]="cardHeight" class="card-referrer-container"></app-card-referrer>
      </ng-container>
    </div>
  </ng-container>
</div>
<vdab-ai-dialog-box *ngIf="questionFacade.error$ | async" #errorDialog appearance="warning">
  <span title>{{ tcs.getText("something_went_wrong_general_message") }}</span>
  <span content
    >{{ tcs.getText("something_went_wrong_general_message_content_part_1") }}
    <a href="/">{{ tcs.getText("something_went_wrong_general_message_content_part_2") }}</a>
    {{ tcs.getText("something_went_wrong_general_message_content_part_3") }}
    <a [href]="environment.WELKE_JOB_PAST_BIJ_ME_URL">{{
      tcs.getText("something_went_wrong_general_message_content_part_4")
    }}</a>
  </span>
</vdab-ai-dialog-box>

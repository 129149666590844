import {Component, Input, OnInit} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: "vdab-ai-half-donut-chart",
  templateUrl: "./half-donut-chart.component.html",
  styleUrls: ["./half-donut-chart.component.scss"],
})
/**
 * Half Donut Chart component:
 *
 * See example in 'examples/half-donut-chart-example.component.html'
 * */
export class HalfDonutChartComponent implements OnInit {
  // Data

  /**
   * Percentage as a float between 0-1
   * E.g. 0, 0.1, 0.75, 1
   * */
  @Input() percentage!: number;
  /**
   * Css color for the half donut
   * E.g. 'lightgreen', '#2d89cc', ...
   * */
  @Input() color!: string;
  /**
   * Text to display below the half donut
   * */
  @Input() text: string = "";
  /**
   * Flag to disable animation for the half donut
   * Animation is disabled by default
   * */
  @Input() disableAnimation: boolean = true;

  /**
   * Flag to enable information icon
   * Disabled by default
   * */
  @Input() enableInfoIcon: boolean = false;

  // Lifecycle

  constructor() {
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.percentage)) {
      this.percentage = 0
    }
    if (isNullOrUndefined(this.color)) {
      this.color = "white"
    }
    if (isNullOrUndefined(this.text)) {
      this.text = ""
    }
    if (isNullOrUndefined(this.disableAnimation)) {
      this.disableAnimation = true;
    }
    if (isNullOrUndefined(this.enableInfoIcon)) {
      this.enableInfoIcon = false
    }
  }

  // Getters & setters

  public get formattedPercentage(): number {
    return Number((this.percentage * 100).toFixed(0));
  }

  public get noTextProvided(): boolean {
    return this.text.length === 0
  }

  public get bottomPaddingForHalfDonutChart(): string {
    return this.noTextProvided ? '1rem' : '0.5rem'
  }

  public get topPlacementForInformationIcon(): string {
    return this.noTextProvided ? '-45px' : '-70px'
  }

  public get e2e() {
    return e2e;
  }
}

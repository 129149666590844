export const isNull = <T>(value: T | null) => {
  return value === null;
}

export const isUndefined = <T>(value: T | undefined) => {
  return value === undefined;
}

export const isNullOrUndefined = <T>(value: T | undefined | null) => {
  return isNull(value) || isUndefined(value);
}

export const assertIsDefined = <T>(value: T | undefined | null, variableName: string = ""): asserts value is T => {
  if (isNullOrUndefined(value)) {
    throw new Error(`Variable ${variableName} is null or undefined`);
  }
}

import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Observable, ReplaySubject, Subject, takeUntil } from "rxjs";
import { map } from "rxjs/operators";

import { ISuggestedOccupationalProfile } from "../../../models/ISuggestedOccupationalProfile";
import { StyleService } from "../../../services/style.service";
import { capitalizeFirstLetterOnly, TextContentService } from "../../../services/text-content.service";
import { ResultFacade } from "../../../state/Result/Result.facade";
import { ResultPageFilterHelperComponent } from "../result-page-filter-helper/result-page-filter-helper.component";

@Component({
  selector: "app-result-page-filter",
  templateUrl: "./result-page-filter.component.html",
  styleUrls: ["./result-page-filter.component.scss"],
})
export class ResultPageFilterComponent {
  // Data

  @Input() showMobileFilterModal = false;
  @Output() showMobileFilterModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isNoKnelpuntberoepValue$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public filtersLabel: string;
  public filteredResultsCountLabel$: Observable<string>;

  public destroy$: Subject<void> = new Subject<void>();

  @ViewChild("desktopFilter") desktopFilter!: ResultPageFilterHelperComponent;
  @ViewChild("mobileFilter") mobileFilter!: ResultPageFilterHelperComponent;

  // Lifecycle

  constructor(public tcs: TextContentService, public resultFacade: ResultFacade, public styleService: StyleService) {
    this.filtersLabel = this.tcs.getText("filters", capitalizeFirstLetterOnly);
    this.filteredResultsCountLabel$ = this.createFilteredResultsCountLabel();
  }

  // Interface methods

  public onCloseModalFromFilterHelper() {
    this.changeModalStatus(false);
  }

  public changeModalStatus(open: boolean) {
    this.showMobileFilterModalChange.emit(open);
  }

  // Getters & setters

  // Helper methods

  private createFilteredResultsCountLabel(): Observable<string> {
    return this.resultFacade.filteredSuggestedOccupationalProfiles$.pipe(
      takeUntil(this.destroy$),
      map((filteredSuggestedProfessions: ISuggestedOccupationalProfile[]) => {
        return `${filteredSuggestedProfessions.length} ${this.tcs.getText("results")}`;
      })
    );
  }
}

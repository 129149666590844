// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "./IEnvironment";

export const environment: IEnvironment = {
  production: false,
  // ========================================================================================
  // Common

  AMT_OF_RESULTS_TO_HANDLE: 200,
  QUESTION_IMAGE_PATH: "/assets/question_img",
  GTM_ID: "GTM-NXCS7BQ",
  AMT_OF_EXPLANATIONS_TO_SHOW_FOR_SUGGESTED_PROFESSION: 3,
  WELKE_JOB_PAST_BIJ_ME_URL: "https://www.vdab.be/welke-job-past-bij-me",
  ORIENT_API: "",
  LOADING_DELAY: 500,

  // ========================================================================================
  // Dev - common
  RECAPTCHA_SITE_KEY: "6LcJycogAAAAAFK9YYkJMYSlnokvDUXptQ08XR4w",
  // ORIENT_API: "http://localhost:8000/api", // localhost

  // ========================================================================================
  // Dev - ABT
  // ORIENTATION_PAGE_URL='https://www-mlb1abt.ops.vdab.be/orienteren?orientsessie-id=',
  // PROFESSION_URL:'https://www-mlb1abt.ops.vdab.be/beroep/',
  // VACANCIES_URL:'https://www-mlb1abt.ops.vdab.be/vindeenjob/vacatures?trefwoord=',
  // LOGO_URL: "https://www-mlb1abt.ops.vdab.be",
  // PRIVACY_URL: "https://www-mlb1abt.ops.vdab.be/privacy/",

  // ========================================================================================
  // Dev - CBT

  ORIENTATION_PAGE_URL: "https://www-rel1abt.ops.vdab.be/orienteren?orientsessie-id=",
  PROFESSION_URL: "https://www-rel1abt.ops.vdab.be/beroep/",
  VACANCIES_URL: "https://www-rel1abt.ops.vdab.be/vindeenjob/vacatures?trefwoord=",
  APP_LOGO_URL: "https://www-rel1abt.ops.vdab.be",
  PRIVACY_URL: "https://www-rel1abt.ops.vdab.be/privacy/",
  VEO_PAGE_URL: "https://www-rel1abt.ops.vdab.be/opleidingen/aanbod/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

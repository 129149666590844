import { Injectable } from "@angular/core";

import { IStyleConstants, StyleConstants } from "../../styles/styles";

@Injectable({
  providedIn: "root",
})
export class StyleService {
  // Data

  public styles: IStyleConstants = StyleConstants;

  constructor() {}
}

import { QuestionTypesEnum } from "./Enums";

export interface INextQuestionSummary {
  id: string;
  question: string;
  description: string;
  questionType: QuestionTypesEnum;
}

export const isInterestQuestionSummary = (question: INextQuestionSummary | null | undefined): boolean => {
  return question?.questionType === QuestionTypesEnum.InterestQuestion;
};

export const isProfessionQuestionSummary = (question: INextQuestionSummary | null | undefined): boolean => {
  return question?.questionType === QuestionTypesEnum.ProfessionQuestion;
};

export const isProfessionWarningQuestionSummary = (question: INextQuestionSummary | null | undefined): boolean => {
  return question?.questionType === QuestionTypesEnum.ProfessionWarning;
};

export const isEndQuestionSummary = (question: INextQuestionSummary | null | undefined): boolean => {
  return question?.questionType === QuestionTypesEnum.End;
};

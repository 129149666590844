// export function getByKeys(o: Object, s: string) {
//   s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
//   s = s.replace(/^\./, ""); // strip a leading dot
//   let a = s.split(".");
//   for (let i = 0, n = a.length; i < n; ++i) {
//     let k = a[i];
//     if (k in o) {
//       // @ts-ignore
//       o = o[k];
//     } else {
//       return;
//     }
//   }
//   return o;
// }

import { Observable, of } from "rxjs";

export function getByNestedKey(obj: any, keys: string[], fallback: any): any {
  let currentObjScope = obj;
  for (const key in keys) {
    if (currentObjScope.hasOwnProperty(key)) {
      // @ts-ignore
      currentObjScope = currentObjScope[key];
    } else {
      return fallback;
    }
  }
  return currentObjScope;
}

export function updatedObj<T>(obj: T, update: Partial<T>): T {
  return Object.assign({}, obj, update);
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return obj === null || obj === undefined;
}

export function objLength(obj: any): number {
  return Object.keys(obj).length;
}

export function toObservable(object: any): Observable<any> {
  return of(object);
}

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: "vdab-ai-text-area",
  templateUrl: "./text-area.component.html",
  styleUrls: ["./text-area.component.scss"],
})
export class TextAreaComponent implements OnInit {
  // Data

  /**
   * Label
   */
  @Input() label: string = "";
  /**
   * Placeholder
   */
  @Input() placeholder: string = "";
  /**
   * Value
   */
  @Input() value!: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Amount of columns for width of text area
   */
  @Input() columns: number | null = 0;
  /**
   * Amount of rows for width of text area
   */
  @Input() rows: number | null = 0;

  /**
   * Disabled
   */
  @Input() disabled!: boolean;

  public id: string = this.generateId();

  // Lifecycle

  constructor() {
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.id)) {
      this.id = this.generateId();
    }
    if (isNullOrUndefined(this.label)) {
      this.label = "";
    }
    if (isNullOrUndefined(this.placeholder)) {
      this.placeholder = "";
    }
    if (isNullOrUndefined(this.value)) {
      this.value = "";
    }
    if (isNullOrUndefined(this.columns)) {
      this.columns = 1;
    }
    if (isNullOrUndefined(this.rows)) {
      this.rows = 1;
    }
    if (isNullOrUndefined(this.disabled)) {
      this.disabled = false;
    }

  }

  // Interface methods

  public onChange(event: any) {
    const value = event.target.value;
    this.value = value;
    this.valueChange.emit(value)
  }

  // Getters & setters

  public get _columns() {
    return this.columns ?? 1;
  }

  public get _rows() {
    return this.rows ?? 1;
  }

  public get e2e() {
    return e2e;
  }

  // Helper methods

  private generateId(): string {
    return `textarea-${this.label}}`;
  }
}

import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { RangeSliderComponent } from "angular-frontend-components";
import { combineLatest, map, Observable, shareReplay, Subject, takeUntil } from "rxjs";

import { Werkniveau } from "../../../models/Enums";
import { ISuggestedOccupationalProfile } from "../../../models/ISuggestedOccupationalProfile";
import { capitalizeFirstLetterOnly, TextContentService } from "../../../services/text-content.service";
import { ResultFacade } from "../../../state/Result/Result.facade";
import { filterOnKnelpuntberoep, filterOnWerkniveau } from "../../../state/Result/Result.helpers";

@Component({
  selector: "app-result-page-filter-helper",
  templateUrl: "./result-page-filter-helper.component.html",
  styleUrls: ["./result-page-filter-helper.component.scss"],
})
export class ResultPageFilterHelperComponent {
  // Data

  public educationLevelTitle: string;

  public lowEducationLevelProfessionCount$: Observable<string>;
  public lowEducationLevelLabel: string;
  public lowEducationLevelValue$: Observable<boolean>;

  public mediumEducationLevelProfessionCount$: Observable<string>;
  public mediumEducationLevelLabel: string;
  public mediumEducationLevelValue$: Observable<boolean>;

  public highEducationLevelProfessionCount$: Observable<string>;
  public highEducationLevelLabel: string;
  public highEducationLevelValue$: Observable<boolean>;

  public knelpuntberoepTitle: string;

  public isKnelpuntberoepProfessionCount$: Observable<string>;
  public yesLabel: string;
  public isKnelpuntberoepValue$: Observable<boolean>;

  public isNoKnelpuntberoepProfessionCount$: Observable<string>;
  public noLabel: string;
  public isNoKnelpuntberoepValue$: Observable<boolean>;

  public resultsLabel: string;

  public destroy$: Subject<void> = new Subject<void>();

  @ViewChild("rangeSlider") rangeSlider!: RangeSliderComponent;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  // Lifecycle
  constructor(public tcs: TextContentService, public resultFacade: ResultFacade) {
    this.educationLevelTitle = this.tcs.getText("education_level", capitalizeFirstLetterOnly);
    this.lowEducationLevelLabel = this.tcs.getText("lowly_educated", capitalizeFirstLetterOnly);
    this.lowEducationLevelValue$ = this.educationLevelValueHandler(Werkniveau.W1);
    this.mediumEducationLevelLabel = this.tcs.getText("medium_educated", capitalizeFirstLetterOnly);
    this.mediumEducationLevelValue$ = this.educationLevelValueHandler(Werkniveau.W2);

    this.highEducationLevelLabel = this.tcs.getText("highly_educated", capitalizeFirstLetterOnly);
    this.highEducationLevelValue$ = this.educationLevelValueHandler(Werkniveau.W3);

    this.knelpuntberoepTitle = this.tcs.getText("knelpuntberoep", capitalizeFirstLetterOnly);
    this.yesLabel = this.tcs.getText("yes", capitalizeFirstLetterOnly);
    this.isKnelpuntberoepValue$ = this.resultFacade.isKnelpuntBeroep$;
    this.isNoKnelpuntberoepValue$ = this.resultFacade.isNotKnelpuntBeroep$;

    this.noLabel = this.tcs.getText("no", capitalizeFirstLetterOnly);

    this.resultsLabel = this.tcs.getText("results", capitalizeFirstLetterOnly);
    this.lowEducationLevelProfessionCount$ = this.createEducationLevelProfessionCount(Werkniveau.W1);
    this.mediumEducationLevelProfessionCount$ = this.createEducationLevelProfessionCount(Werkniveau.W2);
    this.highEducationLevelProfessionCount$ = this.createEducationLevelProfessionCount(Werkniveau.W3);
    this.isKnelpuntberoepProfessionCount$ = this.createIsKnelpuntberoepProfessionCount(true);
    this.isNoKnelpuntberoepProfessionCount$ = this.createIsKnelpuntberoepProfessionCount(false);
  }

  // Interface methods

  public onCloseModalClicked() {
    this.closeModal.next();
  }

  public lowEducationLevelValueChanged(enable: boolean) {
    this.resultFacade.setWerkniveauFilter(Werkniveau.W1, enable);
  }

  public mediumEducationLevelValueChanged(enable: boolean) {
    this.resultFacade.setWerkniveauFilter(Werkniveau.W2, enable);
  }

  public highEducationLevelValueChanged(enable: boolean) {
    this.resultFacade.setWerkniveauFilter(Werkniveau.W3, enable);
  }

  public isKnelpuntberoepValueChanged(enable: boolean) {
    this.resultFacade.setIsKnelpuntBeroep(enable);
  }

  public isNoKnelpuntberoepValueChanged(enable: boolean) {
    this.resultFacade.setIsNotKnelpuntBeroep(enable);
  }

  // Getters & setters

  // Helper methods

  private educationLevelValueHandler(werkniveau: Werkniveau): Observable<boolean> {
    return this.resultFacade.werkniveaus$.pipe(
      takeUntil(this.destroy$),
      map((werkniveaus: Werkniveau[]) => werkniveaus.includes(werkniveau))
    );
  }

  private createEducationLevelProfessionCount(werkniveau: Werkniveau): Observable<string> {
    return combineLatest(
      this.resultFacade.suggestedOccupationalProfiles$,
      this.isKnelpuntberoepValue$,
      this.isNoKnelpuntberoepValue$
    ).pipe(
      takeUntil(this.destroy$),
      map(
        ([suggestedProfessions, isKnelpuntberoepFilter, isNoKnelpuntberoepFilter]: [
          ISuggestedOccupationalProfile[],
          boolean,
          boolean
        ]) => {
          const suggestedProfessionsFilteredOnKnelpuntberoep = filterOnKnelpuntberoep(
            suggestedProfessions,
            isKnelpuntberoepFilter,
            isNoKnelpuntberoepFilter
          );
          const amtOfProfessions = suggestedProfessionsFilteredOnKnelpuntberoep.filter(
            (suggestedProfession: ISuggestedOccupationalProfile) => suggestedProfession.werkniveaus.includes(werkniveau)
          ).length;
          return `(${amtOfProfessions})`;
        }
      )
    );
  }

  private createIsKnelpuntberoepProfessionCount(isKnelpuntberoep: boolean): Observable<string> {
    return combineLatest(this.resultFacade.suggestedOccupationalProfiles$, this.resultFacade.werkniveaus$).pipe(
      takeUntil(this.destroy$),
      map(([suggestedProfessions, werkniveauFilter]: [ISuggestedOccupationalProfile[], Werkniveau[]]) => {
        const suggestedProfessionsFilteredOnWerkniveau = filterOnWerkniveau(suggestedProfessions, werkniveauFilter);
        const amtOfProfessions = suggestedProfessionsFilteredOnWerkniveau.filter(
          (suggestedProfession: ISuggestedOccupationalProfile) =>
            suggestedProfession.knelpuntBeroep === isKnelpuntberoep
        ).length;
        return `(${amtOfProfessions})`;
      }),
      shareReplay(1)
    );
  }
}

import {Component, Input, OnInit} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {TextContentService} from "../../services/text-content.service";

@Component({
  selector: "vdab-ai-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
/**
 * Snackbar component has 2 content slots:
 * 1. content: Represents the text content of the snackbar
 * 2. actions: Represents the actions that can be performed on the snackbar
 *
 * See example in 'examples/snackbar-example.component.html'
 * */
export class SnackbarComponent implements OnInit {
  // Data

  /**
   * Appearance of Snackbar
   */
  @Input() appearance: "neutral" | "warning" | "mini" | "light" = "neutral";
  /**
   * Indicates whether the close button is displayed
   */
  @Input() withClose: boolean = true;


  public isShown: boolean = false;
  public showDuration: number = 5000;

  // Lifecycle

  constructor(public textContentService: TextContentService) {
  }

  ngOnInit(): void {
    if (this.withClose == null) {
      this.withClose = true;
    }
  }

  // Interface

  public show() {
    this.isShown = true;
    setTimeout(() => {
      this.isShown = false;
    }, this.showDuration);
  }

  public hide() {
    this.isShown = false;
  }

  // Getters & setters

  public get neutral() {
    return this.appearance === "neutral" || !(this.appearance === "warning" || this.appearance === "mini" || this.appearance === "light");
  }

  public get warning() {
    return this.appearance === "warning"
  }

  public get mini() {
    return this.appearance === "mini"
  }

  public get light() {
    return this.appearance === "light"
  }

  public get e2e() {
    return e2e;
  }

  // Helper methods
}

<div class="vdab-ai-input input-container"
     [style.width]="width"
>
  <ng-container *ngVar="id$ | async as id">
    <ng-container *ngVar="label$ | async as label">
      <div class="form-label">
        <label
          [for]="id"
          [attr.data-e2e]="e2e.vdabAiInputLabelElement"
        >{{ label}}</label>
      </div>
      <div
        class="form-field"
        [class.field-error]="error?? false"
        [attr.data-e2e]="e2e.vdabAiInputFormFieldElement"
      >
        <div class="form-field-inner">
          <span
            *ngIf="prefix.length
            > 0" class="prefix u-mr05"
            [attr.data-e2e]="e2e.vdabAiInputPrefixElement"
          >{{ prefix }}</span>
          <input
            #input
            class="a"
            type="text"
            [id]="id"
            [style.width]="this.width"
            [value]="value"
            (input)="emitValueChanged($event)"
            (blur)="onBlurHandler($event)"
            (keyup)="onEnterHandler($event)"
            [placeholder]="placeholder"
            [disabled]="disabled"
            [type]="inputType"
            [step]="inputStep"
            [attr.data-e2e]="e2e.vdabAiInputInputElement"
          >
          <span
            *ngIf="affix.length > 0"
            class="affix u-ml05"
            [attr.data-e2e]="e2e.vdabAiInputAffixElement"
          >{{ affix }}</span>
        </div>
        <div
          *ngIf="error?? false"
          class="inline-notification error"
          [attr.data-e2e]="e2e.vdabAiInputErrorMsgElement"
        >
          <span class="action-warning"></span>
          <span class="error-msg">{{errorMessage ?? ""}}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

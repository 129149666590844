import {Component, Input, OnInit} from '@angular/core';
import {e2e} from '../../e2e/dataE2eValues';
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: 'vdab-ai-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // Data

  @Input() appLogoUrl!: string;
  @Input() appLogoLinkUrl!: string;
  @Input() appName!: string;

  // Lifecycle

  constructor() {
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.appName)) {
      this.appName = ""
    }

    if (isNullOrUndefined(this.appLogoUrl)) {
      this.appLogoUrl = ""
    }

    if (isNullOrUndefined(this.appLogoLinkUrl)) {
      this.appLogoLinkUrl = ""
    }
  }

  // Getters & setters

  public get e2e() {
    return e2e;
  }

}

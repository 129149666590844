import {HttpClient, HttpContext} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, map, Observable, of, shareReplay, startWith, throwError, withLatestFrom} from "rxjs";

import {environment} from "../../environments/environment";
import {WithStatus, WithStatusDone, WithStatusError, WithStatusLoading} from "../../utils/with-status";
import {INextQuestion} from "../models/INextQuestion";
import {ISession} from "../models/ISession";
import {fromApi, toApi} from "../models/mapper";
import {OrientService} from '../../api/services/orient.service';
import {SendMailApiOrientMailMailPost$Params} from '../../api/fn/orient/send-mail-api-orient-mail-mail-post';
import {NextQuestion} from '../../api/models/next-question';
import {Session} from '../../api/models/session';

@Injectable({
  providedIn: "root",
})
export class HttpService {
  // Data

  // Lifecycle

  constructor(public httpClient: HttpClient, private orientService: OrientService) {}

  // Interface methods

  public getNextQuestion(session: ISession): Observable<INextQuestion> {
    return this.orientService.updateAnswersApiOrientUpdateAnswersUpdateAnswersPost({
      body: toApi.AnswersFromISession(session)
    }).pipe(
      map(
        (nextQuestion) => {
          return fromApi.INextQuestion(nextQuestion);
        },
        catchError((err) => {
          console.error(err);
          return throwError(err);
        })
      )
    );
  }

  public loadSession(sessionId: string): Observable<ISession> {
    return this.orientService.getSessionApiOrientGetSessionSessionSessionIdGet({
      session_id: sessionId
    }).pipe(
      map(
        (session) => {
          return fromApi.ISession(session);
        },
        catchError((err) => {
          console.error(err);
          return throwError(err);
        })
      )
    );
  }

  public sendPdfMail(
    sessionDetails: Session,
    mailAddress: string,
    mailAddressCc: string | null,
    recaptchaToken: string,
  ): Observable<WithStatus<any>> {
    const params: SendMailApiOrientMailMailPost$Params = {
      recaptcha_token: recaptchaToken,
      body: {
          session_id: sessionDetails.session_id,
          date: new Date().toISOString(),
          rated_questions: sessionDetails.rated_questions,
          rated_occupational_profiles: sessionDetails.rated_occupational_profiles,
          suggested_occupational_profiles: sessionDetails.suggested_occupational_profiles,
      },
      mail_address: mailAddress,
      mail_cc: mailAddressCc,
    };

    return (
      this.orientService.sendMailApiOrientMailMailPost(params)
        .pipe(
          map((resultsMailResultsPost: any) => {
            return <WithStatusDone<any>>{ status: "done", value: resultsMailResultsPost };
          }),
          startWith(<WithStatusLoading<any>>{ status: "loading" }),
          catchError((err) => of(<WithStatusError<any>>{ status: "error", error: err.message })),
          shareReplay(1)
        )
    );
  }

  public downloadPdf(sessionDetails: Session): Observable<WithStatus<Blob>> {
    return this.orientService.sendPdfApiOrientPdfPdfPost$Pdf({body: sessionDetails})
      // TODO is the httpHeaderAccept still necessary? YESS!!!88!!!
    // return this.openApiService
    //   .pdfApiOrientPdfPdfPost(sessionDetails, undefined, false, {
    //     // Circumvent limitation of openapi-generator with generating the correct accept header
    //     // @ts-ignore
    //     httpHeaderAccept: "application/pdf",
    //     context: undefined,
    //   })
      .pipe(
        map((response: Blob) => {
          return <WithStatus<Blob>>{ status: "done", value: response };
        }),
        startWith(<WithStatus<Blob>>{ status: "loading" }),
        catchError((err, caught) => {
          console.error(err);
          return of(<WithStatusError<Blob>>{ status: "error", error: err.message });
        }),
        shareReplay(1)
      );
  }

  // TODO: Refactor any response
  public saveOnVdab(sessionDetails: Session): Observable<WithStatus<any>> {
    return this.orientService.saveSessionApiOrientSaveSessionSaveSessionPut({body: sessionDetails}).pipe(
      map((response) => {
        return <WithStatus<any>>{ status: "done", value: response };
      }),
      startWith(<WithStatus<any>>{ status: "loading" }),
      catchError((err) => of(<WithStatusError<INextQuestion>>{ status: "error", error: err.message })),
      shareReplay(1)
    );
  }

  public loadImageAsBlob(url: string): Observable<WithStatus<Blob>> {
    return this.httpClient
      .get<Blob>(url, {
        // Workaround for responseType: https://stackoverflow.com/a/58168096
        responseType: "blob" as "json",
      })
      .pipe(
        map((blob) => {
          return <WithStatusDone<Blob>>{ status: "done", value: blob };
        }),
        startWith(<WithStatusLoading<Blob>>{ status: "loading" })
      );
  }

  public createProfessionLinkUrlHandler(suggestedProfession$: Observable<[string, string]>): Observable<string> {
    const suggestedProfessionName$ = suggestedProfession$.pipe(
      map(([, suggestedProfessionName]) => suggestedProfessionName)
    );
    return suggestedProfession$.pipe(
      withLatestFrom(HttpService.normalizedProfessionHandler(suggestedProfessionName$)),
      map(([[suggestedProfessionId], normalizedProfession]): string => {
        return `${environment.PROFESSION_URL}${suggestedProfessionId}/${normalizedProfession}?utm_source=orientatietest&utm_medium=referral&utm_campaign=orient_bekijk_beroep`;
      })
    );
  }

  public createVacancyLinkUrlHandler(suggestedProfession$: Observable<[string, string]>): Observable<string> {
    const suggestedProfessionName$ = suggestedProfession$.pipe(
      map(([, suggestedProfessionName]) => suggestedProfessionName)
    );
    return suggestedProfession$.pipe(
      withLatestFrom(HttpService.normalizedProfessionHandler(suggestedProfessionName$)),
      map(([, normalizedProfession]): string => {
        return `${environment.VACANCIES_URL}${normalizedProfession}&utm_source=orientatietest&utm_medium=referral&utm_campaign=orient_bekijk_vacatures`;
      }),
      shareReplay(1)
    );
  }

  public createCourseUrlHandler(suggestedProfession$: Observable<[string, string]>): Observable<string> {
    const suggestedProfessionName$ = suggestedProfession$.pipe(
      map(([, suggestedProfessionName]) => suggestedProfessionName)
    );
    return suggestedProfession$.pipe(
      withLatestFrom(HttpService.normalizedProfessionHandler(suggestedProfessionName$)),
      map(([, normalizedProfession]): string => {
        return `${environment.VEO_PAGE_URL}?trefwoord=${normalizedProfession}`;
      }),
      shareReplay(1)
    );
  }

  // Getters & setters

  // Helper methods

  private static normalizedProfessionHandler(suggestedProfessionName$: Observable<string>): Observable<string> {
    return suggestedProfessionName$.pipe(
      map((suggestedProfessionName: string) => {
        return `${suggestedProfessionName.replace(" (m/v)", "").toLowerCase().replace(/\s+/g, "-")}`;
      }),
      shareReplay(1)
    );
  }
}

export function capitalize(str: string, onlyFirst = false): string {
  return onlyFirst ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : str.toUpperCase();
}

// Source: https://www.w3resource.com/javascript/form/email-validation.php
export function isValidEmail(input: string) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
    return true;
  }
  return false;
}

<ng-container *ngIf="textContentService.textContent$ | async as textContent">
  <ng-container *ngVar="selectedLabel$ | async as selectedLabel">
    <ng-container *ngVar="showOptions$ | async as showOptions">
      <ng-container *ngIf="items$ | async as items">
        <div
          #dropdownParent
          class="dropdown"
          [attr.data-e2e]="e2e.vdabAiAutocompleteRoot"
        >
          <div class="dropdown-label u-mb025">
            <label
              [attr.data-e2e]="e2e.vdabAiAutocompleteLabel"
              for="dropdown"
            >{{label}}</label>
          </div>
          <div class="form-field field-select">
            <div class="form-field-icon select">
              <div class="select-input">
                <input
                  id="dropdown"
                  #dropdown
                  [attr.data-e2e]="e2e.vdabAiAutocompleteInput"
                  [placeholder]="placeholder"
                  [value]="selectedLabel"
                  (click)="onInputClicked()"
                  (keyup)="onInputChanged($event)"
                >
                <span *ngIf="items.length > 0" class="field-icon icon-arrow-down"></span>
              </div>
              <div
                #selectOptions
                [attr.data-e2e]="e2e.vdabAiAutocompleteContent"
                class="select-options"
                [style.visibility]="selectOptionsVisibility$ | async"
                [style.top]="selectOptionsTop"
                [style.width]="selectOptionsWidth"
                [class.hover]="false"
              >
                <ng-container *ngVar="arrowKeySelectedLabelIndex$ | async as arrowKeySelectedLabelIndex">
                <span
                  *ngFor="let item of items;let i = index;"
                  class="select-option"
                  [class.hover]="i == arrowKeySelectedLabelIndex"
                  (click)="onSelectChanged(item.label)"
                  tabindex="0"
                >{{item.label}}</span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {e2e} from "../../e2e/dataE2eValues";
import {
  $color_black,
  $color_blue_300,
  $color_burgundy_300,
  $color_green_300,
  $color_grey_300,
  $color_purple_300,
  $color_red_300,
  $color_turquoise_300,
  $color_yellow_300
} from "../../styles/styles";
import {isNullOrUndefined} from "../../utils/guards";

@Component({
  selector: "vdab-ai-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
})
/**
 * Tag component
 *
 * See example in 'examples/tag-example.component.html'
 * */
export class TagComponent implements OnInit, AfterViewInit {
  // Data

  /**
   * Light-style tag
   */
  @Input() light: boolean = false;

  /**
   * Indicates whether the tag is removable
   */
  @Input() removable: boolean = false;

  /**
   * Indicates tag appearance
   */
  @Input() appearance:
    | "neutral"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "yellow"
    | "lightgreen"
    | "turquoise"
    | "blue"
    | "purple"
    | "burgundy"
    | "grey"
    | "black" = "neutral";

  /**
   * Emits PointerEvent when close icon is clicked
   */
  @Output() closeClicked: EventEmitter<PointerEvent> = new EventEmitter<PointerEvent>();
  @ViewChild("tag") tag!: ElementRef;
  public mouseHover: boolean = false;

  // Lifecycle

  constructor() {
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.appearance)) {
      this.appearance = "neutral";
    }
    if (isNullOrUndefined(this.light)) {
      this.light = false;
    }
    if (isNullOrUndefined(this.removable)) {
      this.removable = false;
    }
  }

  ngAfterViewInit() {
    const mouseEnterHandler = (event: MouseEvent) => {
      this.mouseHover = true;
    };
    this.tag.nativeElement.addEventListener("mouseenter", mouseEnterHandler);
    const mouseLeaveHandler = (event: MouseEvent) => {
      this.mouseHover = false;
    };
    this.tag.nativeElement.addEventListener("mouseleave", mouseLeaveHandler);
  }

  // Interface methods

  public onClick(event: any) {
    this.closeClicked.emit(event);
  }

  // Getters & setters

  public get yellowColor(): boolean {
    return this.appearance === "yellow";
  }

  public get lightgreenColor(): boolean {
    return this.appearance === "lightgreen";
  }

  public get turquoiseColor(): boolean {
    return this.appearance === "turquoise";
  }

  public get blueColor(): boolean {
    return this.appearance === "blue";
  }

  public get purpleColor(): boolean {
    return this.appearance === "purple";
  }

  public get burgundyColor(): boolean {
    return this.appearance === "burgundy";
  }

  public get greyColor(): boolean {
    return this.appearance === "grey";
  }

  public get blackColor(): boolean {
    return this.appearance === "black";
  }

  public get neutral(): boolean {
    return this.appearance === "neutral";
  }

  public get info(): boolean {
    return this.appearance === "info";
  }

  public get success(): boolean {
    return this.appearance === "success";
  }

  public get warning(): boolean {
    return this.appearance === "warning";
  }

  public get danger(): boolean {
    return this.appearance === "danger";
  }


  public get removableIconBackgroundColor(): string {
    if (!this.mouseHover) {
      return "rgba(0,0,0,0)"
    }
    switch (this.appearance) {
      case "burgundy":
        return $color_burgundy_300;
      case "black":
        return $color_black;
      case "blue":
        return $color_blue_300;
      case "danger":
        return $color_red_300;
      case "lightgreen":
        return $color_green_300
      case "neutral":
        return $color_grey_300;
      case "purple":
        return $color_purple_300
      case "success":
        return $color_green_300;
      case "turquoise":
        return $color_turquoise_300;
      case "warning":
        return $color_yellow_300;
      case "yellow":
        return $color_yellow_300;
      case "grey":
        return $color_grey_300;
      case "info":
        return $color_blue_300;
    }
  }

  public get e2e() {
    return e2e;
  }

  // Helper methods
}
